import React from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Form,
	Tag,
	Select,
	Spin
} from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  RedoOutlined,
  ShoppingOutlined,
  FileAddOutlined,
  HistoryOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined
} from '@ant-design/icons';

import axios from 'axios';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import moment from 'moment';

import Config from '../../Config';
import {Helmet} from "react-helmet";
import NumberFormat from 'react-number-format';

const configClass = {
	title: 'verifikasi penjualan',
	apiUrl: {
		data: 'transaksi/api/penjualan/',
		outlet: 'master/api/outlet/',
		kasir: "pengguna/api/admin/",
		approve: 'transaksi/api/penjualan/approve/?'
	},
	breadCrumb: [
		(
			<span>
				<ShoppingOutlined /> penjualan
			</span>
		),
		'verifikasi penjualan'
	]

}

const config = new Config();

const formItemLayout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 18 },
    sm: { span: 18 },
  },
};

class PembayaranPenjualan extends React.Component {
	formRef = React.createRef();
	formPembayaran = React.createRef();
	constructor(props){
		super(props);
		this.state = {
			errMsg: '',
			loadingData: false,
			pagination: {pageSize: 25},
			searchText: '',
			searchColumn: '',
			dataSource: [],
			columns: [
				{
					title: 'KODE',
					width: 160,
					dataIndex: 'kode',
					key: 'kode',
					fixed: 'left'
				},
				{
					title: 'CUSTOMER',
					width: 200,
					dataIndex: 'info_customer',
					key: 'info_customer',
					render: (value, row, index) => {
						return value.nama
					}
				},
				{
					title: 'CREATED',
					width: 200,
					dataIndex: 'created',
					key: 'created',
					render: (value, row, index) => {
						return moment(value).format('DD-MM-YYYY hh:mm:ss')
					}
				},
				{
					title: 'JENIS',
					width: 100,
					dataIndex: 'direct',
					key: 'direct',
					render: (value, row, index) => {
						return value ? <Tag color="blue">direct</Tag> : <Tag color="red">wholesale</Tag>
					}
				},
				{
					title: 'JENIS BAYAR',
					width: 120,
					dataIndex: 'jenis_bayar_nama',
					key: 'jenis_bayar'
				},
				{
					title: 'TOTAL',
					width: 100,
					dataIndex: 'total_harga',
					key: 'total_harga',
					render: (value, row, index) => {
						return (parseFloat(value) + parseFloat(row.diskon)).toLocaleString('en-us')
					}
				},
				{
					title: 'PAJAK',
					width: 100,
					dataIndex: 'info_pajak',
					key: 'info_pajak',
					render: (value, row, index) => {
						return (
							row.info_pajak != "" && row.info_pajak.length > 0 && row.info_pajak[0].jenis_pajak+' : '+parseFloat(row.info_pajak[0].nilai_rp).toLocaleString('en-us')
						)
					}
				},
				{
					title: 'DISKON',
					width: 100,
					dataIndex: 'diskon',
					key: 'diskon',
					render: (value, row, index) => {
						return parseFloat(value).toLocaleString('en-us')
					}
				},
				{
					title: 'TOTAL AKHIR',
					width: 120,
					dataIndex: 'harga_akhir',
					key: 'harga_akhir',
					render: (value, row, index) => {
						return row.info_pajak != "" && row.info_pajak.length > 0 
									?
										((parseFloat(row.total_harga)-parseFloat(row.diskon))+parseFloat(row.info_pajak[0].nilai_rp)).toLocaleString('en-us')
									:
										((parseFloat(row.total_harga)-parseFloat(row.diskon))).toLocaleString('en-us')
					}
				},
				{
					title: 'DIBAYAR',
					width: 100,
					dataIndex: 'total_bayar',
					key: 'total_bayar',
					render: (value, row, index) => {
						return parseFloat(value).toLocaleString('en-us')
					}
				},
				{
					title: 'KEKURANGAN',
					width: 120,
					dataIndex: 'total_bayar',
					key: 'kekurangan',
					render: (value, row, index) => {
						// return (parseFloat(row.harga_akhir) - parseFloat(value)).toLocaleString('en-us')
						return row.info_pajak != "" && row.info_pajak.length > 0 
									?
										(((parseFloat(row.total_harga)-parseFloat(row.diskon))+parseFloat(row.info_pajak[0].nilai_rp)) - parseFloat(row.total_bayar)).toLocaleString('en-us')
									:
										(((parseFloat(row.total_harga)-parseFloat(row.diskon))) - parseFloat(row.total_bayar)).toLocaleString('en-us')
					}
				},
				{
					title: 'KASIR',
					width: 120,
					dataIndex: 'kasir_nama',
					key: 'kasir_nama'
				},
				{
					title: 'CUSTOMER',
					width: 120,
					dataIndex: 'info_customer',
					key: 'info_customer',
					render: (value, row, index) => {
						return Object.keys(row.info_customer).length > 0 && row.info_customer.nama
					}
				},
				{
					title: 'EDIT',
					width: 150,
					dataIndex: 'id',
					key: 'aksi',
					fixed: 'right',
					render: (value, row, index) => {
						return (
							
								<Row key={"space-aksi-"+index} justify="center">
									<Col style={{padding: 5}}>
										{row.is_approve == false ?
											<Link to={row.direct ? '/admin/penjualan/'+value : '/admin/penjualan_wholesale/'+value}>
												<Button className="btn-edit" size="small" shape="circle">
													<FormOutlined />
												</Button>
											</Link>
										: <Tag icon={<CheckCircleOutlined />} color="success">approved</Tag>}
									</Col>
								</Row>
							
						)
					},
				}

			],

			total_harga: 0,
			total_bayar: 0,
			total_angsuran: 0,
			total_kekurangan: 0,
			modalPembayaran: false,
			id: '',

			loadingOutlet: false,
			listOutlet: [],
			outlet: '',

			loadingKasir: false,
			listKasir: [],
			kasir: '',

			filter: '',
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		// this.getData();
		this.loadMasterList();
	}

	loadMasterList(){
		const rule = [
			{
				destination: {
					stateProgressName: 'loadingOutlet',
					endPoint: configClass.apiUrl.outlet,
					stateDestination: 'listOutlet',
				},
				nextDestination: {
					stateProgressName: 'loadingKasir',
					endPoint: configClass.apiUrl.kasir,
					stateDestination: 'listKasir',
					next: null
				}
			}
		];

		this.setState({
			errComponent: false
		});

		rule.map((item, index) => {
			if(index == rule.length-1){
				this.getMasterList(item, true);
			}else{
				this.getMasterList(item);
			}
		});
	}

	async getMasterList(rule, isFinal=false) {
		var destination = rule.destination;
		var nextDestination = rule.nextDestination;
		this.setState({[destination.stateProgressName]: true});
		this.props.ajaxViewHandler('get', destination.endPoint)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				if(data.count != undefined){
					let newState = null;
					if(this.state[destination.stateDestination].length == 0){
						newState = data;
					}else{
						if(destination.stateProgressName.includes('_searching')){
							newState = data
						}else{
							const oldState = this.state[destination.stateDestination];
							newState = {
								count: data.count,
								next: data.next,
								previous: data.previous,
								results: [...oldState.results, ...data.results]
							};
						}
					}
					this.setState({
		            	[destination.stateDestination]: newState
		            });
				}else if(data.results != undefined){
	        		this.setState({
		            	[destination.stateDestination]: data.results
		            });
	        	}else{
	        		if(Array.isArray(data)){
			            this.setState({
			            	[destination.stateDestination]: data
			            })
			        }else{
			        	this.setState({
			            	[destination.stateDestination]: [data]
			            })
			        }
		        }
		        
		    }else{
		    	this.setState({
	        		errComponent: true
	        	});
		    }
		    this.setState({[destination.stateProgressName]: false});
		}).catch((response) => {
			//console.log('gagal_get_master', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({
				errTitle: 'gagal request data master',
				errMsg: response
			});
			this.setState({[destination.stateProgressName]: false});
		});
        //next function
        if(nextDestination != null){
        	const next = {
        		destination: {
	        		stateProgressName: nextDestination.stateProgressName,
	        		endPoint: nextDestination.endPoint,
	        		stateDestination: nextDestination.stateDestination,
	        	},
        		nextDestination: nextDestination.next

        	}
        	this.getMasterList(next);
        }
	}

	showModalPembayaran(row){
		this.setState({
			id: row.id,
			total_harga: parseFloat(row.harga_akhir),
			total_bayar: parseFloat(row.total_bayar),
			total_angsuran: 0,
			total_kekurangan: parseFloat(row.harga_akhir) - parseFloat(row.total_bayar),
			modalPembayaran: true

		}, () =>{
			if(this.formPembayaran.current != undefined){
				this.formPembayaran.current.setFieldsValue({
					preview_total_harga: this.state.harga_akhir,
				    preview_total_bayar: this.state.total_bayar,
				    preview_total_angsuran: this.state.total_angsuran,
				    preview_total_kekurangan: this.state.total_kekurangan
				});
				document.getElementById('preview_total_angsuran').focus();
			}
		});

	}

	showAproveConfirm() {
	  Modal.confirm({
	    title: 'Konfirmasi Approvement?',
	    icon: <ExclamationCircleOutlined />,
	    content: 'yakin mengaprove transaksi?',
	    okText: 'Ya',
	    okType: 'danger',
	    cancelText: 'Tidak',
	    onOk: () => this.prosesApprove(),
	    onCancel() {
	      //console.log('Cancel');
	    },
	  });
	}

	prosesApprove() {
		this.setState({
			errTitle: '',
			errMsg: ''
		})
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('get', configClass.apiUrl.approve+this.state.filter)
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil mengaprove transaksi', duration: 2, key: 'toast' });
				this.getData();
			}else{
				const errTitle = 'gagal mengaprove transaksi'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_dapprove', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	getColumnSearchProps = dataIndex => ({
	    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
	      <div style={{ padding: 8 }}>
	        <Input
	          ref={node => {
	            this.searchInput = node;
	          }}
	          placeholder={`Search ${dataIndex}`}
	          value={selectedKeys[0]}
	          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
	          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          style={{ width: 188, marginBottom: 8, display: 'block' }}
	        />
	        <Button
	          type="primary"
	          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          icon={<SearchOutlined />}
	          size="small"
	          style={{ width: 90, marginRight: 8 }}
	        >
	          Search
	        </Button>
	        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
	          Reset
	        </Button>
	      </div>
	    ),
	    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	    onFilter: (value, record) =>
	      record[dataIndex]
	        .toString()
	        .toLowerCase()
	        .includes(value.toLowerCase()),
	    onFilterDropdownVisibleChange: visible => {
	      if (visible) {
	        setTimeout(() => this.searchInput.select());
	      }
	    },
	    render: text =>
	      this.state.searchedColumn === dataIndex ? (
	        <Highlighter
	          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
	          searchWords={[this.state.searchText]}
	          autoEscape
	          textToHighlight={text.toString()}
	        />
	      ) : (
	        text
	      ),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
	    confirm();
	    this.setState({
	      searchText: selectedKeys[0],
	      searchedColumn: dataIndex,
	    });
	};

	handleReset = clearFilters => {
	    clearFilters();
	    this.setState({ searchText: '' });
	};

	async getData(limit=this.state.pagination.pageSize, offset=0, search=this.state.searchText){
		this.setState({loadingData: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.data+'?'+this.state.filter+'&limit='+limit+'&offset='+offset+'&search='+search)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				const pagination = { ...this.state.pagination };
				pagination.total = data.count;
				this.setState({
					dataSource: data.results,
					pagination
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingData: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingData: false});
		});
	}

	handleTableChange = async (pagination, filters, sorter) =>{
    	const newPagination = { ...this.state.pagination };
    	newPagination.pageSize = pagination.pageSize;
		this.setState({
			loadingData: true,
			pagination
		});
		if(pagination.current==1){
			this.getData(pagination.pageSize);
		}else{
			this.getData(pagination.pageSize, (pagination.current - 1)*pagination.pageSize);
		}

		
	}

	handleSubmitFilter = (values) =>{
		var params = "";
		for ( var key in values ) {
			if(values[key] == undefined){
				// params.append(key, '');
			}else{
				if(params == ""){
					params = key+"="+values[key];
				}else{
					params += "&"+key+"="+values[key];
				}
			}
	    }

	    this.setState({
	    	filter: params
	    }, () =>{
	    	this.getData();
	    });
	}

	handleSubmit = (values) =>{
		var params = new FormData();
		for ( var key in values ) {
			if(values[key] == undefined){
				params.append(key, '');
			}else{
				if(key == 'tgl_start' || key == 'tgl_end'){
					params.append(key, (values[key]).toString()+':00');
				}else{
					params.append(key, (values[key]).toString());
				}
			}
	    }
	 
		this.postData(values);
	}

	async postData(datas) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('post', configClass.apiUrl.data+this.state.id+'/pembayaran/', datas, true, true)
		.then(() =>{
			this.setState({
				loadingButton: false,
				modalPembayaran: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menyimpan data', duration: 2, key: 'toast' });
				this.getData();
			}else{
				const errTitle = 'gagal menyimpan data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_post', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	generateSelect(stateName, url, key, value){
		// console.log('cek_for_error', stateName);
		return (
			this.state['loading'+stateName]?(
				<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
			):(
				<Select
					allowClear
					showSearch
					placeholder="pilih"
					optionFilterProp="children"
					onSearch={ val => {
						const rule = {
							destination: {
								stateProgressName: 'loading'+stateName+'_searching',
								endPoint: url+'?search='+val,
								stateDestination: 'list'+stateName
							},
							nextDestination: null
						}
						this.getMasterList(rule);
					}}
					onPopupScroll={ e => {
						e.persist(); 
						let target = e.target;
						if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
							if(this.state['list'+stateName].next != undefined && this.state['list'+stateName].next != null){
								const rule = {
									destination: {
										stateProgressName: 'loading'+stateName+'Paging',
										endPoint: this.state['list'+stateName].next,
										stateDestination: 'list'+stateName
									},
									nextDestination: null
								}
								this.getMasterList(rule);
							}
						}
					}}
					/*
					onChange={
						(val) => {
							if(val != ''){
								this.setState({
									outlet: val
								}, () =>{
									this.getData();
								});
							}
						}
					}
					*/
				>
					{(this.state['loading_'+stateName+'searching'] &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
					{this.state['list'+stateName].count != undefined ? (
						this.state['list'+stateName].results.map((item) => (
							<Select.Option value={item[key]}>{item[key]+" - "+item[value]+""}</Select.Option>
						))
					) : (
						this.state['list'+stateName].map((item) => (
							<Select.Option value={item[key]}>{item[key]+" - "+item[value]+""}</Select.Option>
						))
					)}
					{(this.state['list'+stateName] != undefined && this.state['list'+stateName].next != undefined &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
				</Select>
			)
		);
	}

	render() {
		if(this.props.layanan_str.length>0 && this.props.layanan_str.includes('Pembelian / Pemesanan Obat') == false){
			return <Redirect to="/admin/forbidden" />
		}
		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Row className="isi">
						<Col span={24}>
				        	<Card
				        		className="konten"
					            title={
					            	<Row>
					            		<Col lg={{ span: 20 }} xs={{ span:24 }}>
							            	<span className="konten-judul">
							            		{configClass.title}
							            		{(this.state.loadingData &&
									        		<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="30" alt="loading" />
									        	)}
							            	</span>
							            </Col>
						            	<Col lg={{ span: 4 }} sm={{ span: 24 }} xs={{ span:24 }} style={{textAlign: 'right'}}>
						            		<Button 
										        	loading={this.state.loadingButton}
										        	className="btn-refresh"
										        	size="small"
										        	onClick={() => this.getData()} 
										        >
										          <RedoOutlined />
										    </Button>
						            		{(this.state.dataSource.length > 0 &&
							            		<Button 
										        	loading={this.state.loadingButton}
										        	className="btn-primary"
										        	size="small" 
										        	onClick={() => this.showAproveConfirm()}
										        >
										          <CheckCircleOutlined /> approve
										        </Button>
										    )}
						            	</Col>
						            </Row>
					            }
					        >
					        	{(this.state.errMsg!='' &&
						        	<Alert
								      message={this.state.errTitle}
								      description={JSON.stringify(this.state.errMsg)}
								      type="error"
								      showIcon
								      style={{
								      	marginBottom: 10
								      }}
								    />
								)}
								
								<Form 
									layout="horizontal"
									name="fdata"
								    onFinish={this.handleSubmitFilter}
								    size="small"
								    ref={this.formRef}
								    {...formItemLayout}
								>
									<Row>
										<Col span={9}>
											<Form.Item
												label="Pilih Outlet"
												name="outlet"
												rules={[
													{ 
													    required: true, 
													    message: 'harus dipilih' 
													}
												]}
											>
												{this.generateSelect('Outlet', configClass.apiUrl.outlet, 'id', 'nama')}
											</Form.Item>
										</Col>
										<Col span={9}>
											<Form.Item
												label="Pilih Kasir"
												name="kasir"
											>
												{this.generateSelect('Kasir', configClass.apiUrl.kasir, 'id', 'nama')}
											</Form.Item>
										</Col>
										<Col span={6}>
											<Row>
											        <Col style={{paddingLeft: 10}}>
											        	<Button 
															key="btn-simpan"
															loading={this.state.loadingButton}
															type="primary" 
															htmlType="submit"
															disabled={this.state.errComponent?true:false}
															size="small"
														>
															{(!this.state.loadingButton && <EyeOutlined />)}
															Lihat
														</Button>
													</Col>
													{(this.state.filter != '' && this.state.dataSource.length > 0 &&
														<Col style={{paddingLeft: 10}}>
															<a href={"/admin/cetak_verifikasi_penjualan?"+this.state.filter} target="_blank">
													        	<Button 
																	key="btn-cetk"
																	loading={this.state.loadingButton}
																	type="danger" 
																	htmlType="button"
																	disabled={this.state.errComponent?true:false}
																	size="small"
																>
																	{(!this.state.loadingButton && <EyeOutlined />)}
																	Cetak
																</Button>
															</a>
														</Col>
													)}
											</Row>
										</Col>
									</Row>
								</Form>
						
					        	{(this.state.filter != '' &&
						        	<Table 
						        		style={{marginTop: 10}}
						        		title={() => 
						        			<Row>
						        				<Col lg={8} sm={24} xs={24}><span className="konten-judul">Total {this.state.pagination.total} record</span></Col>
												<Col lg={{span: 8, offset: 8}} sm={24} xs={24}>
													<Input.Search 
														size="small"
														placeholder="ketikkan pencarian" 
														onSearch={value => {
															this.setState({
																searchText: value
															}, () =>{
																	this.getData();
															});
														}} 
														allowClear
													/>										
												</Col>
											</Row>					        			
						        		}
						        		bordered
						        		columns={this.state.columns} 
						        		dataSource={this.state.dataSource} 
						        		loading={this.state.loadingData} 
						        		scroll={{ x: 240, y: 450 }}
						        		size="small"
						        		pagination={this.state.pagination}
						        		onChange={this.handleTableChange}
						        	/>
						        )}

					        </Card>
				        </Col>
				    </Row>
				</div>
				<Modal
				  autoFocus={false}
				  closable={true}
				  visible={this.state.modalPembayaran}
		          title="pembayaran / angsuran"
		          onCancel={() =>{
		          	this.setState({
		          		modalPembayaran: false
		          	});
		          }}
		          footer={[]}
		        >
		        	<Form
		        		ref={this.formPembayaran}
				        labelCol={{ span: 10 }}
				        wrapperCol={{ span: 14 }}
				        layout="horizontal"
				        size={"small"}
				        initialValues={{
				        	preview_total_harga: this.state.total_harga,
				        	preview_total_bayar: this.state.total_bayar,
				        	preview_total_angsuran: this.state.total_angsuran,
				        	preview_total_kekurangan: this.state.total_kekurangan
				        }}
				        onFinish={this.handleSubmit}
				    >
				    	<Form.Item label="Pembelian Total" name="preview_total_harga">
				          <NumberFormat 
							style={{textTransform: 'none'}}
							autoComplete="off" 
							thousandSeparator={true} 
							prefix={'Rp. '}
							className="ant-input ant-input-sm"
							readOnly
							/>
				        </Form.Item>
				        <Form.Item label="Total Sudah Dibayar" name="preview_total_bayar">
				          <NumberFormat 
							style={{textTransform: 'none'}}
							autoComplete="off" 
							thousandSeparator={true} 
							prefix={'Rp. '}
							className="ant-input ant-input-sm"
							readOnly
							/>
				        </Form.Item>
				        <Form.Item label="Saldo Sebelum" name="preview_total_kekurangan">
				          <NumberFormat 
							style={{textTransform: 'none'}}
							autoComplete="off" 
							thousandSeparator={true} 
							prefix={'Rp. '}
							className="ant-input ant-input-sm"
							readOnly
							/>
				        </Form.Item>
				        		<Form.Item 
					        		label="Dibayar" 
					        		name="preview_total_angsuran" 
					        		rules={[
										{ 
											required: true, 
											message: 'harus diisi' 
										}
									]}
					        	>
						          	<NumberFormat 
						          		autoFocus={true}
										style={{textTransform: 'none'}}
										autoComplete="off" 
										thousandSeparator={true} 
										prefix={'Rp. '}
										className="ant-input ant-input-sm"
								        onValueChange={(values) => {
									        const {formattedValue, value} = values;
									        
									        var kekurangan = this.state.total_kekurangan - value;
									        
									        this.setState({
									        	kekurangan: kekurangan,
									        	total_angsuran: value,
									        }, () =>{
									        	this.formPembayaran.current.setFieldsValue({
									        		preview_saldo_setelah: kekurangan,
									        		nominal: value
									        	});
									        });
									        // if(kembali >= 0){
										        // this.formPembayaran.current.setFieldsValue({
										        // 	kekurangan: 'Rp. '+parseFloat(kekurangan).toLocaleString('en-US')
										        // });
										    // }
									        // this.setState({
									        //    harga: parseFloat(value)
									        // });                       // console.log('harga', values);
								        }}
								  	/>
						        </Form.Item>
						        <Form.Item label="Total Angsuran" name="nominal" value={this.state.total_angsuran} style={{display: 'none'}}>
						          	<Input />
						        </Form.Item>
						  <Form.Item label="Saldo Setelah" name="preview_saldo_setelah">
					          <NumberFormat 
								style={{textTransform: 'none'}}
								autoComplete="off" 
								thousandSeparator={true} 
								prefix={'Rp. '}
								className="ant-input ant-input-sm"
								readOnly
								/>
							</Form.Item>
				        <Row
				        	justify="end"
				        >
				        	<Col style={{padding: 5}}>
						        <Button 
						        	type="primary" 
						        	htmlType="submit"
						        	disabled={this.state.kembali < 0 ? true : false}
						        >
						        	Simpan
						        </Button>
					        </Col>
					        <Col style={{padding: 5}}>
						        <Button 
						        	type="danger"
						        	htmlType="button"
						        	onClick={() =>{
							          	this.setState({
							          		modalPembayaran: false
							          	});
							        }}
						        >
						          Cancel
						        </Button>
						    </Col>
						</Row>
			        </Form>
		        </Modal>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(PembayaranPenjualan);