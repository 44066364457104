import React from 'react';
import { Link, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Avatar,
	Form, 
	Input,
	Button,
	Space,
	Select,
	DatePicker,
	message,
	Alert,
	Spin,
	Divider,
	Result,
	Modal, 
	Radio
} from 'antd';
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ShoppingOutlined,
  SearchOutlined,
  BarcodeOutlined,
  TagOutlined,
  RightOutlined,
  WarningOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import axios from 'axios';
import Config from '../../Config';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import {Helmet} from "react-helmet";

const FormItem = Form.Item

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    }
  },
};

const configClass = {
	title: 'transaksi penjualan wholesale',
	apiUrl: {
		penjualan: "transaksi/api/penjualan/",
		produk: "master/api/produk/penjualan",
		preview: "transaksi/api/penjualan/",
		kategori: "master/api/kategori/produk",
		outlet: "master/api/kategori/outlet",
		customer: "master/api/customer/",
		pajak: "pengaturan/api/pajak",
		jenis_bayar: "master/api/jenis_bayar/",
	},
	pageUrl: {
		data: '/admin/produk/'
	},
	breadCrumb: [
		(
			<span>
				<ShoppingOutlined /> penjualan
			</span>
		),
		'transaksi penjualan wholesale'
	]

}

const config = new Config();

class FormPenjualanWholesale extends React.Component {
	formRef = React.createRef();
	barcodeRef = React.createRef();
	formPembayaran = React.createRef();
	no_mejaref = React.createRef();

	constructor(props){
		super(props);

		this.state = {
			errComponent: false,
			errTitle: '',
			errMsg: '',
			loadingButton: false,

			loadingBarcode: false,
			barcodeIsNotFound: false,
			barcodeResults: [],

			loadingProduk: false,
			listProduk: [],
			loadingKategori: false,
			listKategori: [],
			loadingOutlet: false,
			listOutlet: [],
			loadingJenisBayar: false,
			listJenisBayar: [],

			loadingCustomer: false,
			listCustomer: [],

			loadingCart: [],
			cart: [],

			gambar: null,
			
			aksi: 'tambah',
			method: 'post',
			idLama: '',

			searchText: '',
			barcodeText: '',

			modalPembayaran: false,
			kategori: '',

			total: 0,
			total_final: 0,
			dibayar: 0,
			kembali: 0,
			diskon_persen: '',
			diskon_rp: '',

			nama_pajak: '',
			nilai_pajak: 0,

			customer_preview: null,
			customer: undefined,

			preview_no_meja: null,
			preview_diskon_rp: 0,
			preview_pembayaran: 0,
			preview_jenis_bayar: undefined
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.barcodeRef.current.focus();
		this.props.setConfig({
			collapsed: true
		});
		// this.loadnjljlMasterList();
		this.getPajak();
		this.getProduk();
		this.getKategori();
		this.getJenisBayar();

		this.loadMasterList();
	}

	async getPajak(){
		this.setState({loadingPajak: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.pajak)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				if(data.results.length > 0 ){
					this.setState({
						nama_pajak: data.results[0].jenis_detail.nama,
						nilai_pajak: data.results[0].nilai
					})
				}else{
					this.setState({
						nama_pajak: '',
						nilai_pajak: 0,
					});
				}
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({
	        	loadingPajak: false,
	        });
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingPajak: false});
		});
	}

	async scanProduk(barcode){
		this.setState({loadingBarcode: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.produk+'?barcode='+barcode)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				if(data.results.length > 0){
					this.setState({
						barcodeIsNotFound: false,
						barcodeResults: data.results,
						loadingBarcode: false
					}, () =>{
						let temp_cart = this.state.cart;
					    // let temp_label_produk = this.state.label_produk;
					    let that = this;
					    if(temp_cart.length==0){
					      let produk = this.state.barcodeResults[0];
					      // let harga = 0;
					      // let diskon_rupiah = 0;
					      // if(produk['diskon']!="" && produk['diskon'].rupiah>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty){
					      // 	console.log('tes_produk', produk);
					      // 	// console.log('tes_diskon_rupiah', produk['diskon'].rupiah)
					      // 	// diskon_rupiah = produk['diskon'].rupiah;
					      // }
					      
					      temp_cart.push({
					          id: produk['id'],
					          nama: produk['nama'],
					          harga: produk['harga'],
					          qty: 1,
					          gambar: produk['gambar'],
					          diskon_rp: produk['diskon']!="" && produk['diskon'].rupiah>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty ? produk['diskon'].rupiah : 0,
					          diskon_persen: produk['diskon']!="" && produk['diskon'].persen>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty ? produk['diskon'].persen : 0,
					      });
					      // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US');
					    }else{
					      const index_cari = temp_cart.findIndex(data => data.id==this.state.barcodeResults[0].id);
					      if(index_cari==-1){
					        let produk = this.state.barcodeResults[0];
					        // let harga = 0;
					        
					        temp_cart.push({
					        	id: produk['id'],
						        nama: produk['nama'],
						        harga: produk['harga'],
						        qty: 1,
						        gambar: produk['gambar'],
						        diskon_rp: produk['diskon']!="" && produk['diskon'].rupiah>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty ? produk['diskon'].rupiah : 0,
						        diskon_persen: produk['diskon']!="" && produk['diskon'].persen>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty ? produk['diskon'].persen : 0,
					        });
					        // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US');
					      }else{
					        let produk = this.state.barcodeResults[0];
					        // let harga = 0;
					        
					        // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US')+' x '+(temp_cart[index_cari]['qty']+1);
					        temp_cart[index_cari]['qty'] = temp_cart[index_cari]['qty']+1;
					        temp_cart[index_cari]['diskon_rp'] = produk['diskon']!="" && produk['diskon'].rupiah>0 && temp_cart[index_cari]['qty']>=produk['diskon'].min_qty && temp_cart[index_cari]['qty']<=produk['diskon'].max_qty ? produk['diskon'].rupiah : 0;
					        temp_cart[index_cari]['diskon_persen'] = produk['diskon']!="" && produk['diskon'].persen>0 && temp_cart[index_cari]['qty']>=produk['diskon'].min_qty && temp_cart[index_cari]['qty']<=produk['diskon'].max_qty ? produk['diskon'].persen : 0;
					      }
					    }
					    this.setState({
					      cart: temp_cart,
					      // label_produk: temp_label_produk
					    }, () =>{
					    	// console.log('cart', this.state.cart)
					    }); 
					    var temp_total = 0;
					    this.state.cart.map(item => {
					      temp_total += (item['harga']*item['qty']);
					    })
					    this.setState({
					      total: temp_total,
					      total_final: temp_total,
					      barcodeText: ''
					    });
					});
				}else{
					this.setState({
						barcodeIsNotFound: true,
						barcodeResults: [],
						loadingBarcode: false,
					    barcodeText: ''
					});
				}
				// this.barcodeRef.current.val = undefined;
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg,
	        		loadingBarcode: false,
	        		barcodeIsNotFound: false,
					barcodeText: ''
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingProduk: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingBarcode: false});
		});
	}

	async getProduk(kategori="", limit=50000, offset=0, search=this.state.searchText){
		this.setState({loadingProduk: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.produk+'?limit='+limit+'&offset='+offset+'&search='+search+'&kategori='+kategori)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				this.setState({
					listProduk: data.results
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({
	        	loadingProduk: false,
	        	kategori: kategori
	        });
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingProduk: false});
		});
	}

	async getKategori(limit=50000, offset=0, search=this.state.searchText){
		this.setState({loadingKategori: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.kategori+'?limit='+limit+'&offset='+offset+'&search='+search)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				this.setState({
					listKategori: data.results
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingKategori: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingKategori: false});
		});
	}

	async getJenisBayar(limit=50000, offset=0, search=this.state.searchText){
		this.setState({loadingJenisBayar: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.jenis_bayar+'?limit='+limit+'&offset='+offset+'&search='+search)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				this.setState({
					listJenisBayar: data.results,
				}, () => {
					if(data.results.length > 0){
						this.setState({
							preview_jenis_bayar: data.results[0].id
						});
					}
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingJenisBayar: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingJenisBayar: false});
		});
	}

	kurangi(id){
	    const temp_cart = this.state.cart;
	    const index_cari = temp_cart.findIndex(data => data.id==id);
	    if(temp_cart[index_cari]['qty']==1){//hapus item
	      temp_cart.splice(index_cari, 1);
	    }else{//kurangi item
	      temp_cart[index_cari]['qty'] = temp_cart[index_cari]['qty']-1;
	    }
	    this.setState({
	      cart: temp_cart
	    }); 
	    var temp_total = 0;
	    this.state.cart.map(item => {
	      // temp_total += (item['harga']*item['qty']);
	      temp_total += ((item['harga']*item['qty']) - item['diskon_rp'] - (((item.harga*item.qty)/100)*item.diskon_persen));
	    })
	    this.setState({
	      total: temp_total,
	      total_final: temp_total,
	    });
	    
	}
	tambah(id){
	    const temp_cart = this.state.cart;
	    const index_cari = temp_cart.findIndex(data => data.id==id);
	    temp_cart[index_cari]['qty'] = temp_cart[index_cari]['qty']+1;
	    this.setState({
	      cart: temp_cart
	    }); 
	    var temp_total = 0;
	    this.state.cart.map(item => {
	      //temp_total += (item['harga']*item['qty']);
	      temp_total += ((item['harga']*item['qty']) - item['diskon_rp'] - (((item.harga*item.qty)/100)*item.diskon_persen));
	    })
	    this.setState({
	      total: temp_total,
	      total_final: temp_total,
	    });
	}

	setQty(e, id){
	    const temp_cart = this.state.cart;
	    const index_cari = temp_cart.findIndex(data => data.id==id);
	    if(e.target.value=='0'){
	      temp_cart.splice(index_cari, 1);
	    }else{
	      temp_cart[index_cari]['qty'] = e.target.value;
	    }
	    this.setState({
	      cart: temp_cart
	    }); 
	    var temp_total = 0;
	    this.state.cart.map(item => {
	      // temp_total += (item['harga']*item['qty']);
	      temp_total += ((item['harga']*item['qty']) - item['diskon_rp'] - (((item.harga*item.qty)/100)*item.diskon_persen));
	    })
	    this.setState({
	      total: temp_total,
	      total_final: temp_total,
	    });
	}

	addToCart(id){
		let temp_cart = this.state.cart;
	    // let temp_label_produk = this.state.label_produk;
	    let that = this;
	    const index_cari_produk = this.state.listProduk.findIndex(data => data.id==id);
	    if(temp_cart.length==0){
	      let produk = this.state.listProduk[index_cari_produk];
	      // let harga = 0;
	      // let diskon_rupiah = 0;
	      // if(produk['diskon']!="" && produk['diskon'].rupiah>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty){
	      // 	console.log('tes_produk', produk);
	      // 	// console.log('tes_diskon_rupiah', produk['diskon'].rupiah)
	      // 	// diskon_rupiah = produk['diskon'].rupiah;
	      // }
	      
	      temp_cart.push({
	          id: produk['id'],
	          nama: produk['nama'],
	          harga: produk['harga'],
	          qty: 1,
	          gambar: produk['gambar'],
	          diskon_rp: produk['diskon']!="" && produk['diskon'].rupiah>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty ? produk['diskon'].rupiah : 0,
	          diskon_persen: produk['diskon']!="" && produk['diskon'].persen>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty ? produk['diskon'].persen : 0,
	      });
	      // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US');
	    }else{
	      const index_cari = temp_cart.findIndex(data => data.id==id);
	      if(index_cari==-1){
	        let produk = this.state.listProduk[index_cari_produk];
	        // let harga = 0;
	        
	        temp_cart.push({
	        	id: produk['id'],
		        nama: produk['nama'],
		        harga: produk['harga'],
		        qty: 1,
		        gambar: produk['gambar'],
		        diskon_rp: produk['diskon']!="" && produk['diskon'].rupiah>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty ? produk['diskon'].rupiah : 0,
		        diskon_persen: produk['diskon']!="" && produk['diskon'].persen>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty ? produk['diskon'].persen : 0,
	        });
	        // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US');
	      }else{
	        let produk = this.state.listProduk[index_cari_produk];

	        // let harga = 0;
	        
	        // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US')+' x '+(temp_cart[index_cari]['qty']+1);
	        temp_cart[index_cari]['qty'] = temp_cart[index_cari]['qty']+1;
	        let jml = produk['diskon']!="" && produk['diskon'].persen>0 && temp_cart[index_cari]['qty']>=produk['diskon'].min_qty && temp_cart[index_cari]['qty']<=produk['diskon'].max_qty ? produk['diskon'].persen : 0;
	        // alert(produk['diskon'].min_qty+", "+produk['diskon'].max_qty+", "+jml+", "+temp_cart[index_cari]['qty']);
	        temp_cart[index_cari]['diskon_rp'] = produk['diskon']!="" && produk['diskon'].rupiah>0 && temp_cart[index_cari]['qty']>=produk['diskon'].min_qty && temp_cart[index_cari]['qty']<=produk['diskon'].max_qty ? produk['diskon'].rupiah : 0;
	        temp_cart[index_cari]['diskon_persen'] = produk['diskon']!="" && produk['diskon'].persen>0 && temp_cart[index_cari]['qty']>=produk['diskon'].min_qty && temp_cart[index_cari]['qty']<=produk['diskon'].max_qty ? produk['diskon'].persen : 0;
	      }
	    }
	    this.setState({
	      cart: temp_cart,
	      // label_produk: temp_label_produk
	    }, () =>{
	    	console.log('cart', this.state.cart)
	    }); 
	    var temp_total = 0;
	    this.state.cart.map(item => {
	      temp_total += ((item['harga']*item['qty']) - item['diskon_rp'] - (((item.harga*item.qty)/100)*item.diskon_persen))
	    })
	    this.setState({
	      total: temp_total,
	      total_final: temp_total,
	      barcodeIsNotFound: false
	    });
	}

	setGambar = (e) => {
	    this.setState({
			'gambar': e.target.files[0]
		});
	}



	async preview(id) {
		message.loading({ content: 'Menampilkan data sebelumnya...', duration: 0, key: 'toast' });

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview+id)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				var temp = [];
				var temp_total = 0;
				data.detail.map((item, idx) => {
					temp = [...temp, {
						id: item.produk,
						nama: item.produk_nama,
						harga: parseFloat(item.harga),
						qty: parseFloat(item.qty),
						gambar: '',
						diskon_rp: parseFloat(item.diskon_persen) >0 ? 0 : parseFloat(item.diskon_rp),
						diskon_persen: parseFloat(item.diskon_persen),
						deleted: false,
					}];
					temp_total += ((item['harga']*item['qty']) - item['diskon_rp'])
				});

	        	message.destroy();
	        	this.setState({
	        		aksi: 'edit',
	        		method: 'put',
	        		idLama: id+'/',
	        		cart: temp,
	        		nama_pajak: data.info_pajak != "" && data.info_pajak.length > 0 ? data.info_pajak[0].jenis_pajak : '',
	        		nilai_pajak: data.info_pajak != "" && data.info_pajak.length > 0 ? parseFloat(data.info_pajak[0].nilai_pajak) : 0,
	        		total: parseFloat(data.total_harga)+parseFloat(data.diskon),
	      			total_final: parseFloat(data.total_harga),
	      			customer_preview: Object.keys(data.info_customer).length > 0 ? data.info_customer : null,
	      			customer: Object.keys(data.info_customer).length > 0 ? data.customer : undefined,
	      			preview_no_meja: data.no_meja,
	      			preview_diskon_rp: data.diskon,
	      			preview_pembayaran: data.total_bayar,
	      			kembali: parseFloat(data.total_bayar) - parseFloat(data.harga_akhir),
	      			preview_jenis_bayar: data.jenis_bayar
	        	});
	        }else{
	        	const errTitle = 'error preview data lama';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	handleSubmit = (values) =>{
		// var datas = values;
		var datas = {
		    // "outlet": 1,
		    "total_harga": values['total'],
		    "tgl_pesan":null,
		    // "outlet": 1,
		    "direct": false
		};
		// if(values.diskon_persen != null || values.diskon_rp != null){
			datas = {...datas, 
				diskon: this.state.diskon_rp == '' || this.state.diskon_rp == null ? 0 : this.state.diskon_rp
			};
		// }
		// datas = {...datas, diskon: 0};
		for ( var key in values ) {
			if(values[key] == undefined){
				datas = {...datas, [key]: null}
			}else{
				datas = {...datas, [key]: values[key]}
			}
		}
		var detail = [];
		this.state.cart.map(item => {
			detail = [...detail, {
				produk: item.id,
				qty: item.qty,
				harga: item.harga,
				diskon_rp: item.diskon_rp,
				diskon_persen: item.diskon_persen,
				deleted: false
			}];
		});

		datas = {...datas, 'detail': detail};

		// console.log('datas', datas);
		this.postData(datas);

	}

// 	handleSubmit = (values) =>{
// 		// diskon_persen: undefined
// 		// diskon_rp: undefined
// 		// diskon_rp_preview: undefined
// 		// jenis_bayar: "Cash"
// 		// kembali: "Rp. 0"
// 		// nama_cust: undefined
// 		// no_meja: undefined
// 		// pp1: 0
// 		// ppn: 40500
// 		// preview_dibayar: 445500
// 		// total: 405000
// 		// total_bayar: 445500
// 		var datas = values;
// 		for ( var key in values ) {
// 			if(values[key] == undefined){
// 				datas = {...datas, [key]: null};
// 			}else{
// 				datas = {...datas, [key]: values[key]+""};
// 			}
// 		}
// ​
// 		console.log('datas', datas);
// 		// var datas = {
// 		//     "outlet": 1,
// 		//     "total_harga": this.tate.total,
// 		//     "total_bayar": this.state,
// 		//     "jenis_bayar": "cash",
// 		//     "diskon":null,
// 		//     "tgl_pesan":null,
// 		//     "no_meja":null,
// 		//     "detail":[
// 		//         {
// 		//             "produk":8,
// 		//             "qty":2,
// 		//             "harga":1000,
// 		//             "diskon_rp":0,
// 		//             "diskon_persen":0
// 		//         }
// 		//     ]
// 		// };
		
// 		// var datas = new FormData();
// 		// for ( var key in values ) {
// 		// 	if(values[key] == undefined){
// 		// 		datas.append(key, '');
// 		// 	}else{
// 		// 		datas.append(key, values[key].toString());
// 		// 	}
// 		// }
// 		// datas.append('provider', this.props.id);
// 		// if(this.state.gambar != null){
// 		// 	datas.append('gambar', this.state.gambar, this.state.gambar.name);
// 		// }else{
// 		// 	datas.append('gambar', '');
// 		// }
// 		// this.postData(datas);
// 	}

	reset() {
		this.setState({
			errComponent: false,
			errTitle: '',
			errMsg: '',
			loadingButton: false,

			loadingBarcode: false,
			barcodeIsNotFound: false,
			barcodeResults: [],

			loadingCart: false,
			cart: [],

			searchText: '',
			barcodeText: '',

			modalPembayaran: false,
			kategori: '',

			total: 0,
			total_final: 0,
			dibayar: 0,
			kembali: 0,
			diskon_persen: '',
			diskon_rp: '',
			redirect: this.state.idLama != '' ? true : false
		}, () =>{
			if(this.state.idLama == ''){
				this.getProduk();
				this.getKategori();

				// this.loadMasterList();
			}
			if(this.barcodeRef.current != null){
				this.barcodeRef.current.focus();
			}
		});
	}

	async postData(datas) {
		message.loading({ content: 'Memproses...', duration: 0, key: 'toast' });
		this.setState({
			errTitle: '',
			errMsg: '',
			loadingButton: true
		});

		var that = this;

		this.props.ajaxHandler(this.state.method, configClass.apiUrl.penjualan+this.state.idLama, datas, false, true)
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			message.destroy();
			if(this.props.isSuccess){
				this.setState({
					id: this.props.responseMsg.id,
					modalPembayaran: false,
				}, () =>{
					Modal.confirm({
					    title: 'Transaksi sukses',
					    icon: <CheckCircleOutlined style={{color: 'green'}} />,
					    content: 'tekan cetak untuk mencetak, tekan selesai untuk melanjutkan',
					    okText: <a href={"/admin/nota/"+this.props.responseMsg.id} target="_blank"><PrinterOutlined /> Cetak</a>,
					    cancelText: 'Selesai',
					    onOk() {
					    	that.reset()
					    },
					    onCancel() {
					    	that.reset()
					    }
					});
				});
			}else{
				const errTitle = 'gagal menyimpan data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_post', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	loadMasterList(){
		const rule = [
			{
				destination: {
					stateProgressName: 'loadingCustomer',
					endPoint: configClass.apiUrl.customer,
					stateDestination: 'listCustomer',
				},
				nextDestination: null
			}
		];

		this.setState({
			errComponent: false
		});

		rule.map((item, index) => {
			if(index == rule.length-1){
				this.getMasterList(item, true);
			}else{
				this.getMasterList(item);
			}
		});
	}

	async getMasterList(rule, isFinal=false) {
		var destination = rule.destination;
		var nextDestination = rule.nextDestination;
		this.setState({[destination.stateProgressName]: true});
		this.props.ajaxViewHandler('get', destination.endPoint)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				if(data.count != undefined){
					let newState = null;
					if(this.state[destination.stateDestination].length == 0){
						newState = data;
					}else{
						if(destination.stateProgressName.includes('_searching')){
							newState = data
						}else{
							const oldState = this.state[destination.stateDestination];
							newState = {
								count: data.count,
								next: data.next,
								previous: data.previous,
								results: [...oldState.results, ...data.results]
							};
						}
					}
					this.setState({
		            	[destination.stateDestination]: newState
		            });
				}else if(data.results != undefined){
	        		this.setState({
		            	[destination.stateDestination]: data.results
		            });
	        	}else{
	        		if(Array.isArray(data)){
			            this.setState({
			            	[destination.stateDestination]: data
			            })
			        }else{
			        	this.setState({
			            	[destination.stateDestination]: [data]
			            })
			        }
		        }
		        if(isFinal){
		        	if(this.props.match.params.id != undefined){
						this.preview(this.props.match.params.id);
					}
		        }
		    }else{
		    	this.setState({
	        		errComponent: true
	        	});
		    }
		    this.setState({[destination.stateProgressName]: false});
		}).catch((response) => {
			//console.log('gagal_get_master', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({
				errTitle: 'gagal request data master',
				errMsg: response
			});
			this.setState({[destination.stateProgressName]: false});
		});
        //next function
        if(nextDestination != null){
        	const next = {
        		destination: {
	        		stateProgressName: nextDestination.stateProgressName,
	        		endPoint: nextDestination.endPoint,
	        		stateDestination: nextDestination.stateDestination,
	        	},
        		nextDestination: nextDestination.next

        	}
        	this.getMasterList(next);
        }
	}

	

	generateSelect(stateName, url, key, value){
		console.log('cek_for_error', stateName);
		return (
			this.state['loading'+stateName]?(
				<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
			):(
				<Select
					showSearch
					placeholder="pilih"
					optionFilterProp="children"
					onSearch={ val => {
						const rule = {
							destination: {
								stateProgressName: 'loading'+stateName+'_searching',
								endPoint: url+'?search='+val,
								stateDestination: 'list'+stateName
							},
							nextDestination: null
						}
						this.getMasterList(rule);
					}}
					onPopupScroll={ e => {
						e.persist(); 
						let target = e.target;
						if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
							if(this.state['list'+stateName].next != undefined && this.state['list'+stateName].next != null){
								const rule = {
									destination: {
										stateProgressName: 'loading'+stateName+'Paging',
										endPoint: this.state['list'+stateName].next,
										stateDestination: 'list'+stateName
									},
									nextDestination: null
								}
								this.getMasterList(rule);
							}
						}
					}}
				>
					{(this.state['loading_'+stateName+'searching'] &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
					{this.state['list'+stateName].count != undefined ? (
						this.state['list'+stateName].results.map((item) => (
							<Select.Option value={item[key]}>{item[key]+" - "+item[value]+""}</Select.Option>
						))
					) : (
						this.state['list'+stateName].map((item) => (
							<Select.Option value={item[key]}>{item[key]+" - "+item[value]+""}</Select.Option>
						))
					)}
					{(this.state['list'+stateName] != undefined && this.state['list'+stateName].next != undefined &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
				</Select>
			)
		);
	}

	showPembayaran = () =>{
		var that = this;
		this.setState({
			modalPembayaran: true
		}, () =>{
			if(this.formPembayaran.current != null){
				this.formPembayaran.current.setFieldsValue({
					// jenis_bayar: this.state.jenis_bayar,
				    total: this.state.total,
				    preview_dibayar: this.state.idLama == '' ? this.state.total+((this.state.total_final/100)*this.state.nilai_pajak) : this.state.preview_pembayaran,
				    total_bayar: this.state.total+((this.state.total_final/100)*this.state.nilai_pajak),
				    preview_kembali: this.state.idLama == '' ? 'Rp. 0' : 'Rp. '+parseFloat(this.state.kembali).toLocaleString('en-us'),
				    pajak: this.state.total!=this.state.total_final ? ((this.state.total_final/100)*this.state.nilai_pajak) : ((this.state.total/100)*this.state.nilai_pajak),
				    customer_preview: this.state.customer_preview != null ? this.state.customer_preview.nama : '',
				    customer: this.state.customer_preview != null ? this.state.customer : undefined,
				    no_meja: this.state.preview_no_meja,
				    diskon_rp: this.state.preview_diskon_rp,
				    jenis_bayar: this.state.preview_jenis_bayar
				});
				// this.no_mejaref.current.focus();
				// if(this.state.customer_preview != null){
				// 	this.formPembayaran.current.setFieldsValue({
				// 		customer_preview: this.state.customer_preview.nama
				// 	});
				// }
				// if(this.no_mejaref.current != null){
				// 	this.no_mejaref.current.focus();
				// }
				// this.formPembayaran.current.no_meja.focus();
			}
		});
	}

	setDiskonPersen = (e) =>{
		if(e.target.value != ''){
			var diskon_rp = ((this.state.total/100)*e.target.value);
			var gt_final = this.state.total - diskon_rp;

			this.setState({
				diskon_persen: e.target.value,
				diskon_rp: diskon_rp,
				total_final: gt_final,
			}, () =>{
				this.formPembayaran.current.setFieldsValue({
					diskon_rp: diskon_rp,
					diskon_rp_preview: diskon_rp,
					dibayar: '',
					preview_dibayar: '',
					preview_kembali: '',
					// pp1: this.state.total!=this.state.total_final ? ((this.state.total_final/100)*this.state.pp1) : ((this.state.total/100)*this.state.pp1),
					nilai_pajak: this.state.total!=this.state.total_final ? ((this.state.total_final/100)*this.state.nilai_pajak) : ((this.state.total/100)*this.state.nilai_pajak)
				});
			});
		}else{
			this.setState({
				total_final: this.state.total,

			});
		}
	}

	setDiskonRupiah = (values) =>{
		const {formattedValue, value} = values;
		if(value != ''){
			var gt_diskon = (this.state.total - value);
			var gt_pajak = ((this.state.total - value)/100)*this.state.nilai_pajak;
			var gt_final = gt_diskon +  gt_pajak;


			this.setState({
				diskon_rp: value,
				diskon_persen: '',
				total_final: gt_diskon,
			}, () =>{
				this.formPembayaran.current.setFieldsValue({
					diskon_rp: value,
					// diskon_persen: '',
					dibayar: '',
					preview_dibayar: '',
					preview_kembali: '',
					// pp1: this.state.total!=this.state.total_final ? ((this.state.total_final/100)*this.state.pp1) : ((this.state.total/100)*this.state.pp1),
					// nilai_pajak: this.state.total!=this.state.total_final ? ((this.state.total_final/100)*this.state.nilai_pajak) : ((this.state.total/100)*this.state.nilai_pajak)
					// nilai_pajak: gt_pajak
				});
				if(this.state.nilai_pajak > 0){
					this.formPembayaran.current.setFieldsValue({
						pajak: gt_pajak
					});
				}
			});
		}else{
			this.setState({
				total_final: this.state.total,
			});
			this.formPembayaran.current.setFieldsValue({
				dibayar: '',
				preview_dibayar: '',
				preview_kembali: '',
			})
			if(this.state.nilai_pajak > 0){
				this.formPembayaran.current.setFieldsValue({
					pajak: (this.state.total/100)*this.state.nilai_pajak
				});
			}
		}
	}

	render() {
		// if(this.props.layanan_str.length>0 && this.props.layanan_str.includes('Pembelian / Pemesanan Obat') == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		// if(this.props.isRedirect){
		// 	return <Redirect to={configClass.pageUrl.data} />
		// }
		if(this.state.redirect){
			return <Redirect to={'/admin/verifikasi_penjualan'} />
		}

		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+config.get_site_info().name}</title>
				</Helmet>
				
				<Card className="konten">
					<Row className="isi" justify="space-between">
						<Col xl={{span: 15}} xxl={{span: 16}} className="penjualan-panel-kiri">
							<Row>
								<Col span={12} style={{paddingRight: 2}}>
									
								      <Input 
								      	value={this.state.barcodeText}
								      	onChange={(event) =>{
								      		this.setState({
								      			barcodeText: event.target.value
								      		})
								      	}}
								      	ref={this.barcodeRef}
								      	prefix={this.state.loadingBarcode ? <Spin /> : <BarcodeOutlined />}
								      	size="small" 
								      	placeholder="scan barcode disini" 
								      	className="penjualan-cart-barcode" 
								      	onKeyPress={event => {
							                if (event.key === 'Enter') {
							                	event.preventDefault();
							                	this.scanProduk(event.target.value);
							                }
							            }}
								      />
								    
								    {/*
									<Input 
										className="penjualan-cart-barcode"
										prefix={<BarcodeOutlined />}
										size="small" 
										placeholder="scan barcode disini" 
										onKeyPress={event => {
							                if (event.key === 'Enter') {
							                	event.preventDefault();
							                	this.setState({
							                		searchText: event.target.value
							                	}, () =>{
							                		this.getProduk();
							                	});
							                }
							            }}
									/>
									*/}
								</Col>
								<Col span={12} style={{paddingLeft: 2}}>
									<Input.Search
										allowClear
										className="penjualan-cart-cari"
										size="small" 
										placeholder="atau ketikkan pencarian disini" 
										onSearch={(val) =>{
											this.setState({
												searchText: val
											}, () =>{
												this.getProduk()
											});
										}}
									/>
								</Col>
							</Row>
							<PerfectScrollbar>
								{this.state.loadingProduk 
									?
										<div className="panel-cart-produk" align="center" style={{marginTop: 20, color: '#2596FF'}}>
											<Spin /><br />
											fetching product, please wait...
										</div>
									:
										<Row className="panel-cart-produk">
											{this.state.listProduk.map((item, idx) =>(
												<Col xxl={6} xl={8} className="cart-bound">
													<a href="#" onClick={() => this.addToCart(item.id)}>
														<div className="panel-cart-item">
															<div 
																className="penjualan-cart-image" 
																style={{
																	backgroundImage: "url(" + item.gambar + ")",
																	// backgroundImage: "url("+process.env.PUBLIC_URL + "/sample_produk/baju batik.jpg"+")"
																}}
															>
																{(item.diskon != "" &&
																	<div className="penjualan-cart-discount">
																		<TagOutlined />&nbsp;
																		<del>
																			{item.diskon.rupiah != 0 ? 'Rp. '+parseFloat(item.diskon.rupiah).toLocaleString('en-us') : parseFloat(item.diskon.persen)+'%'}
																		</del>
																		<span style={{fontSize: 10, fontStyle: 'italic'}}>
																			<br />
																			{'min: '+parseFloat(item.diskon.min_qty)+' '+item.satuan}
																		</span>
																	</div>
																)}
																<div className="penjualan-cart-price">
																	{'Rp. '+parseFloat(item.harga).toLocaleString('en-us')+' ('+item.stok+')'}
																</div>
															</div>
															<div className="penjualan-cart-nama">
																{item.nama}
															</div>
														</div>
													</a>
												</Col>
											))}
										</Row>
								}
							</PerfectScrollbar>
							<PerfectScrollbar>
								<div className="penjualan-cart-kategori">
									<Button 
										className={this.state.kategori=='' ? "penjualan-cart-kategori-item" : "penjualan-cart-kategori-item-selected"}
										onClick={
											() => this.getProduk('')
										}
									>
										Semua
									</Button>
									{this.state.loadingKategori 
									?
										<div align="center" style={{marginTop: 20, color: '#2596FF'}}>
											<Spin />											
										</div>
									:
										this.state.listKategori.map((item, idx) =>(
											<Button 
												className={this.state.kategori==item.id ? "penjualan-cart-kategori-item" : "penjualan-cart-kategori-item-selected"}
												onClick={
													() => this.getProduk(item.id)
												}
											>
												{item.nama}
											</Button>
										))
									}
									
								</div>
							</PerfectScrollbar>
						</Col>

						<Col xl={{span: 8}} xxl={{span: 7}} className="penjualan-panel-kanan">
							{(this.state.barcodeIsNotFound == true &&
								<Alert
							      message="Produk tidak ditemukan"
							      showIcon
							      description="produk yang anda input tidak ditemukan dalam sistem"
							      type="error"
							      closable
							    />
						    )}
							<div className="penjualan-panel-kanan-konten">
								<PerfectScrollbar className="panel-cart">
									<Row className="panel-daftar-belanja">
										<Col span={24}>Daftar Belanja</Col>
									</Row>
									{this.state.cart.length == 0
										?
											<Result
											    icon={<WarningOutlined style={{color: '#f9af4d'}} />}
											    title={
											    	<span style={{color: '#f9af4d'}}>
											    	daftar belanja kosong!
											    	</span>
											    }
											 />
										:
											this.state.cart.map((item, idx) => (
												<Row className="penjualan-cart-item">
													<Col span={7}>
														
																<Button
																	size="small"
																	type="text"
																	style={{
																		display: 'inline-block'
																	}}
																	onClick={
																		() => this.kurangi(item.id)
																	}
																>
																-
																</Button>
																<Input 
																	size="small"
																	value={item.qty} 
																	style={{
																		width: 40,
																		display: 'inline-block'
																	}}
																	onChange={(e) => this.setQty(e, item.id)} 
																/>
																<Button
																	size="small"
																	type="text"
																	style={{
																		display: 'inline-block'
																	}}
																	onClick={() => this.tambah(item.id)}
																>
																+
																</Button>
													</Col>
													<Col span={10} className="penjualan-cart-item-panel">
														<div className="penjualan-cart-item-nama">
															{item.nama}
														</div>
														{(item.diskon_rp > 0 &&
															<div className="penjualan-cart-item-discount">
																<TagOutlined /> 
																{'discount Rp. '+parseFloat(item.diskon_rp).toLocaleString('en-us')}
															</div>
														)}
														{(item.diskon_persen > 0 &&
															<div className="penjualan-cart-item-discount">
																<TagOutlined /> 
																{'discount '+item.diskon_persen+' % = '+parseFloat((item.harga*item.qty/100) * item.diskon_persen).toLocaleString('en-us')}
															</div>
														)}
													</Col>
													<Col span={7} className="penjualan-cart-item-sub-total-panel">
														<div className="penjualan-cart-item-sub-total">
															{'Rp. '+parseFloat(item.harga*item.qty - (item.diskon_rp) - (((item.harga*item.qty)/100)*item.diskon_persen) ).toLocaleString('en-us')}
														</div>
														<div className="penjualan-cart-item-harga">
															{'@'+parseFloat(item.harga).toLocaleString('en-us')}
														</div>
													</Col>
												</Row>
											))
									}
								</PerfectScrollbar>
							</div>
							<div className="total-cart-panel">
								<Button 
									className="btn-primary" style={{width: '100%'}}
									disabled={this.state.cart.length>0?false:true}
									onClick={this.showPembayaran}
								>
									<Row>
										<Col span={7}>
											Bayar
										</Col>
										<Col span={15} style={{fontSize: 18}}>
										 {'Rp. '+parseFloat(this.state.total+((this.state.total/100)*this.state.nilai_pajak)).toLocaleString('en-us')}
										</Col>
										<Col span={2}>
											<RightOutlined style={{fontSize: 18}} />
										</Col>
									</Row>	
								</Button>
							</div>
						</Col>
					</Row>
				</Card>
				<Modal
				  autoFocus={false}
				  closable={false}
				  className="penjualan-pembayaran"
		          visible={this.state.modalPembayaran}
		          title={
		          	<Row>
			          	<Col span={24} className="penjualan-pembayaran-total">
			          		<span style={{fontSize: 10}}>
			          			{'Sub Total Rp. '+parseFloat(this.state.total).toLocaleString('en-us')}<br />
			          		</span>
			          		{this.state.total_final != this.state.total 
			          			?
			          				<span>
			          					<del>{'Rp. '+parseFloat(this.state.total+((this.state.total/100)*this.state.nilai_pajak)).toLocaleString('en-us')}</del>
			          					<br />
			          					{'Total Rp. '+parseFloat(this.state.total_final+((this.state.total_final/100)*this.state.nilai_pajak)).toLocaleString('en-us')}
			          				</span>
			          			:
			          				<span>{'Total Rp. '+parseFloat(this.state.total+((this.state.total/100)*this.state.nilai_pajak)).toLocaleString('en-us')}</span>

			          		}
			          	</Col>
			        </Row>
		          }
		          onCancel={() =>{
		          	this.setState({
		          		modalPembayaran: false
		          	});
		          }}
		          footer={[]}
		        >
		        	<Form
		        		ref={this.formPembayaran}
				        labelCol={{ span: 10 }}
				        wrapperCol={{ span: 14 }}
				        layout="horizontal"
				        size={"small"}
				        initialValues={{
				        	// jenis_bayar: "Cash",
				        	// total: this.state.total,
				        	// preview_dibayar: this.state.idLama == '' ? this.state.total+((this.state.total_final/100)*this.state.nilai_pajak) : this.state.preview_pembayaran,
				        	// total_bayar: this.state.total+((this.state.total_final/100)*this.state.nilai_pajak),
				        	// preview_kembali: this.state.idLama == '' ? 'Rp. 0' : 'Rp. '+parseFloat(this.state.kembali).toLocaleString('en-us'),
				        	// pajak: this.state.total!=this.state.total_final ? ((this.state.total_final/100)*this.state.nilai_pajak) : ((this.state.total/100)*this.state.nilai_pajak),
				        	// customer_preview: this.state.customer_preview != null ? this.state.customer_preview.nama : '',
				        	// customer: this.state.customer_preview != null ? this.state.customer : undefined,
				        	// no_meja: this.state.preview_no_meja,
				        	// diskon_rp: this.state.preview_diskon_rp,
				        	// jenis_bayar: this.state.preview_jenis_bayar
				        	jenis_bayar: "Cash",
				        	total: this.state.total,
				        	preview_dibayar: this.state.idLama == '' ? this.state.total+((this.state.total_final/100)*this.state.nilai_pajak) : this.state.preview_pembayaran,
				        	total_bayar: this.state.total+((this.state.total_final/100)*this.state.nilai_pajak),
				        	preview_kembali: this.state.idLama == '' ? 'Rp. 0' : 'Rp. '+parseFloat(this.state.kembali).toLocaleString('en-us'),
				        	pajak: this.state.total!=this.state.total_final ? ((this.state.total_final/100)*this.state.nilai_pajak) : ((this.state.total/100)*this.state.nilai_pajak),
				        	customer_preview: this.state.customer_preview != null ? this.state.customer_preview.nama : '',
				        	customer: this.state.customer_preview != null ? this.state.customer : undefined,
				        	no_meja: this.state.preview_no_meja,
				        	diskon_rp: this.state.preview_diskon_rp,
				        	diskon_rp_preview: this.state.idLama != '' ? this.state.preview_diskon_rp : '',
				        	jenis_bayar: this.state.preview_jenis_bayar
				        }}
				        onFinish={this.handleSubmit}
				    >
				    	<Form.Item label="Total Rp" name="total" style={{display: 'none'}}>
				          <Input value={this.state.total} />
				        </Form.Item>
				        <Row>
				        	<Col span={12} style={{padding: 3}}>
				        		<Form.Item label="No Meja" name="no_meja" ref={this.no_mejaref}>
						          <Input autoFocus={true} placeholder="opsional" style={{textTransform: 'none'}} />
						        </Form.Item>
				        	</Col>
				        	<Col span={12} style={{padding: 3}}>
				        		{(this.state.nama_pajak != '' &&
					        		<Form.Item label={this.state.nama_pajak+" "+parseFloat(this.state.nilai_pajak)+"%"} name="pajak">
								          <Input placeholder="otomatis" value={this.state.total!=this.state.total_final ? ((this.state.total_final/100)*this.state.nilai_pajak) : ((this.state.total/100)*this.state.nilai_pajak)} readOnly style={{textTransform: 'none'}} />
								    </Form.Item>
							    )}
				        	</Col>
				        </Row>
				        {this.state.customer_preview == null
				        	?
						        <Form.Item 
						        	labelCol={{ span: 5 }}
						        	wrapperCol={{ span: 19 }}
						        	label="Customer" 
						        	name="customer"
						        	rules={[
										{ 
											required: true, 
											message: 'harus diisi' 
										}
									]}
						        >
						        	{this.generateSelect('Customer', configClass.apiUrl.customer, 'id', 'nama')}
								    
								</Form.Item>
							:
								<>
									<Form.Item 
										style={{display: 'none'}}
							        	labelCol={{ span: 5 }}
							        	wrapperCol={{ span: 19 }}
							        	label="Customer" 
							        	name="customer"
							        	rules={[
											{ 
												required: true, 
												message: 'harus diisi' 
											}
										]}
							        >
							        	<Input />
									</Form.Item>
									<Form.Item 
										style={{display: ''}}
							        	labelCol={{ span: 5 }}
							        	wrapperCol={{ span: 19 }}
							        	label="Customer" 
							        	name="customer_preview"
							        >
							        	<Input readOnly />
									</Form.Item>
								</>
							}	


				        {/*(this.state.nama_pajak != '' &&
					        <Row>
					        	<Col span={12} style={{padding: 3}}>
					        		<Form.Item label={this.state.nama_pajak+" "+parseFloat(this.state.nilai_pajak)+"%"} name="pajak">
							          <Input placeholder="otomatis" value={this.state.total!=this.state.total_final ? ((this.state.total_final/100)*this.state.nilai_pajak) : ((this.state.total/100)*this.state.nilai_pajak)} readOnly style={{textTransform: 'none'}} />
							        </Form.Item>
					        	</Col>
					        </Row>
					    )*/}
				        <Row>
				        	<Col span={24} style={{padding: 3}} >
				        		<Form.Item 
				        			label="Diskon Rp" 
				        			name="diskon_rp_preview"
				        			labelCol={{ span: 5 }}
						        	wrapperCol={{ span: 19 }}
				        		>
				        			<NumberFormat 
				        				placeholder="opsional"
										style={{textTransform: 'none'}}
										autoComplete="off" 
										thousandSeparator={true} 
										prefix={'Rp. '}
										className="ant-input ant-input-sm"
										onValueChange={(values) => this.setDiskonRupiah(values)}
								      //   onValueChange={(values) => {
									     //    const {formattedValue, value} = values;
									     //    this.formPembayaran.current.setFieldsValue({
										    //     diskon_rp: value,
										    //     diskon_persen: '',
										    // });
								      //   }}
								  	/>
						        </Form.Item>
				        	</Col>
				        	<Col span={12} style={{padding: 3}} style={{display: 'none'}}>
				        		<Form.Item label="Diskon Persen" name="diskon_persen" style={{display: 'none'}}>
						          <Input 
						          	value={this.state.diskon_persen}
						          	placeholder="opsional" 
						          	style={{textTransform: 'none'}} 
						          	onChange={this.setDiskonPersen}
						          />
						        </Form.Item>
				        	</Col>
				        </Row>
				        <Form.Item label="Diskon Rp" name="diskon_rp" style={{display: 'none'}}>
				          <Input value={this.state.diskon_rp} />
				        </Form.Item>
				        <Row>
							<Col span={12} style={{padding: 3}}>
								<Form.Item 
					        		label="Dibayar" 
					        		name="preview_dibayar" 
					        		rules={[
										{ 
											required: true, 
											message: 'harus diisi' 
										}
									]}
					        	>
						          	<NumberFormat 
										style={{textTransform: 'none'}}
										autoComplete="off" 
										thousandSeparator={true} 
										prefix={'Rp. '}
										className="ant-input ant-input-sm"
								        onValueChange={(values) => {
									        const {formattedValue, value} = values;
									        if(this.formPembayaran.current != null){
										        this.formPembayaran.current.setFieldsValue({
										        	total_bayar: value
										        });
										    }    
									        if(this.state.total != this.state.total_final){
									        	var kembali = value - (this.state.total_final + ((this.state.total_final/100)*this.state.nilai_pajak));
									        }else{
									        	// console.log('total', this.state.total);
									        	var kembali = value - (this.state.total + ((this.state.total_final/100)*this.state.nilai_pajak));
									        }
									        this.setState({
									        	kembali: kembali
									        });
									        // if(kembali >= 0){
									        if(this.formPembayaran.current != null){
										        this.formPembayaran.current.setFieldsValue({
										        	preview_kembali: 'Rp. '+parseFloat(kembali).toLocaleString('en-US')
										        });
										    }    
										    // }
									        // this.setState({
									        //    harga: parseFloat(value)
									        // });                       // console.log('harga', values);
								        }}
								  	/>
						        </Form.Item>
						        <Form.Item label="Total Bayar" name="total_bayar" style={{display: 'none'}}>
						          	<Input />
						        </Form.Item>
							</Col>
							<Col span={12} style={{padding: 3}}>
								<Form.Item 
						        	label={this.state.kembali >=0 ? "Kembali" : "Kekurangan"} 
						        	name="preview_kembali"
								    // validateStatus={this.state.kembali < 0 ? "error" : ''}
								    // help={this.state.kembali < 0 ? "nominal bayar masih kurang" : ''}
						        >
						          <Input readOnly style={{textTransform: 'none'}} />
						        </Form.Item>
							</Col>
						</Row>
				        <Row>
				        	<Col span={24}>
				        		{this.state.loadingJenisBayar
				        			? <Spin />
				        			: (this.state.listJenisBayar.length == 0 ? 'belum ada master jenis bayar' :
								        <Form.Item 
								        	labelCol={{ span: 5 }}
							        		wrapperCol={{ span: 19 }}
								        	label="Jenis Bayar" 
								        	name="jenis_bayar"
								        	rules={[
												{ 
													required: true, 
													message: 'harus dipilih' 
												}
											]}
								        >
									        <Radio.Group
									        	optionType="button"
									        	buttonStyle="solid"
									        >
									        	{(this.state.listJenisBayar.map((item, idx) =>(
									        		<Radio.Button value={item.id}>{item.nama}</Radio.Button>
									        	)))}
									        	{/*
									          <Radio.Button value="Cash">Cash</Radio.Button>
									          <Radio.Button value="BCA">BCA</Radio.Button>
									          <Radio.Button value="BNI">BNI</Radio.Button>
									          <Radio.Button value="BRI">BRI</Radio.Button>
									          <Radio.Button value="Mandiri">Mandiri</Radio.Button>
									          <Radio.Button value="CIMB Niaga">CIMB Niaga</Radio.Button>
									          <Radio.Button value="BPD">BPD</Radio.Button>
									          <Radio.Button value="Bank Lain">Bank Lain</Radio.Button>
									          <Radio.Button value="OVO">OVO</Radio.Button>
									          <Radio.Button value="Go Pay">Go Pay</Radio.Button>
									          <Radio.Button value="Shopee Pay">Shopee Pay</Radio.Button>
									          <Radio.Button value="Dana">Dana</Radio.Button>
									          <Radio.Button value="Link Aja">Link Aja</Radio.Button>
									      		*/}
									        </Radio.Group>
									    </Form.Item>
									  )
								}	    
							</Col>
						</Row>
				        <Row
				        	justify="end"
				        >
				        	<Col style={{padding: 5}}>
						        <Button 
						        	type="primary" 
						        	htmlType="submit"
						        	// disabled={this.state.kembali < 0 ? true : false}
						        >
						        	Simpan
						        </Button>
					        </Col>
					        <Col style={{padding: 5}}>
						        <Button 
						        	type="danger"
						        	htmlType="button"
						        	onClick={() =>{
							          	this.setState({
							          		modalPembayaran: false
							          	});
							        }}
						        >
						          Cancel
						        </Button>
						    </Col>
						</Row>
			        </Form>
		        </Modal>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(FormPenjualanWholesale);