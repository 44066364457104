import React from "react";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Breadcrumb,
  Card,
  Row,
  Col,
  Avatar,
  Form,
  Input,
  Button,
  Space,
  Select,
  DatePicker,
  message,
  Alert,
  Spin,
  Modal,
  Skeleton,
  Table,
  Tabs,
  Result,
} from "antd";
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  BarcodeOutlined,
  UserAddOutlined,
  PrinterOutlined,
  UserOutlined,
  WarningOutlined,
  SaveOutlined,
  FileSearchOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import NumberFormat from "react-number-format";

import axios from "axios";
import Config from "../../Config";

import { connect } from "react-redux";
import {
  initHandler,
  ajaxViewHandler,
  ajaxHandler,
  setConfig,
} from "../../store/actions";
import { Helmet } from "react-helmet";

const FormItem = Form.Item;

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    },
  },
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const configClass = {
  apiUrl: {
    produk: "master/api/produk",
    detail_transaksi: "transaksi/api/detail/transaksi/",
    dosis: "master/api/dosis",
    racikan: "master/api/racikan",
    aturan_makan: "master/api/aturan/makan/",
    waktu: "master/api/waktu/"
  },
  pageUrl: {
    cetak_resep: "/addon/cetak_resep/",
  },
};

const config = new Config();

class Obat extends React.Component {
  formRefObat = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      errComponent: false,
      errTitle: "",
      errMsg: "",
      loadingButton: false,

      jenis_layanan: "produk",

      loadingObat: false,
      listObat: [],

      loadingDosis: false,
      listDosis: [],

      loadingCarikan: false,
      listRacikan: [],

      loadingCarObat: false,
      cartObat: [],

      loadingAturanMakan: false,
      listAturanMakan: [],

      loadingWaktu: false,
      listWaktu: [],

      loadingBarcodeObat: false,
      barcodeObatIsNotFound: false,
      barcodeObatResults: null,

      totalObat: 0,
      total_finalObat: 0,
      barcodeTextObat: undefined,

      loadingSimpanObat: false,

      isFinish: false,
      dosis: null,
      keterangan: null,
      dosis_nama: null,
      racikan: null,
      racikan_nama: null,
      aturan_makan: null,
      waktu: null,

      isValid: false,
      // isValidAturanMakan: false,
      // isValidWaktu: false,

      tempKodeBarcode: null,
      tempIdProduk: null,

      method: "post",
    };
  }

  UNSAFE_componentWillMount() {
    this.props.initHandler();
  }

  componentDidMount() {
    this.loadMasterList();
    if (
      this.props.transaksi != null &&
      this.props.transaksi[0].transaksi != null &&
      this.props.transaksi[0].transaksi.length > 0
    ) {
      var transaksiExist = this.props.transaksi[0].transaksi;
      var tempCart = [];

      transaksiExist.map((item, idx) => {
        if (item.tipe == "2") {
          tempCart.push({
            id: item.produk,
            nama: item.produk_nama,
            harga: parseFloat(item.harga),
            qty: item.quantity,
            idx: item.id,
            keterangan: item.keterangan,
            dosis: item.dosis,
            dosis_nama: item.dosis_nama,
            racikan: item.racikan,
            racikan_nama: item.racikan_nama
          });
        }
      });
      this.setState({
        cartObat: tempCart,
      });
    }
  }

  componentDidUpdate(prevProps) {
    // console.log('prevprops', prevProps.transaksi);
    // console.log('newProps', this.props.transaksi);
    // alert(prevProps.transaksi);
    if (
      JSON.stringify(prevProps.transaksi) !=
      JSON.stringify(this.props.transaksi)
    ) {
      this.reset();
      if (
        this.props.transaksi != null &&
        this.props.transaksi[0].transaksi != null &&
        this.props.transaksi[0].transaksi.length > 0
      ) {
        var transaksiExist = this.props.transaksi[0].transaksi;
        var tempCart = [];

        transaksiExist.map((item, idx) => {
          if (item.keterangan != "") {
            tempCart.push({
              id: item.produk,
              nama: item.produk_nama,
              harga: parseFloat(item.harga),
              qty: item.quantity,
              idx: item.id,
            });
          }
        });
        this.setState({
          cartObat: tempCart,
        });
      }
    }
  }

  loadMasterList() {
    const rule = [
      {
        destination: {
          stateProgressName: "loadingObat",
          endPoint: configClass.apiUrl.produk + "?tipe=2",
          stateDestination: "listObat",
        },
        nextDestination: {
          stateProgressName: "loadingDosis",
          endPoint: configClass.apiUrl.dosis,
          stateDestination: "listDosis",
          next: null,
        },
      },
      {
        destination: {
          stateProgressName: "loadingRacikan",
          endPoint: configClass.apiUrl.racikan,
          stateDestination: "listRacikan",
        },
        nextDestination: null,
      },
      {
        destination: {
          stateProgressName: "loadingAturanMakan",
          endPoint: configClass.apiUrl.aturan_makan,
          stateDestination: "listAturanMakan",
        },
        nextDestination: {
          stateProgressName: "loadingWaktu",
          endPoint: configClass.apiUrl.waktu,
          stateDestination: "listWaktu",
          next: null,
        },
      },
      {
        destination: {
          stateProgressName: "loadingRacikan",
          endPoint: configClass.apiUrl.racikan,
          stateDestination: "listRacikan",
        },
        nextDestination: null,
      },
    ];

    this.setState({
      errComponent: false,
    });

    rule.map((item, index) => {
      if (index == rule.length - 1) {
        this.getMasterList(item, true);
      } else {
        this.getMasterList(item);
      }
    });
  }

  async getMasterList(rule, isFinal = false) {
    var destination = rule.destination;
    var nextDestination = rule.nextDestination;
    this.setState({ [destination.stateProgressName]: true });
    this.props
      .ajaxViewHandler("get", destination.endPoint)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.count != undefined) {
            let newState = null;
            if (this.state[destination.stateDestination].length == 0) {
              newState = data;
            } else {
              if (destination.stateProgressName.includes("_searching")) {
                newState = data;
              } else {
                const oldState = this.state[destination.stateDestination];
                newState = {
                  count: data.count,
                  next: data.next,
                  previous: data.previous,
                  results: [...oldState.results, ...data.results],
                };
              }
            }
            this.setState({
              [destination.stateDestination]: newState,
            });
          } else if (data.results != undefined) {
            this.setState({
              [destination.stateDestination]: data.results,
            });
          } else {
            if (Array.isArray(data)) {
              this.setState({
                [destination.stateDestination]: data,
              });
            } else {
              this.setState({
                [destination.stateDestination]: [data],
              });
            }
          }
        } else {
          this.setState({
            errComponent: true,
          });
        }
        this.setState({ [destination.stateProgressName]: false });
      })
      .catch((response) => {
        console.log("gagal_get_master", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({
          errTitle: "gagal request data master",
          errMsg: response,
        });
        this.setState({ [destination.stateProgressName]: false });
      });
    //next function
    if (nextDestination != null) {
      const next = {
        destination: {
          stateProgressName: nextDestination.stateProgressName,
          endPoint: nextDestination.endPoint,
          stateDestination: nextDestination.stateDestination,
        },
        nextDestination: nextDestination.next,
      };
      this.getMasterList(next);
    }
  }

  generateSelect(stateName, url, key, value) {
    return this.state["loading" + stateName] ? (
      <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
    ) : (
      <Select
        labelInValue
        showSearch
        placeholder={"Pilih "+stateName}
        optionFilterProp="children"
        onSearch={(val) => {
          const rule = {
            destination: {
              stateProgressName: "loading" + stateName + "_searching",
              endPoint: url + "?search=" + val,
              stateDestination: "list" + stateName,
            },
            nextDestination: null,
          };
          this.getMasterList(rule);
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (
              this.state["list" + stateName].next != undefined &&
              this.state["list" + stateName].next != null
            ) {
              const rule = {
                destination: {
                  stateProgressName: "loading" + stateName + "Paging",
                  endPoint: this.state["list" + stateName].next,
                  stateDestination: "list" + stateName,
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            }
          }
        }}
        allowClear
        size={"large"}
        onChange={(val) => {
          // console.log('obat_val', val.value)
          if (stateName == "Racikan") {
            console.log('racikane', val);
            this.formRefObat.current.setFieldsValue({
              keterangan: undefined,
              dosis: undefined,
              aturan_makan: undefined,
              waktu: undefined,
            });
            this.setState({
              racikan: val != undefined ? val.value : '',
              racikan_nama: val != undefined ? val.label : '',
              dosis: null,
              keterangan: null,
              dosis_nama: null,
              aturan_makan: null,
              waktu: null,
            });
          }
          // if(this.state.isValid == true && this.state.isValidWaktu == true && this.state.isValidAturanMakan == true){
          //   this.pilihObat(val);
          // }
        }}
      >
        {this.state["loading_" + stateName + "_searching"] && (
          <Select.Option value="">
            <Spin size="small" />
          </Select.Option>
        )}
        {this.state["list" + stateName].count != undefined
          ? this.state["list" + stateName].results.map((item) => (
              <Select.Option value={item[key]}>
                {item[value] + ""}
              </Select.Option>
            ))
          : this.state["list" + stateName].map((item) => (
              <Select.Option value={item[key]}>
                {item[value] + ""}
              </Select.Option>
            ))}
        {this.state["list" + stateName] != undefined &&
          this.state["list" + stateName].next != undefined && (
            <Select.Option value="">
              <Spin size="small" />
            </Select.Option>
          )}
      </Select>
    );
  }

  generateSelectProdukObat(stateName, url, key, value) {
    return this.state["loading" + stateName] ? (
      <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
    ) : (
      <Select
        autoFocus
        showSearch
        placeholder={"cari / pilih " + stateName.toLowerCase()}
        optionFilterProp="children"
        onSearch={(val) => {
          const rule = {
            destination: {
              stateProgressName: "loading" + stateName + "_searching",
              endPoint: url + "&search=" + val,
              stateDestination: "list" + stateName,
            },
            nextDestination: null,
          };
          this.getMasterList(rule);
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (
              this.state["list" + stateName].next != undefined &&
              this.state["list" + stateName].next != null
            ) {
              const rule = {
                destination: {
                  stateProgressName: "loading" + stateName + "Paging",
                  endPoint: this.state["list" + stateName].next,
                  stateDestination: "list" + stateName,
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            }
          }
        }}
        allowClear
        style={{
          width: "100%",
        }}
        size={"large"}
        onChange={(val) => {
          if (stateName == "Obat" && val != '' && val != undefined && val != null) {
            this.setState({
              isValid: true,
              tempIdProduk: val
            });
            // if(this.state.isValid){
            //   this.setState({
            //     tempIdProduk: val
            //   }, () =>{
            //     this.pilihObat(val);
            //   });
            // }else{
            //   this.setState({
            //     tempIdProduk: val
            //   });
            // }
          }
        }}
      >
        {this.state["loading" + stateName + "_searching"] == true && (
          <Select.Option value="">
            <Spin size="small" />
          </Select.Option>
        )}
        {this.state["list" + stateName].count != undefined
          ? this.state["list" + stateName].results.map((item) => (
              <Select.Option value={item[key] + ""}>
                {item[value]}
              </Select.Option>
            ))
          : this.state["list" + stateName].map((item) => (
              <Select.Option value={item[key] + ""}>
                {item[value]}
              </Select.Option>
            ))}
        {this.state["list" + stateName] != undefined &&
          this.state["list" + stateName].next != undefined && (
            <Select.Option value="">
              <Spin size="small" />
            </Select.Option>
          )}
      </Select>
    );
  }

  async getDosis(idDosis) {
    // this.setState({loadingBarcode: true});
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.dosis + "/" + idDosis)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data != undefined && data != null) {
            this.setState({
              dosis_nama: data.nama,
            });
          }
        }
      })
      .catch((response) => {
        console.log("gagal_dosis", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  async scanObat(barcode) {
    // this.setState({loadingBarcode: true});
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.produk + "?barcode=" + barcode)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.results.length > 0) {
            this.setState(
              {
                barcodeObatIsNotFound: false,
                barcodeObatResults: data.results,
                loadingBarcodeObat: false,
              },
              () => {
                let temp_cart = this.state.cartObat;
                // let temp_label_produk = this.state.label_produk;
                let that = this;
                if (temp_cart.length == 0) {
                  let produk = this.state.barcodeObatResults[0];
                  // let harga = 0;
                  // let diskon_rupiah = 0;
                  // if(produk['diskon']!="" && produk['diskon'].rupiah>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty){
                  // 	console.log('tes_produk', produk);
                  // 	// console.log('tes_diskon_rupiah', produk['diskon'].rupiah)
                  // 	// diskon_rupiah = produk['diskon'].rupiah;
                  // }

                  temp_cart.push({
                    id: produk["id"],
                    nama: produk["nama"],
                    harga: produk["harga"],
                    dosis: this.state.dosis,
                    keterangan: this.state.keterangan,
                    qty: 1,
                  });
                  // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US');
                } else {
                  const index_cari = temp_cart.findIndex(
                    (data) => data.id == this.state.barcodeObatResults[0].id
                  );
                  if (index_cari == -1) {
                    let produk = this.state.barcodeObatResults[0];
                    // let harga = 0;

                    temp_cart.push({
                      id: produk["id"],
                      nama: produk["nama"],
                      harga: produk["harga"],
                      dosis: this.state.dosis,
                      keterangan: this.state.keterangan,
                      qty: 1,
                    });
                    // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US');
                  } else {
                    let produk = this.state.barcodeObatResults[0];
                    // let harga = 0;

                    // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US')+' x '+(temp_cart[index_cari]['qty']+1);
                    temp_cart[index_cari]["qty"] =
                      temp_cart[index_cari]["qty"] + 1;
                  }
                }
                this.setState(
                  {
                    cartObat: temp_cart,
                    // label_produk: temp_label_produk
                  },
                  () => {
                    // console.log('cart', this.state.cart)
                  }
                );
                var temp_total = 0;
                this.state.cartObat.map((item) => {
                  temp_total += item["harga"] * item["qty"];
                });
                this.setState({
                  totalObat: temp_total,
                  total_finalObat: temp_total,
                  barcodeTextObat: "",
                });
              }
            );
            this.resetToAdd();
          } else {
            this.setState({
              barcodeObatIsNotFound: true,
              barcodeObatResults: null,
              loadingBarcodeObat: false,
              barcodeTextObat: "",
            });
            message.error({
              content: "produk tidak ditemukan",
              duration: 2,
              key: "toast",
            });
            // this.resetToAdd();
          }
          // this.barcodeRef.current.val = undefined;
        } else {
          const errTitle = "error get data";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
            loadingBarcodeObat: false,
            barcodeObatIsNotFound: false,
            barcodeTextObat: "",
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.resetToAdd();
        }
        this.setState({ loadingProduk: false });
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({ loadingBarcode: false });
        this.resetToAdd();
      });
  }

  async pilihObat(id) {
    // this.setState({loadingBarcode: true});
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);
    this.setState({
      loadingAdd: true,
    });

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.produk + "/" + id)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data != undefined) {
            this.setState(
              {
                barcodeObatIsNotFound: false,
                barcodeObatResults: data,
                loadingBarcodeObat: false,
              },
              () => {
                let temp_cart = this.state.cartObat;
                // let temp_label_produk = this.state.label_produk;
                let that = this;
                if (temp_cart.length == 0) {
                  let produk = this.state.barcodeObatResults;

                  temp_cart.push({
                    id: produk["id"],
                    nama: produk["nama"],
                    harga: produk["harga"],
                    qty: 1,
                    dosis: this.state.dosis,
                    keterangan: this.state.keterangan,
                    dosis_nama: this.state.dosis_nama,
                    racikan:
                      this.state.racikan == undefined
                        ? null
                        : this.state.racikan,
                    racikan_nama: this.state.racikan_nama,
                    aturan_makan: this.state.aturan_makan,
                    waktu: this.state.waktu    
                  });
                } else {
                  const index_cari = temp_cart.findIndex(
                    (data) => data.id == this.state.barcodeObatResults.id
                  );
                  if (this.state.racikan == null) {
                    //non racik
                    if (index_cari == -1) {
                      let produk = this.state.barcodeObatResults;
                      temp_cart.push({
                        id: produk["id"],
                        nama: produk["nama"],
                        harga: produk["harga"],
                        qty: 1,
                        dosis: this.state.dosis,
                        keterangan: this.state.keterangan,
                        dosis_nama: this.state.dosis_nama,
                        racikan:
                          this.state.racikan == undefined
                            ? null
                            : this.state.racikan,
                        racikan_nama: this.state.racikan_nama,    
                        aturan_makan: this.state.aturan_makan,
                        waktu: this.state.waktu    
                      });
                    } else {
                      let produk = this.state.barcodeObatResults;
                      temp_cart[index_cari]["qty"] =
                        temp_cart[index_cari]["qty"] + 1;
                    }
                  } else {
                    //racikan
                    let produk = this.state.barcodeObatResults;
                    temp_cart.push({
                      id: produk["id"],
                      nama: produk["nama"],
                      harga: produk["harga"],
                      qty: 1,
                      dosis: this.state.dosis,
                      keterangan: this.state.keterangan,
                      dosis_nama: this.state.dosis_nama,
                      racikan:
                        this.state.racikan == undefined
                          ? null
                          : this.state.racikan,
                      racikan_nama: this.state.racikan_nama,    
                      aturan_makan: this.state.aturan_makan,
                      waktu: this.state.waktu    
                    });
                  }
                }
                this.setState(
                  {
                    cartObat: temp_cart,
                  },
                  () => {
                    // console.log('cart', this.state.cart)
                  }
                );
                var temp_total = 0;
                this.state.cartObat.map((item) => {
                  temp_total += item["harga"] * item["qty"];
                });
                this.setState(
                  {
                    totalObat: temp_total,
                    total_finalObat: temp_total,
                    barcodeTextObat: "",
                  },
                  () => {
                    this.formRefObat.current.setFieldsValue({
                      produks: undefined,
                    });
                  }
                );
              }
            );
          } else {
            this.setState({
              barcodeObatIsNotFound: true,
              barcodeObatResults: null,
              loadingBarcodeObat: false,
              barcodeTextObat: "",
            });
          }
          // this.barcodeRef.current.val = undefined;
          this.setState({
            loadingAdd: false,
          });
          this.resetToAdd();
        } else {
          const errTitle = "error get data";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
            loadingBarcodeObat: false,
            barcodeObatIsNotFound: false,
            barcodeTextObat: "",
            loadingAdd: false,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
        this.setState({ loadingProduk: false });
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({ loadingBarcode: false });
      });
  }

  removeObat(id, nama, isExisting) {
    Modal.confirm({
      title: "Konfirmasi Hapus?",
      icon: <ExclamationCircleOutlined />,
      content: "yakin menghapus item : " + nama + "?",
      okText: "Ya",
      okType: "danger",
      cancelText: "Tidak",
      onOk: () => this.prosesHapusItem(id, isExisting),
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  prosesHapusItem(id, isExisting) {
    const temp_cart = this.state.cartObat;
    var index_cari;
    if (isExisting) {
      index_cari = temp_cart.findIndex((data) => data.idx == id);
      this.hapusExisting(id);
    } else {
      index_cari = temp_cart.findIndex((data) => data.id == id);

      temp_cart.splice(index_cari, 1);
      this.setState({
        cartObat: temp_cart,
      });
      var temp_total = 0;
      this.state.cartObat.map((item) => {
        // temp_total += (item['harga']*item['qty']);
        temp_total += parseFloat(item["harga"]) * parseFloat(item["qty"]);
      });
      this.setState({
        totalObat: temp_total,
        total_finalObat: temp_total,
      });
    }
  }

  setQtyObat(e, id) {
    if(e.target.value != undefined && e.target.value != null && e.target.value != ''){
      const temp_cart = this.state.cartObat;
      const index_cari = temp_cart.findIndex((data) => data.id == id);
      if (e.target.value == "0") {
        temp_cart.splice(index_cari, 1);
      } else {
        temp_cart[index_cari]["qty"] = parseFloat(e.target.value);
      }
      this.setState({
        cartObat: temp_cart,
      });
      var temp_total = 0;
      this.state.cartObat.map((item) => {
        // temp_total += (item['harga']*item['qty']);
        temp_total += parseFloat(item["harga"]) * parseFloat(item["qty"]);
      });
      this.setState({
        totalObat: temp_total,
        total_finalObat: temp_total,
      });
    }else{
      const temp_cart = this.state.cartObat;
      const index_cari = temp_cart.findIndex((data) => data.id == id);
      
      temp_cart[index_cari]["qty"] = "";
      this.setState({
        cartObat: temp_cart,
      });
      var temp_total = 0;
      this.state.cartObat.map((item) => {
        // temp_total += (item['harga']*item['qty']);
        if(item["qty"] != ""){
          temp_total += parseFloat(item["harga"]) * parseFloat(item["qty"]);
        }
      });
      this.setState({
        totalObat: temp_total,
        total_finalObat: temp_total,
      });
    }
  }

  handleSubmitObat = (values) => {
    var datas = values;
    var detail = [];
    this.state.cartObat.map((item) => {
      detail = [
        ...detail,
        {
          produk: item.id,
          quantity: item.qty,
          dosis: item.dosis == undefined || item.dosis == '' || item.dosis == null ? null : item.dosis,
          keterangan: item.keterangan == undefined || item.keterangan == '' || item.keterangan == null ? null : item.keterangan,
          transaksi: this.props.transaksi[0].id,
          racikan: item.racikan == undefined || item.racikan == '' || item.racikan == null ? null : item.racikan,
          aturan_makan: item.aturan_makan == undefined || item.aturan_makan == '' || item.aturan_makan == null ? null : item.aturan_makan,
          waktu: item.waktu == undefined || item.waktu == '' || item.waktu == null ? null : item.waktu,
        },
      ];
    });

    // datas = {detail};

    // console.log('detail', detail);
    this.postDataObat({ detail: detail });
  };

  async hapusExisting(id) {
    this.setState({
      errTitle: "",
      errMsg: "",
    });

    message.loading({ content: "Memproses...", duration: 0, key: "toast" });

    var that = this;

    this.props
      .ajaxHandler(
        "del",
        configClass.apiUrl.detail_transaksi + id,
        null,
        false,
        true
      )
      .then(() => {
        if (this.props.isSuccess) {
          message.success({
            content: "berhasil menghapus item",
            duration: 2,
            key: "toast",
          });

          const temp_cart = this.state.cartObat;
          const index_cari = temp_cart.findIndex((data) => data.id == id);

          temp_cart.splice(index_cari, 1);
          this.setState({
            cartObat: temp_cart,
          });
          var temp_total = 0;
          this.state.cartObat.map((item) => {
            // temp_total += (item['harga']*item['qty']);
            temp_total += parseFloat(item["harga"]) * parseFloat(item["qty"]);
          });
          this.setState({
            totalObat: temp_total,
            total_finalObat: temp_total,
          });
        } else {
          const errTitle = "gagal menghapus item";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_del", response);
        this.setState({
          loadingSimpanLayanan: false,
        });
        message.error({
          content: "kesalahan pengiriman data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  async postDataObat(datas) {
    this.setState({
      errTitle: "",
      errMsg: "",
      loadingSimpanObat: true,
    });

    message.loading({ content: "Memproses...", duration: 0, key: "toast" });

    var that = this;

    this.props
      .ajaxHandler(
        this.state.method,
        configClass.apiUrl.detail_transaksi,
        datas,
        false,
        true
      )
      .then(() => {
        this.setState({
          loadingSimpanObat: false,
        });
        if (this.props.isSuccess) {
          message.success({
            content: "berhasil menyimpan data",
            duration: 2,
            key: "toast",
          });
          this.setState({
            isFinish: true,
          });
          var response = this.props.responseMsg;
          var that = this;
          Modal.confirm({
            title: "Berhasil Menyimpan",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            content:
              "tekan cetak untuk mencetak, tekan selesai untuk melanjutkan",
            okText: (
              <a
                href={
                  configClass.pageUrl.cetak_resep + this.props.transaksi[0].id
                }
                target="_blank"
              >
                <PrinterOutlined /> Cetak
              </a>
            ),
            cancelText: "Selesai",
            onOk() {
              // that.reset()
            },
            onCancel() {
              // that.reset()
            },
          });
        } else {
          const errTitle = "gagal menyimpan data";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post", response);
        this.setState({
          loadingSimpanObat: false,
        });
        message.error({
          content: "kesalahan pengiriman data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  resetToAdd() {
    this.formRefObat.current.setFieldsValue({
      barcode: undefined,
      produks: undefined,
    });
    this.setState({
      tempKodeBarcode: null,
      tempIdProduk: null,
    });
  }

  reset() {
    this.setState({
      cartObat: [],
      barcodeObatIsNotFound: false,
      barcodeObatResults: null,
      totalObat: 0,
      total_finalObat: 0,
      barcodeTextObat: undefined,
    });
  }

  render() {
    return (
      <div>
        <Form
          layout="vertical"
          name="fproduk"
          onFinish={this.handleSubmitObat}
          size="small"
          ref={this.formRefObat}
          initialValues={{
            jenis_layanan: this.state.jenis_layanan,
          }}
        >
          <Row>
            <Col
              span={12}
              style={{
                fontSize: 20,
                fontWeight: "bold",
                fontStyle: "italic",
                textAlign: "left",
                marginBottom: 10,
                color: "blue",
              }}
            >
              {this.state.barcodeObatResults != null
                ? this.state.jenis_layanan == "barcode" &&
                  this.state.barcodeObatResults != null &&
                  this.state.barcodeObatResults.length > 0
                  ? this.state.barcodeObatResults[0].nama
                  : this.state.barcodeObatResults.nama
                : ""}
            </Col>
            <Col
              span={12}
              style={{
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "right",
                marginBottom: 10,
              }}
            >
              {"Total Rp. " +
                parseFloat(this.state.total_finalObat).toLocaleString("en-us")}
            </Col>
            <Col span={24}>
              <Input.Group compact>
                <Form.Item name="jenis_layanan" style={{ width: "15%" }}>
                  <Select
                    showSearch
                    placeholder="pilih jenis"
                    optionFilterProp="children"
                    style={{
                      width: "100%",
                    }}
                    onChange={(val) => {
                      this.setState(
                        {
                          jenis_layanan: val,
                        },
                        () => {
                          this.resetToAdd();
                          // this.formRef.pasien.current.focus({cursor: 'start'});
                          // this.formRefObat.current.setFieldsValue({
                          // 	produk: undefined
                          // });
                        }
                      );
                    }}
                    size={"large"}
                  >
                    <Select.Option value={"barcode"}>
                      <BarcodeOutlined /> barcode
                    </Select.Option>
                    <Select.Option value={"produk"}>
                      <UserOutlined /> produk
                    </Select.Option>
                  </Select>
                </Form.Item>
                {this.state.jenis_layanan == "barcode" && (
                  <Form.Item name="barcode" style={{ width: "70%" }}>
                    <Input
                      onChange={(e) => {
                        this.setState({
                          tempKodeBarcode: e.target.value,
                        });
                      }}
                      autoFocus
                      size="large"
                      placeholder="scan barcode disini"
                      className="penjualan-cart-barcode"
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          var val = event.target.value;
                          
                            this.setState(
                              {
                                isValid: true,
                              },
                              () => {
                                this.scanObat(val);
                              }
                            );
                          
                        }
                      }}
                      style={{ textTransform: "none" }}
                    />
                  </Form.Item>
                )}
                {this.state.jenis_layanan == "produk" && (
                  <Form.Item name="produks" style={{ width: "70%" }}>
                    {this.generateSelectProdukObat(
                      "Obat",
                      configClass.apiUrl.produk + "?tipe=2",
                      "id",
                      "nama"
                    )}
                  </Form.Item>
                )}
                <Form.Item name="simpan" style={{ width: "10%" }}>
	                <Button
	                  style={{ width: "100%" }}
	                  key="btn-simpan-layanan"
	                  loading={this.state.loadingSimpanObat}
	                  type="primary"
	                  htmlType="submit"
	                  disabled={this.state.cartObat.length == 0 ? true : false}
	                  size="large"
	                >
	                  {!this.state.loadingSimpanObat && <SaveOutlined />}
	                  Simpan
	                </Button>
                </Form.Item>
                <Form.Item name="simpan" style={{ width: "5%" }}>
                  <a href={configClass.pageUrl.cetak_resep + this.props.transaksi[0].id} target="_blank">
  	                <Button
  	                  style={{ width: "100%" }}
  	                  key="btn-simpan-layanan"
  	                  loading={this.state.loadingSimpanObat}
  	                  type="default"
  	                  htmlType="button"
  	                  disabled={this.state.cartObat.length == 0 ? true : false}
  	                  size="large"
  	                >
  	                  {!this.state.loadingSimpanObat && <PrinterOutlined />}
  	                </Button>
                  </a>
	              </Form.Item>
              </Input.Group>
            </Col>
            <Col span={12} style={{maxHeight: 60}}>
              <Form.Item name="racikan">
                {this.generateSelect(
                  "Racikan",
                  configClass.apiUrl.racikan,
                  "id",
                  "nama"
                )}
              </Form.Item>
            </Col>
            <Col span={12} style={{maxHeight: 60}}>
              <Form.Item
                name="dosis"
              >
                {this.generateSelect(
                  "Dosis",
                  configClass.apiUrl.dosis,
                  "id",
                  "nama"
                )}
              </Form.Item>
            </Col>
            <Col span={12} style={{maxHeight: 60}}>
              <Form.Item 
                name="aturan_makan"
              >
                {this.generateSelect(
                  "AturanMakan",
                  configClass.apiUrl.aturan_makan,
                  "id",
                  "nama"
                )}
              </Form.Item>
            </Col>
            <Col span={12} style={{maxHeight: 60}}>
              <Form.Item 
                name="waktu"
              >
                {this.generateSelect(
                  "Waktu",
                  configClass.apiUrl.waktu,
                  "id",
                  "nama"
                )}
              </Form.Item>
            </Col>
            <Col span={22} style={{maxHeight: 60}}>
              <Form.Item name="keterangan">
                <Input
                  style={{ textTransform: "lowercase" }}
                  placeholder="input keterangan"
                  size="large"
                  onChange={(e) => {
                    this.setState({
                      keterangan: e.target.value.toLowerCase(),
                      // isValid: e.target.value != null && e.target.value != '' ? true : (this.state.dosis!=null && this.state.dosis!='' ? true : false)
                    });
                  }}
                  onKeyPress={(event) => {
                    // if (event.key === "Enter") {
                    //   event.preventDefault();
                    //   var val = event.target.value;
                    //   if (
                    //     this.state.jenis_layanan == "barcode" &&
                    //     this.state.tempKodeBarcode != null
                    //   ) {
                    //     this.scanObat(this.state.tempKodeBarcode);
                    //   } else if (
                    //     this.state.jenis_layanan == "produk" &&
                    //     this.state.tempIdProduk != null
                    //   ) {
                    //     this.pilihObat(this.state.tempIdProduk);
                    //   }
                    // }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={2} style={{ textAlign: "right" }}>
              <Button
                loading={this.state.loadingAdd}
                disabled={this.state.tempIdProduk != undefined && this.state.tempIdProduk != null && this.state.tempIdProduk != "" && this.state.isValid == true ? false : true}
                size="large"
                className="btn-primary"
                type="text"
                onClick={(e) => this.pilihObat(this.state.tempIdProduk)}
              >
                + item
              </Button>
            </Col>
          </Row>
        </Form>
        <PerfectScrollbar className="panel-cart">
          <Row
            style={{
              borderBottom: "1px solid #cccccc",
              padding: 10,
              fontWeight: "bold",
            }}
          >
            <Col span={3}>DEL</Col>
            <Col span={4}>QTY</Col>
            <Col span={5}>NAMA OBAT</Col>
            <Col span={4}>RACIKAN</Col>
            <Col span={4}>@HARGA</Col>
            <Col span={4}>SUB TOTAL</Col>
          </Row>
          {this.state.cartObat.length == 0 ? (
            <Result
              icon={<WarningOutlined style={{ color: "#f9af4d" }} />}
              title={
                <span style={{ color: "#f9af4d" }}>
                  belum ada obat dipilih!
                </span>
              }
            />
          ) : (
            this.state.cartObat.map((item, idx) => (
              <Row style={{ borderBottom: "1px solid #cccccc", padding: 10 }}>
                <Col span={3}>
                  <Button
                    // disabled={this.state.isFinish == true ? true : false}
                    size="large"
                    className="btn-delete"
                    type="text"
                    onClick={(e) =>
                      item.idx != undefined
                        ? this.removeObat(item.idx, item.nama, true)
                        : this.removeObat(item.id, item.nama, false)
                    }
                  >
                    <DeleteOutlined />
                  </Button>
                </Col>
                <Col span={4}>
                  <Input
                    size="large"
                    value={item.qty}
                    style={{
                      width: 40,
                      display: "inline-block",
                    }}
                    onChange={(e) => this.setQtyObat(e, item.id)}
                    // disabled={this.state.isFinish == true ? true : false}
                  />
                </Col>
                <Col span={5}>
                  {item.nama}
                  <br />
                  <i style={{ color: "blue", fontSize: 12 }}>
                    {item.keterangan != null && item.keterangan != ""
                      ? item.dosis != null
                        ? item.dosis_nama + " " + item.keterangan
                        : item.keterangan
                      : item.dosis_nama}
                  </i>
                </Col>
                <Col span={4}>
                  {(item.racikan != null && item.racikan_nama)}
                </Col>
                <Col span={4}>
                  {"Rp. " + parseFloat(item.harga).toLocaleString("en-us")}
                </Col>
                <Col span={4}>
                  {"Rp. " +
                    parseFloat(item.harga * item.qty).toLocaleString("en-us")}
                </Col>
              </Row>
            ))
          )}
        </PerfectScrollbar>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  initHandler,
  setConfig,
  ajaxViewHandler,
  ajaxHandler,
})(Obat);
