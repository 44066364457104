import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
	Layout,
	Menu,
	Drawer,
	Result,
	Button,
	message,
	notification
} from 'antd';
import { enquireScreen, unenquireScreen } from 'enquire-js'
import 'antd/dist/antd.css';
import '../assets/style.css';

import 'react-perfect-scrollbar/dist/css/styles.css';

import { connect } from 'react-redux';
import { 
	setConfig, 
	ajaxViewHandler 
} from "../store/actions";

import axios from 'axios';
import Config from '../Config';

import Cookies from 'js-cookie';
import Sider from '../components/AdminSider';
import Header from '../components/AdminHeader';
import Footer from '../components/AdminFooter';

import Home from '../views/Home';
import Forbidden from '../views/Forbidden';

import DataGudang from '../views/gudang/DataGudang';
import FormGudang from '../views/gudang/FormGudang';

import DataCabang from '../views/cabang/DataCabang';
import FormCabang from '../views/cabang/FormCabang';

import DataKategoriOutlet from '../views/kategori_outlet/DataKategoriOutlet';
import FormKategoriOutlet from '../views/kategori_outlet/FormKategoriOutlet';

import DataKategoriProduk from '../views/kategori_produk/DataKategoriProduk';
import FormKategoriProduk from '../views/kategori_produk/FormKategoriProduk';

import DataOutlet from '../views/outlet/DataOutlet';
import FormOutlet from '../views/outlet/FormOutlet';

import DataProduk from '../views/produk/DataProduk';
import FormProduk from '../views/produk/FormProduk';

import DataJabatan from '../views/jabatan/DataJabatan';
import FormJabatan from '../views/jabatan/FormJabatan';

import DataPasien from '../views/pasien/DataPasien';
import FormPasien from '../views/pasien/FormPasien';

import DataKaryawan from '../views/karyawan/DataKaryawan';
import FormKaryawan from '../views/karyawan/FormKaryawan';

import DataJenisPajak from '../views/jenis_pajak/DataJenisPajak';
import FormJenisPajak from '../views/jenis_pajak/FormJenisPajak';

import DataPerusahaan from '../views/perusahaan/DataPerusahaan';
import FormPerusahaan from '../views/perusahaan/FormPerusahaan';

import DataSuplier from '../views/suplier/DataSuplier';
import FormSuplier from '../views/suplier/FormSuplier';

import DataHargaProduk from '../views/harga_produk/DataHargaProduk';
import FormHargaProduk from '../views/harga_produk/FormHargaProduk';

import DataDiskon from '../views/diskon/DataDiskon';
import FormDiskon from '../views/diskon/FormDiskon';

import DataPengaturanPajak from '../views/pajak/DataPengaturanPajak';
import FormPengaturanPajak from '../views/pajak/FormPengaturanPajak';

import FormRegistrasi from '../views/registrasi/FormRegistrasi';
import FormLayanan from '../views/layanan/FormLayanan';
import FormPembayaran from '../views/pembayaran/FormPembayaran';

import FormPenjualan from '../views/penjualan/FormPenjualan';
import FormPenjualanWholesale from '../views/penjualan/FormPenjualanWholesale';
import PembayaranPenjualan from '../views/penjualan/PembayaranPenjualan';
import VerifikasiPenjualan from '../views/penjualan/VerifikasiPenjualan';

import FormPembelian from '../views/pembelian/FormPembelian';
import DataPembelian from '../views/pembelian/DataPembelian';
import DataStock from '../views/pembelian/DataStock';

import PembayaranPembelian from '../views/pembelian/PembayaranPembelian';
import ReturPembelian from '../views/pembelian/ReturPembelian';
import FormReturPembelian from '../views/pembelian/FormReturPembelian';

import FormTitipan from '../views/barang_titipan/FormTitipan';
import PembayaranTitipan from '../views/barang_titipan/PembayaranTitipan';

import DataCustomer from '../views/customer/DataCustomer';
import FormCustomer from '../views/customer/FormCustomer';

import DataJenisPembayaran from '../views/jenis_pembayaran/DataJenisPembayaran';
import FormJenisPembayaran from '../views/jenis_pembayaran/FormJenisPembayaran';

import DataDiagnosa from '../views/diagnosa/DataDiagnosa';
import FormDiagnosa from '../views/diagnosa/FormDiagnosa';

import DataRacikan from '../views/racikan/DataRacikan';
import FormRacikan from '../views/racikan/FormRacikan';

import DataDosis from '../views/dosis/DataDosis';
import FormDosis from '../views/dosis/FormDosis';

import DataAturanMakan from '../views/aturan_makan/DataAturanMakan';
import FormAturanMakan from '../views/aturan_makan/FormAturanMakan';

import DataWaktu from '../views/waktu/DataWaktu';
import FormWaktu from '../views/waktu/FormWaktu';

import LaporanTransaksi from '../views/laporan/Transaksi';

// import { askForPermissioToReceiveNotifications, logoutFirebase } from '../push-notification';

const { Content } = Layout;

const configClass = {
  apiUrl: {
    userProfile: "auth/api/user/info"
  }
}

class AdminLayout extends React.Component {
	config;

	constructor(props){
		super(props);
		this.config = new Config();
	}

	handleCloseDrawer = () => {
		this.props.setConfig({collapsed: true});
		// console.log('drawer_close', this.props.collapsed);
	}

	componentDidMount() {
		// if (!("Notification" in window)) {
		//     alert("browser tidak support notifikasi, notifikasi chat tidak akan aktif di browser ini");
		// }else if(Notification.permission !== "denied"){
		// 	Notification.requestPermission().then(function (permission) {
		//       // If the user accepts, let's create a notification
		//       if (permission === "granted") {
		//         //var notification = new Notification("notifikasi berhasil diaktifkan!");
		//       }
		//     });
		// }
		// this.connect();

	    this.enquireHandler = enquireScreen(mobile => {
	      const { isMobile } = this.props
	      if (isMobile !== mobile) {
	        this.props.setConfig({
	        	isMobile: mobile
	        });
	        if(mobile){
	        	this.props.setConfig({
	        		collapsed: false
	        	});
	        }
	      }
	    });

	    this.getProfile();
	}

	componentWillUnmount() {
	  	unenquireScreen(this.enquireHandler)
	}

	// connect = () => {
	//         var ws = new WebSocket(this.config.get_web_socket()+"?token="+Cookies.get(this.config.get_cookie_name()).replace("Bearer ", ""));
	//         let that = this; // cache the this
	//         var connectInterval;

	//         // websocket onopen event listener
	//         ws.onopen = () => {
	//             console.log("web socket connected with token "+Cookies.get(this.config.get_cookie_name()).replace("Bearer ", ""));

	//             this.props.setConfig({
	//               ws: ws
	//             });

	//             that.timeout = 250; // reset timer to 250 on open of websocket connection 
	//             clearTimeout(connectInterval); // clear Interval on on open of websocket connection
	//         };

	//         ws.onmessage = evt => {
	//         	const pesan = JSON.parse(evt.data);
	// 		    //this.setState({dataFromServer: pesan});
	// 		    //console.log(pesan);
	// 		    if(pesan.status != null && pesan.status == 'online'){
	// 		    	//notif konek ke websocket
	// 		    }else{
	// 		    	// console.log('pesan_masuk', pesan);
			    	
	// 		    	if(pesan.tipe == 'Pembelian / Pemesanan Obat'){
	// 		    		notification['info']({
	// 					    message: pesan.tipe+' Baru',
	// 					    description: 'ada transaksi '+pesan.tipe+' Baru, silahkan konfirmasi segera.',
	// 					    placement: 'topRight'
	// 					});
	// 		    		this.props.setConfig({
	// 		    			latestId_PembelianObat: pesan.transaksi
	// 		    		});
	// 		    	}else if(pesan.tipe == 'Periksa Laboratorium'){
	// 		    		notification['info']({
	// 					    message: pesan.tipe+' Baru',
	// 					    description: 'ada transaksi '+pesan.tipe+' Baru, silahkan konfirmasi segera.',
	// 					    placement: 'topRight'
	// 					});
	// 		    		this.props.setConfig({
	// 		    			latestId_PeriksaLab: pesan.transaksi
	// 		    		});
	// 		    	}else if(pesan.tipe == 'Home Care'){
	// 		    		notification['info']({
	// 					    message: pesan.tipe+' Baru',
	// 					    description: 'ada transaksi '+pesan.tipe+' Baru, silahkan konfirmasi segera.',
	// 					    placement: 'topRight'
	// 					});
	// 		    		this.props.setConfig({
	// 		    			latestId_HomeCare: pesan.transaksi
	// 		    		});
	// 		    	}else if(pesan.tipe == 'Ambulan / Mobil Janazah'){
	// 		    		notification['info']({
	// 					    message: pesan.tipe+' Baru',
	// 					    description: 'ada transaksi '+pesan.tipe+' Baru, silahkan konfirmasi segera.',
	// 					    placement: 'topRight'
	// 					});
	// 		    		this.props.setConfig({
	// 		    			latestId_Ambulans: pesan.transaksi
	// 		    		});
	// 		    	}else if(pesan.tipe == 'Emergency Call'){
	// 		    		notification['info']({
	// 					    message: pesan.tipe+' Baru',
	// 					    description: 'ada transaksi '+pesan.tipe+' Baru, silahkan konfirmasi segera.',
	// 					    placement: 'topRight'
	// 					});
	// 		    		this.props.setConfig({
	// 		    			latestId_Emergency: pesan.transaksi
	// 		    		});
	// 		    	}


	// 			}

	//         };

	//         // websocket onclose event listener
	//         ws.onclose = e => {
	//             console.log(
	//                 `Socket is closed. Reconnect will be attempted in ${Math.min(
	//                     10000 / 1000,
	//                     (that.timeout + that.timeout) / 1000
	//                 )} second.`,
	//                 e.reason
	//             );

	//             that.timeout = that.timeout + that.timeout; //increment retry interval
	//             connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
	//         };

	//         // websocket onerror event listener
	//         ws.onerror = err => {
	//             console.error(
	//                 "Socket encountered error: ",
	//                 err.message,
	//                 "Closing socket"
	//             );

	//             ws.close();
	//         };
	// };

	// /**
	//   * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
	//   */
	// check = () => {
	//       const { ws } = this.props;
	//       if (!ws || ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
	// };

	async getProfile(){
		this.props.ajaxViewHandler('get', configClass.apiUrl.userProfile)
		.then(() =>{
			const data = this.props.responseMsg;
			// console.log('data_profile', data);
			if(data != undefined){
				this.props.setConfig({
		        	username: data.username,
		        	id: data.profile[0].id,
		        	pp: this.config.get_site_info().logo,
		        	nama: data.profile[0].nama,
		        	isSuperadmin: true,
		        	isDokter: data.is_dokter,
		        });

		        // askForPermissioToReceiveNotifications(data[0].tempatTugas);

		        // if(!this.props.switchable){
		        // 	// this.props.setConfig({
		        // 	// 	isSuperadmin: data[0].is_superuser
		        // 	// });
		        // }
			}
		}).catch((response) => {
			console.log('gagal_getprofile', response);
			message.error({ content: 'gagal request data profile, coba lagi', key: 'toast' });
		});
	}

	render() {
		if(!this.props.isLogin){
			return <Redirect to="/auth" />
		}
		return(
			<Layout>
				{this.props.isMobile ? (
		            <Drawer
		              closable={false}
		              onClose={this.handleCloseDrawer}
		              //visible={!this.props.collapsed}
		              visible={!this.props.collapsed}
		              placement="top"
		              width={200}

		            >
		              <Sider />
		            </Drawer>
		        ): (
				<Sider />
				)}
		        <div className="container" style={{maxWidth: '4000px'}}>
		          <Header />
		          <Content
		            className="content"

		          >
		          	<Switch>
		          		<Route path="/admin" component={Home} exact />
		          		<Route path="/admin/forbidden" component={Forbidden} exact />
		          		<Route path="/admin/index" component={Home} />

		          		<Route path="/admin/gudang" exact component={DataGudang} />
		          		<Route path="/admin/gudang/form/:id?" component={FormGudang} />

		          		<Route path="/admin/cabang" exact component={DataCabang} />
		          		<Route path="/admin/cabang/form/:id?" component={FormCabang} />

		          		<Route path="/admin/kategori_outlet" exact component={DataKategoriOutlet} />
		          		<Route path="/admin/kategori_outlet/form/:id?" component={FormKategoriOutlet} />

		          		<Route path="/admin/kategori_produk" exact component={DataKategoriProduk} />
		          		<Route path="/admin/kategori_produk/form/:id?" component={FormKategoriProduk} />

		          		<Route path="/admin/diagnosa" exact component={DataDiagnosa} />
		          		<Route path="/admin/diagnosa/form/:id?" component={FormDiagnosa} />

		          		<Route path="/admin/racikan" exact component={DataRacikan} />
		          		<Route path="/admin/racikan/form/:id?" component={FormRacikan} />

		          		<Route path="/admin/dosis" exact component={DataDosis} />
		          		<Route path="/admin/dosis/form/:id?" component={FormDosis} />

		          		<Route path="/admin/aturan_makan" exact component={DataAturanMakan} />
		          		<Route path="/admin/aturan_makan/form/:id?" component={FormAturanMakan} />

		          		<Route path="/admin/waktu" exact component={DataWaktu} />
		          		<Route path="/admin/waktu/form/:id?" component={FormWaktu} />

		          		<Route path="/admin/outlet" exact component={DataOutlet} />
		          		<Route path="/admin/outlet/form/:id?" component={FormOutlet} />

		          		<Route path="/admin/produk" component={DataProduk} exact />
		          		<Route path="/admin/produk/form/:id?" render={(props) => <FormProduk {...props} /> } />

		          		<Route path="/admin/jenis_pajak" component={DataJenisPajak} exact />
		          		<Route path="/admin/jenis_pajak/form/:id?" render={(props) => <FormJenisPajak {...props} /> } />

		          		<Route path="/admin/perusahaan" component={DataPerusahaan} exact />
		          		<Route path="/admin/perusahaan/form/:id?" render={(props) => <FormPerusahaan {...props} /> } />

		          		<Route path="/admin/jabatan" component={DataJabatan} exact />
		          		<Route path="/admin/jabatan/form/:id?" render={(props) => <FormJabatan {...props} /> } />

		          		<Route path="/admin/karyawan" component={DataKaryawan} exact />
		          		<Route path="/admin/karyawan/form/:id?" render={(props) => <FormKaryawan {...props} /> } />

		          		<Route path="/admin/pasien" component={DataPasien} exact />
		          		<Route path="/admin/pasien/form/:id?" render={(props) => <FormPasien {...props} /> } />

		          		<Route path="/admin/suplier" component={DataSuplier} exact />
		          		<Route path="/admin/suplier/form/:id?" render={(props) => <FormSuplier {...props} /> } />

		          		<Route path="/admin/harga_produk" component={DataHargaProduk} exact />
		          		<Route path="/admin/harga_produk/form/:id?" render={(props) => <FormHargaProduk {...props} /> } />

		          		<Route path="/admin/diskon" component={DataDiskon} exact />
		          		<Route path="/admin/diskon/form/:id?" render={(props) => <FormDiskon {...props} /> } />

		          		<Route path="/admin/pajak" component={DataPengaturanPajak} exact />
		          		<Route path="/admin/pajak/form/:id?" render={(props) => <FormPengaturanPajak {...props} /> } />

		          		<Route path="/admin/registrasi" render={(props) => <FormRegistrasi {...props} /> } />
		          		<Route path="/admin/layanan" render={(props) => <FormLayanan {...props} /> } />
		          		<Route path="/admin/pembayaran" render={(props) => <FormPembayaran {...props} /> } />

		          		<Route path="/admin/penjualan/:id?" render={(props) => <FormPenjualan {...props} /> } />
		          		<Route path="/admin/penjualan_wholesale/:id?" render={(props) => <FormPenjualanWholesale {...props} /> } />
		          		<Route path="/admin/pembayaran_penjualan" component={PembayaranPenjualan} exact />
		          		<Route path="/admin/verifikasi_penjualan" component={VerifikasiPenjualan} exact />

		          		<Route path="/admin/pembelian" component={FormPembelian} exact />
		          		<Route path="/admin/pembelian/data" component={DataPembelian} exact />
		          		<Route path="/admin/pembelian/stock" component={DataStock} exact />

		          		<Route path="/admin/pembayaran_pembelian" component={PembayaranPembelian} exact />
		          		<Route path="/admin/retur_pembelian" component={ReturPembelian} exact />
		          		<Route path="/admin/retur_pembelian/form/:id" render={(props) => <FormReturPembelian {...props} /> } />

		          		<Route path="/admin/stock_konsinyasi" component={FormTitipan} exact />
		          		<Route path="/admin/pembayaran_konsinyasi" component={PembayaranTitipan} exact />

		          		<Route path="/admin/customer" component={DataCustomer} exact />
		          		<Route path="/admin/customer/form/:id?" render={(props) => <FormCustomer {...props} /> } />

		          		<Route path="/admin/jenis_pembayaran" component={DataJenisPembayaran} exact />
		          		<Route path="/admin/jenis_pembayaran/form/:id?" render={(props) => <FormJenisPembayaran {...props} /> } />

		          		<Route path="/admin/laporan/transaksi" component={LaporanTransaksi} exact />

		          		<Route path="" render={
		          			() =>
		          				<Result
								    status="404"
								    title="404"
								    subTitle="Maaf, halaman tidak ditemukan."
								/>
		          		} />
		          	</Switch>
		          </Content>
		          {/*<Footer />*/}
		        </div>
		      </Layout>
		);
	}
}

const mapStateToProps = function(state) {
  return state.configReducers
}

export default connect(mapStateToProps, {
		setConfig,
		ajaxViewHandler
	})(AdminLayout);
