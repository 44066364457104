import { 
  SET_CONFIG,
  INITIALIZE_HANDLER,
  SUCCESS_HANDLER,
  FAILURE_HANDLER,
  SUCCESS_VIEW,
  SUCCESS_DOWNLOAD,
} from "../actionTypes";
import Cookies from 'js-cookie';

import Config from '../../Config';

let config = new Config();

let initialState = {
  collapsed: false,
  isLogin: Cookies.get(config.get_cookie_name())?true:false,
  isMobile: false,
  username: '',
  nama: '',
  id: '',
  pp: '',
  alamat: '',
  no_hp: '',
  layanan_id: [],
  layanan_str: [],
  isDokter: undefined,
  //ajax handler
  isRedirect: false,
  isSuccess: false,
  responseCode: 0,
  responseMsg: '',
  domainName: '',
  switchable: false,
  isSuperadmin: false,
  jmlNotif: 0,
  tempatTugas: config.get_site_info().name,
  //notifikasi
  latestId_PembelianObat: 0,
  latestId_PeriksaLab: 0,
  latestId_HomeCare: 0,
  latestId_Ambulans: 0,
  latestId_Emergency: 0,
  ws: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CONFIG: {
      const newState = Object.assign({}, initialState, action.payload.newConfig);
      initialState = newState;
      return newState;
    }
    case INITIALIZE_HANDLER: {
      const result = {
        isRedirect: false,
        isSuccess: false,
        responseCode: 0,
        responseMsg: '',
      }
      const newState = Object.assign({}, state, result);
      return newState;
    }
    case SUCCESS_VIEW: {
      //console.log('sukses_view', action);
      let res = '';
      if(action.payload.data.count != undefined){
          res = action.payload.data;
      }else if(action.payload.data.results != undefined){
          res = action.payload.data.results;
      }else if(action.payload.data != undefined){
          res = action.payload.data;
      }else{
          res = action.payload.statusText;
      }
      const result = {
        isRedirect: false,
        isSuccess: true,
        responseCode: action.payload.status,
        responseMsg: res
      }
      const newState = Object.assign({}, initialState, result);
      //initialState = newState;
      return newState;
    }
    case SUCCESS_DOWNLOAD: {
      //console.log('sukses_downloads', action);
      let res = action.payload.data;

      const result = {
        isRedirect: false,
        isSuccess: true,
        responseCode: action.payload.status,
        responseMsg: res
      }
      const newState = Object.assign({}, initialState, result);
      //initialState = newState;
      return newState;
    }
    case SUCCESS_HANDLER: {
      //console.log('sukses', action);
      const result = {
        isRedirect: true,
        isSuccess: true,
        responseCode: action.payload.status,
        responseMsg: action.payload.data != undefined ? action.payload.data : action.payload.statusText
      }
      const newState = Object.assign({}, initialState, result);
      initialState = newState;
      return newState;
    }
    case FAILURE_HANDLER: {
      //console.log('fagal', action);
      let errMsg = "";
      if(action.error.response != undefined && action.error.response.data != undefined && action.error.response.data != null){
        errMsg = action.error.response.data;
      }else if(action.error.response != undefined && action.error.response.statusText != '' && action.error.response.statusText != null){
        errMsg = action.error.response.statusText;
      }else{
        errMsg = action.error.message != undefined ? action.error.message : action.error.data
      }
      //console.log('action_error', errMsg);
      const result = {
        isRedirect: false,
        isSuccess: false,
        responseCode: action.error.response != undefined ? action.error.response.status : action.error.status,
        responseMsg: errMsg
      }
      const newState = Object.assign({}, state, result);
      return newState;
    }
    default:
      return state;
  }
}
