import React from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Form,
	Tag
} from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  RedoOutlined,
  ShoppingCartOutlined,
  FileAddOutlined,
  HistoryOutlined,
  CheckCircleOutlined,
  ArrowsAltOutlined,
} from '@ant-design/icons';

import axios from 'axios';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import moment from 'moment';

import Config from '../../Config';
import {Helmet} from "react-helmet";
import NumberFormat from 'react-number-format';

const configClass = {
	title: 'retur pembelian',
	apiUrl: {
		data: 'transaksi/api/pembelian/'
	},
	pageUrl: {
		form: '/admin/retur_pembelian/form/'
	},
	breadCrumb: [
		(
			<span>
				<ArrowsAltOutlined /> retur
			</span>
		),
		'pembelian'
	]

}

const config = new Config();

class ReturPembelian extends React.Component {
	formPembayaran = React.createRef();
	constructor(props){
		super(props);
		this.state = {
			errMsg: '',
			loadingData: false,
			pagination: {pageSize: 25},
			searchText: '',
			searchColumn: '',
			dataSource: [],
			columns: [
				{
					title: 'KODE',
					width: 150,
					dataIndex: 'kode',
					key: 'kode',
					fixed: 'left'
				},
				{
					title: 'SUPLIER',
					width: 200,
					dataIndex: 'suplier_data',
					key: 'suplier_data',
					render: (value, row, index) => {
						return value.nama
					}
				},
				{
					title: 'CREATED',
					width: 200,
					dataIndex: 'created',
					key: 'created',
					render: (value, row, index) => {
						return moment(value).format('DD-MM-YYYY hh:mm:ss')
					}
				},
				{
					title: 'TOTAL',
					width: 200,
					dataIndex: 'total_harga',
					key: 'total_harga',
					render: (value, row, index) => {
						return parseFloat(value).toLocaleString('en-us')
					}
				},
				{
					title: 'DIBAYAR',
					width: 200,
					dataIndex: 'total_bayar',
					key: 'total_bayar',
					render: (value, row, index) => {
						return parseFloat(value).toLocaleString('en-us')
					}
				},
				{
					title: 'KEKURANGAN',
					width: 200,
					dataIndex: 'total_bayar',
					key: 'kekurangan',
					render: (value, row, index) => {
						return (parseFloat(row.total_harga) - parseFloat(value)).toLocaleString('en-us')
					}
				},
				{
					title: 'RETUR',
					width: 150,
					dataIndex: 'id',
					key: 'aksi',
					fixed: 'right',
					render: (value, row, index) => {
						return (
							
								<Row key={"space-aksi-"+index} justify="center">
									<Col style={{padding: 5}}>
										<Link to={configClass.pageUrl.form+value}>
											<Button 
												className="btn-edit" 
												size="small" 
												shape="circle" 
											>
													<FileAddOutlined />
											</Button>
										</Link>
									</Col>
								</Row>
							
						)
					},
				}

			],
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.getData();
	}

	

	showDeleteConfirm(id) {
	  Modal.confirm({
	    title: 'Konfirmasi Hapus?',
	    icon: <ExclamationCircleOutlined />,
	    content: 'yakin menghapus data?',
	    okText: 'Ya',
	    okType: 'danger',
	    cancelText: 'Tidak',
	    onOk: () => this.prosesHapus(id),
	    onCancel() {
	      //console.log('Cancel');
	    },
	  });
	}

	prosesHapus(id) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('delete', configClass.apiUrl.data+id+'/')
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menghapus data', duration: 2, key: 'toast' });
				this.getData();
			}else{
				const errTitle = 'gagal menghapus data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_delete', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	getColumnSearchProps = dataIndex => ({
	    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
	      <div style={{ padding: 8 }}>
	        <Input
	          ref={node => {
	            this.searchInput = node;
	          }}
	          placeholder={`Search ${dataIndex}`}
	          value={selectedKeys[0]}
	          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
	          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          style={{ width: 188, marginBottom: 8, display: 'block' }}
	        />
	        <Button
	          type="primary"
	          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          icon={<SearchOutlined />}
	          size="small"
	          style={{ width: 90, marginRight: 8 }}
	        >
	          Search
	        </Button>
	        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
	          Reset
	        </Button>
	      </div>
	    ),
	    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	    onFilter: (value, record) =>
	      record[dataIndex]
	        .toString()
	        .toLowerCase()
	        .includes(value.toLowerCase()),
	    onFilterDropdownVisibleChange: visible => {
	      if (visible) {
	        setTimeout(() => this.searchInput.select());
	      }
	    },
	    render: text =>
	      this.state.searchedColumn === dataIndex ? (
	        <Highlighter
	          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
	          searchWords={[this.state.searchText]}
	          autoEscape
	          textToHighlight={text.toString()}
	        />
	      ) : (
	        text
	      ),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
	    confirm();
	    this.setState({
	      searchText: selectedKeys[0],
	      searchedColumn: dataIndex,
	    });
	};

	handleReset = clearFilters => {
	    clearFilters();
	    this.setState({ searchText: '' });
	};

	async getData(limit=this.state.pagination.pageSize, offset=0, search=this.state.searchText){
		this.setState({loadingData: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.data+'?limit='+limit+'&offset='+offset+'&search='+search)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				const pagination = { ...this.state.pagination };
				pagination.total = data.count;
				this.setState({
					dataSource: data.results,
					pagination
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingData: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingData: false});
		});
	}

	handleTableChange = async (pagination, filters, sorter) =>{
    	const newPagination = { ...this.state.pagination };
    	newPagination.pageSize = pagination.pageSize;
		this.setState({
			loadingData: true,
			pagination
		});
		if(pagination.current==1){
			this.getData(pagination.pageSize);
		}else{
			this.getData(pagination.pageSize, (pagination.current - 1)*pagination.pageSize);
		}

		
	}

	handleSubmit = (values) =>{
		var params = new FormData();
		for ( var key in values ) {
			if(values[key] == undefined){
				params.append(key, '');
			}else{
				if(key == 'tgl_start' || key == 'tgl_end'){
					params.append(key, (values[key]).toString()+':00');
				}else{
					params.append(key, (values[key]).toString());
				}
			}
	    }
	 
		this.postData(values);
	}

	async postData(datas) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('post', configClass.apiUrl.data+this.state.id+'/pembayaran/', datas, true, true)
		.then(() =>{
			this.setState({
				loadingButton: false,
				modalPembayaran: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menyimpan data', duration: 2, key: 'toast' });
				this.getData();
			}else{
				const errTitle = 'gagal menyimpan data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_post', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	render() {
		if(this.props.layanan_str.length>0 && this.props.layanan_str.includes('Pembelian / Pemesanan Obat') == false){
			return <Redirect to="/admin/forbidden" />
		}
		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Row className="isi">
				       	<Col span={24}>
				        	<Card
				        		className="konten"
					            title={
					            	<Row>
					            		<Col lg={{ span: 20 }} xs={{ span:24 }}>
							            	<span className="konten-judul">
							            		{configClass.title}
							            		{(this.state.loadingData &&
									        		<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="30" alt="loading" />
									        	)}
							            	</span>
							            </Col>
						            	<Col lg={{ span: 4 }} sm={{ span: 24 }} xs={{ span:24 }} style={{textAlign: 'right'}}>
						            		<Button 
										        	loading={this.state.loadingButton}
										        	className="btn-refresh"
										        	size="small"
										        	onClick={() => this.getData()} 
										        >
										          <RedoOutlined />
										    </Button>
						            		
						            	</Col>
						            </Row>
					            }
					        >
					        	{(this.state.errMsg!='' &&
						        	<Alert
								      message={this.state.errTitle}
								      description={JSON.stringify(this.state.errMsg)}
								      type="error"
								      showIcon
								      style={{
								      	marginBottom: 10
								      }}
								    />
								)}
					        	<Table 
					        		title={() => 
					        			<Row>
					        				<Col lg={8} sm={24} xs={24}><span className="konten-judul">Total {this.state.pagination.total} record</span></Col>
											<Col lg={{span: 8, offset: 8}} sm={24} xs={24}>
												<Input.Search 
													size="small"
													placeholder="ketikkan pencarian" 
													onSearch={value => {
														this.setState({
															searchText: value
														}, () =>{
																this.getData();
														});
													}} 
													allowClear
												/>										
											</Col>
										</Row>					        			
					        		}
					        		bordered
					        		columns={this.state.columns} 
					        		dataSource={this.state.dataSource} 
					        		loading={this.state.loadingData} 
					        		scroll={{ x: 240, y: 450 }}
					        		size="small"
					        		pagination={this.state.pagination}
					        		onChange={this.handleTableChange}
					        	/>
					        </Card>
				        </Col>
				    </Row>
				</div>
				<Modal
				  autoFocus={false}
				  closable={true}
				  visible={this.state.modalPembayaran}
		          title="pembayaran / angsuran"
		          onCancel={() =>{
		          	this.setState({
		          		modalPembayaran: false
		          	});
		          }}
		          footer={[]}
		        >
		        	<Form
		        		ref={this.formPembayaran}
				        labelCol={{ span: 10 }}
				        wrapperCol={{ span: 14 }}
				        layout="horizontal"
				        size={"small"}
				        initialValues={{
				        	preview_total_harga: this.state.total_harga,
				        	preview_total_bayar: this.state.total_bayar,
				        	preview_total_angsuran: this.state.total_angsuran,
				        	preview_total_kekurangan: this.state.total_kekurangan
				        }}
				        onFinish={this.handleSubmit}
				    >
				    	<Form.Item label="Pembelian Total" name="preview_total_harga">
				          <NumberFormat 
							style={{textTransform: 'none'}}
							autoComplete="off" 
							thousandSeparator={true} 
							prefix={'Rp. '}
							className="ant-input ant-input-sm"
							readOnly
							/>
				        </Form.Item>
				        <Form.Item label="Total Sudah Dibayar" name="preview_total_bayar">
				          <NumberFormat 
							style={{textTransform: 'none'}}
							autoComplete="off" 
							thousandSeparator={true} 
							prefix={'Rp. '}
							className="ant-input ant-input-sm"
							readOnly
							/>
				        </Form.Item>
				        <Form.Item label="Saldo Sebelum" name="preview_total_kekurangan">
				          <NumberFormat 
							style={{textTransform: 'none'}}
							autoComplete="off" 
							thousandSeparator={true} 
							prefix={'Rp. '}
							className="ant-input ant-input-sm"
							readOnly
							/>
				        </Form.Item>
				        		<Form.Item 
					        		label="Dibayar" 
					        		name="preview_total_angsuran" 
					        		rules={[
										{ 
											required: true, 
											message: 'harus diisi' 
										}
									]}
					        	>
						          	<NumberFormat 
						          		autoFocus={true}
										style={{textTransform: 'none'}}
										autoComplete="off" 
										thousandSeparator={true} 
										prefix={'Rp. '}
										className="ant-input ant-input-sm"
								        onValueChange={(values) => {
									        const {formattedValue, value} = values;
									        
									        var kekurangan = this.state.total_kekurangan - value;
									        
									        this.setState({
									        	kekurangan: kekurangan,
									        	total_angsuran: value,
									        }, () =>{
									        	this.formPembayaran.current.setFieldsValue({
									        		preview_saldo_setelah: kekurangan,
									        		nominal: value
									        	});
									        });
									        // if(kembali >= 0){
										        // this.formPembayaran.current.setFieldsValue({
										        // 	kekurangan: 'Rp. '+parseFloat(kekurangan).toLocaleString('en-US')
										        // });
										    // }
									        // this.setState({
									        //    harga: parseFloat(value)
									        // });                       // console.log('harga', values);
								        }}
								  	/>
						        </Form.Item>
						        <Form.Item label="Total Angsuran" name="nominal" value={this.state.total_angsuran} style={{display: 'none'}}>
						          	<Input />
						        </Form.Item>
						  <Form.Item label="Saldo Setelah" name="preview_saldo_setelah">
					          <NumberFormat 
								style={{textTransform: 'none'}}
								autoComplete="off" 
								thousandSeparator={true} 
								prefix={'Rp. '}
								className="ant-input ant-input-sm"
								readOnly
								/>
							</Form.Item>
				        <Row
				        	justify="end"
				        >
				        	<Col style={{padding: 5}}>
						        <Button 
						        	type="primary" 
						        	htmlType="submit"
						        	disabled={this.state.kembali < 0 ? true : false}
						        >
						        	Simpan
						        </Button>
					        </Col>
					        <Col style={{padding: 5}}>
						        <Button 
						        	type="danger"
						        	htmlType="button"
						        	onClick={() =>{
							          	this.setState({
							          		modalPembayaran: false
							          	});
							        }}
						        >
						          Cancel
						        </Button>
						    </Col>
						</Row>
			        </Form>
		        </Modal>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(ReturPembelian);