import React, { PureComponent } from 'react'
import { Redirect, Link } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Avatar,
	Form, 
	Input,
	Button,
	Space,
	Select,
	DatePicker,
	message,
	Alert,
	Spin,
	Skeleton
} from 'antd';
import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../store/actions";
import {Helmet} from "react-helmet";
import '../assets/invoice.css';
import Config from '../Config';
import moment from 'moment';

import Barcode  from 'react-barcode';


const configClass = {
	title: 'nota penjualan',
	apiUrl: {
		preview: "transaksi/api/transaksi/",
		userProfile: "auth/api/user/info"
	}
}

class CetakRegistrasi extends PureComponent {
	config;
	
	constructor(props){
	    super(props);
	    this.config = new Config();
	    this.state = {
	    	loading: true,
	    	data_registrasi: null
	    }

	    // this.preview(this.props.match.params.id);
	    
	}

	componentDidMount(){
		this.preview(this.props.match.params.id);
	}

	async preview(id) {
		message.loading({ content: 'Menampilkan data...', duration: 0, key: 'toast' });

		this.setState({
			errTitle: '',
			errMsg: '',
			loading: true
		});

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview+id)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				message.destroy();
				this.setState({
					loading: false,
					data_registrasi: data
				}, () =>{
					setTimeout(function(){
						window.print();

					}, 100);
				});
	        }else{
	        	const errTitle = 'error preview data registrasi';
	        	this.setState({
	        		loading: false,
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data registrasi, coba lagi', duration: 2, key: 'toast' });
			this.setState({
				loading: false
			});
		});
	}

	render() {		
    	return (
			  <div id="invoice-POS">
			  		<Helmet>
			  			<title>cetak registrasi</title>
			  		</Helmet>
			  		{this.state.loading 
			  			?
			  				<Skeleton active />
			  			:
			  				<div>
							    <center>
							    	<div className="registrasi-no-urut">
							    		{this.state.data_registrasi.urutan}
							    	</div>
								    <Barcode 
								    	width={2}
								    	value={this.state.data_registrasi.code} 
								    	height={50}
								    	displayValue={true}
								    	fontSize={14}
								    	format={"CODE128"}
								    />
								</center>
								<center>
									<div className="registrasi-nama">
										{this.state.data_registrasi.pasien_nama}
									</div>
									<div className="registrasi-footer">
										{this.state.data_registrasi.cabang_nama+' '+moment(this.state.data_registrasi.tanggal).format('DD-MM-YYYY')}
									</div>
								</center>
							</div>
					}
			   </div>

    	)
    }


}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(CetakRegistrasi);