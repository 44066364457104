import React from 'react';
import { Link, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Avatar,
	Form, 
	Input,
	Button,
	Space,
	Select,
	DatePicker,
	message,
	Alert,
	Spin
} from 'antd';
import {
  ToolOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';

import axios from 'axios';
import Config from '../../Config';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import {Helmet} from "react-helmet";

import LocationPicker from 'react-location-picker';

const FormItem = Form.Item

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    }
  },
};

const configClass = {
	title: 'form diskon',
	apiUrl: {
		preview: "pengaturan/api/diskon/",
		produk: "master/api/produk",
	},
	pageUrl: {
		data: '/admin/diskon/'
	},
	breadCrumb: [
		(
			<span>
				<ToolOutlined /> pengaturan
			</span>
		),
		'diskon',
		'form'
	]

}

const config = new Config();

class FormDiskon extends React.Component {
	formRef = React.createRef();

	constructor(props){
		super(props);

		this.state = {
			errComponent: false,
			errTitle: '',
			errMsg: '',
			loadingButton: false,
			
			loadingProduk: false,
			listProduk: [],
			
			aksi: 'tambah',
			method: 'post',
			idLama: '',
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.loadMasterList();
	}

	loadMasterList(){
		const rule = [
			{
				destination: {
					stateProgressName: 'loadingProduk',
					endPoint: configClass.apiUrl.produk,
					stateDestination: 'listProduk',
				},
				nextDestination: null
			}
		];

		this.setState({
			errComponent: false
		});

		rule.map((item, index) => {
			if(index == rule.length-1){
				this.getMasterList(item, true);
			}else{
				this.getMasterList(item);
			}
		});
	}

	async getMasterList(rule, isFinal=false) {
		var destination = rule.destination;
		var nextDestination = rule.nextDestination;
		this.setState({[destination.stateProgressName]: true});
		this.props.ajaxViewHandler('get', destination.endPoint)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				if(data.count != undefined){
					let newState = null;
					if(this.state[destination.stateDestination].length == 0){
						newState = data;
					}else{
						if(destination.stateProgressName.includes('_searching')){
							newState = data
						}else{
							const oldState = this.state[destination.stateDestination];
							newState = {
								count: data.count,
								next: data.next,
								previous: data.previous,
								results: [...oldState.results, ...data.results]
							};
						}
					}
					this.setState({
		            	[destination.stateDestination]: newState
		            });
				}else if(data.results != undefined){
	        		this.setState({
		            	[destination.stateDestination]: data.results
		            });
	        	}else{
	        		if(Array.isArray(data)){
			            this.setState({
			            	[destination.stateDestination]: data
			            })
			        }else{
			        	this.setState({
			            	[destination.stateDestination]: [data]
			            })
			        }
		        }
		        if(isFinal){
		        	if(this.props.match.params.id != undefined){
						this.preview(this.props.match.params.id);
					}
		        }
		    }else{
		    	this.setState({
	        		errComponent: true
	        	});
		    }
		    this.setState({[destination.stateProgressName]: false});
		}).catch((response) => {
			//console.log('gagal_get_master', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({
				errTitle: 'gagal request data master',
				errMsg: response
			});
			this.setState({[destination.stateProgressName]: false});
		});
        //next function
        if(nextDestination != null){
        	const next = {
        		destination: {
	        		stateProgressName: nextDestination.stateProgressName,
	        		endPoint: nextDestination.endPoint,
	        		stateDestination: nextDestination.stateDestination,
	        	},
        		nextDestination: nextDestination.next

        	}
        	this.getMasterList(next);
        }
	}

	async preview(id) {
		message.loading({ content: 'Menampilkan data sebelumnya...', duration: 0, key: 'toast' });

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview+id)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				this.formRef.current.setFieldsValue(data);
				for ( var key in data ) {
				  //   const dateRegex = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
				  //   const dateTimeRegex = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
				  //   if(dateRegex.test(data[key])){//jika format tanggal			    	
				  //   	this.formRef.current.setFieldsValue({
				  //   		[key+'_picker']: moment(data[key], 'YYYY-MM-DD'),
						// 	//[key]: moment(data[key], 'DD-MM-YYYY')
						// });
				  //   }else if(dateTimeRegex.test(data[key])){//jika format tanggal dan jam	
				  //   	alert(data[key]);    	
				  //   	this.formRef.current.setFieldsValue({
				  //   		[key+'_picker']: moment(data[key], 'YYYY-MM-DD HH:mm:ss'),
						// });
				  //   }
				  if(key == 'tgl_start' || key == 'tgl_end'){
				  	// alert(moment(data[key], 'YYYY-MM-DD HH:mm:ss')+'');
				  	this.formRef.current.setFieldsValue({
				    	[key+'_picker']: moment(data[key], 'YYYY-MM-DD HH:mm:ss'),
				    	// [key]: moment(data[key], 'YYYY-MM-DD HH:mm:ss')
					});	
				  }
				  if(key == 'max_qty'){
				  	this.formRef.current.setFieldsValue({
				  		[key]: '5000'
				  	});
				  }
	        	}
	        	message.destroy();
	        	this.setState({
	        		aksi: 'edit',
	        		method: 'put',
	        		idLama: id+'/'
	        	});
	        }else{
	        	const errTitle = 'error preview data lama';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	handleSubmit = (values) =>{
		var params = new FormData();
		for ( var key in values ) {
			if(values[key] == undefined){
				params.append(key, '');
			}else{
				if(key == 'tgl_start' || key == 'tgl_end'){
					params.append(key, (values[key]).toString()+':00');
				}else{
					params.append(key, (values[key]).toString());
				}
			}
	    }
	 // 	var params = {};
		// for ( var key in values ) {
		// 	if(values[key] == undefined){
		// 		params = {...key, ''};
		// 	}else{
		// 		if(key == 'tgl_start' || key == 'tgl_end'){
		// 			params = {...key, (values[key]).toString()+':00'};
		// 		}else{
		// 			params = {...key, (values[key]).toString()};
		// 		}
		// 	}
	 //    }
		this.postData(params);
	}

	async postData(datas) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler(this.state.method, configClass.apiUrl.preview+this.state.idLama, datas, true, true)
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menyimpan data', duration: 2, key: 'toast' });
			}else{
				const errTitle = 'gagal menyimpan data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_post', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	generateSelect(stateName, url, key, value){
		console.log('tes', url);
		return (
			this.state['loading'+stateName]?(
				<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
			):(
				<Select
					showSearch
					placeholder="pilih"
					optionFilterProp="children"
					onSearch={ val => {
						const rule = {
							destination: {
								stateProgressName: 'loading'+stateName+'_searching',
								endPoint: url+'?search='+val,
								stateDestination: 'list'+stateName
							},
							nextDestination: null
						}
						this.getMasterList(rule);
					}}
					onPopupScroll={ e => {
						e.persist(); 
						let target = e.target;
						if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
							if(this.state['list'+stateName].next != undefined && this.state['list'+stateName].next != null){
								const rule = {
									destination: {
										stateProgressName: 'loading'+stateName+'Paging',
										endPoint: this.state['list'+stateName].next,
										stateDestination: 'list'+stateName
									},
									nextDestination: null
								}
								this.getMasterList(rule);
							}
						}
					}}
				>
					{(this.state['loading_'+stateName+'searching'] &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
					{this.state['list'+stateName].count != undefined ? (
						this.state['list'+stateName].results.map((item) => (
							<Select.Option value={item[key]}>{item[key]+" - "+item[value]+""}</Select.Option>
						))
					) : (
						this.state['list'+stateName].map((item) => (
							<Select.Option value={item[key]}>{item[key]+" - "+item[value]+""}</Select.Option>
						))
					)}
					{(this.state['list'+stateName] != undefined && this.state['list'+stateName].next != undefined &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
				</Select>
			)
		);
	}

	generateSelectMultiple(stateName, url, key, value){
		console.log('tes', url);
		return (
			this.state['loading'+stateName]?(
				<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
			):(
				<Select
					mode="multiple"
					showSearch
					placeholder="pilih"
					optionFilterProp="children"
					onSearch={ val => {
						const rule = {
							destination: {
								stateProgressName: 'loading'+stateName+'_searching',
								endPoint: url+'?search='+val,
								stateDestination: 'list'+stateName
							},
							nextDestination: null
						}
						this.getMasterList(rule);
					}}
					onPopupScroll={ e => {
						e.persist(); 
						let target = e.target;
						if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
							if(this.state['list'+stateName].next != undefined && this.state['list'+stateName].next != null){
								const rule = {
									destination: {
										stateProgressName: 'loading'+stateName+'Paging',
										endPoint: this.state['list'+stateName].next,
										stateDestination: 'list'+stateName
									},
									nextDestination: null
								}
								this.getMasterList(rule);
							}
						}
					}}
				>
					{(this.state['loading_'+stateName+'searching'] &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
					{this.state['list'+stateName].count != undefined ? (
						this.state['list'+stateName].results.map((item) => (
							<Select.Option value={item[key]}>{item[key]+" - "+item[value]+""}</Select.Option>
						))
					) : (
						this.state['list'+stateName].map((item) => (
							<Select.Option value={item[key]}>{item[key]+" - "+item[value]+""}</Select.Option>
						))
					)}
					{(this.state['list'+stateName] != undefined && this.state['list'+stateName].next != undefined &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
				</Select>
			)
		);
	}

	render() {
		// if(this.props.isSuperadmin == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		if(this.props.isRedirect){
			return <Redirect to={configClass.pageUrl.data} />
		}

		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Form 
						layout="vertical"
						name="fdata"
					    onFinish={this.handleSubmit}
					    size="small"
					    ref={this.formRef}
					    initialValues={{
					    	max_qty: '5000'
					    }}
					>
						{/*data dasar*/}
						<Row className="isi">
					       	<Col lg={24} sm={24} xs={24}>
					        	<Card
					        		className="konten"
						            title={
						            	<span className="konten-judul">
						            		{configClass.title}
						            		{(this.state.loadingButton &&
								        		<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
								        	)}
						            	</span>
						            }
						        >
						        		{(this.state.errMsg!='' &&
								        	<Alert
										      message={this.state.errTitle}
										      description={JSON.stringify(this.state.errMsg)}
										      type="error"
										      showIcon
										      style={{
										      	marginBottom: 10
										      }}
										    />
										)}
					                	<Row justify="space-between">
						                	<Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="PRODUK"
										            name="produk"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            {this.state.loadingProduk?(
														<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
													):(
														<Select
															allowClear
															showSearch
															placeholder="pilih"
															optionFilterProp="children"
															onSearch={ val => {
																const rule = {
																	destination: {
																		stateProgressName: 'loadingProduk_searching',
																		endPoint: configClass.apiUrl.produk+'?search='+val,
																		stateDestination: 'listProduk'
																	},
																	nextDestination: null
																}
																this.getMasterList(rule);
															}}
															onPopupScroll={ e => {
																e.persist(); 
																let target = e.target;
																if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
																	if(this.state.listProduk.next != undefined && this.state.listProduk.next != null){
																		const rule = {
																			destination: {
																				stateProgressName: 'loadingProdukPaging',
																				endPoint: this.state.listProduk.next,
																				stateDestination: 'listProduk'
																			},
																			nextDestination: null
																		}
																		this.getMasterList(rule);
																	}
																}
															}}
														>
															{(this.state.loadingProduk_searching &&
																<Select.Option value=""><Spin size="small" /></Select.Option>
															)}
															{this.state.listProduk.count != undefined ? (
																this.state.listProduk.results.map((item) => (
																	<Select.Option value={item.id}>{item.nama}</Select.Option>
																))
															) : (
																this.state.listProduk.map((item) => (
																	<Select.Option value={item.id}>{item.nama}</Select.Option>
																))
															)}
															{(this.state.listProduk != undefined && this.state.listProduk.next != undefined &&
																<Select.Option value=""><Spin size="small" /></Select.Option>
															)}
														</Select>
													)}
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="DISKON RUPIAH"
										            name="rupiah"
										        >
										            <Input
										            	style={{textTransform: 'none'}}
										                placeholder="diskon rupiah"
										                onChange={(e) =>{
										                	if(e.target.value != ''){
										                		this.formRef.current.setFieldsValue({
																    persen: '0',
																});
										                	}
										                }}
										            />
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="DISKON PERSEN"
										            name="persen"
										        >
										            <Input
										            	style={{textTransform: 'none'}}
										                placeholder="diskon persen"
										                onChange={(e) =>{
										                	if(e.target.value != ''){
										                		this.formRef.current.setFieldsValue({
																    rupiah: '0',
																});
										                	}
										                }}
										            />
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="MINIMAL QTY"
										            name="min_qty"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input
										            	style={{textTransform: 'none'}}
										                placeholder="minimal quantity"
										            />
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between" style={{display: 'none'}}>
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="MAKSIMAL QTY"
										            name="max_qty"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input
										            	style={{textTransform: 'none'}}
										                placeholder="maximal quantity"
										            />
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="TANGGAL MULAI"
										            name="tgl_start_picker"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <DatePicker
										            	showTime
										        		placeholder="yyyy-mm-dd hh:mm" 
										        		format="YYYY-MM-DD HH:mm"
										                style={{width: '100%'}}
										                onChange={(date, dateString) =>{
										                	this.formRef.current.setFieldsValue({
														      tgl_start: dateString,
														    });
										                }}
										        	/>
										        </Form.Item>
										        <Form.Item
										        	name="tgl_start"
										        	style={{
										        		display: 'none'
										        	}}
										        >
										        	<Input type="hidden" />
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="TANGGAL SELESAI"
										            name="tgl_end_picker"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <DatePicker
										            	showTime 
										        		placeholder="yyyy-mm-dd hh:mm" 
										        		format="YYYY-MM-DD HH:mm"
										                style={{width: '100%'}}
										                onChange={(date, dateString) =>{
										                	this.formRef.current.setFieldsValue({
														      tgl_end: dateString,
														    });
										                }}
										        	/>
										        </Form.Item>
										        <Form.Item
										        	name="tgl_end"
										        	style={{
										        		display: 'none'
										        	}}
										        >
										        	<Input type="hidden" />
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="end">
									        <Col style={{padding: 5}}>
									        	<Button 
													key="btn-simpan"
													loading={this.state.loadingButton}
													type="primary" 
													htmlType="submit"
													disabled={this.state.errComponent?true:false}
													size="small"
												>
													{(!this.state.loadingButton && <CheckCircleOutlined />)}
													Simpan
												</Button>
											</Col>
											<Col style={{padding: 5}}>
												<Link to={configClass.pageUrl.data}>
													<Button 
														type="danger"
														key="btn-cancel"
														disabled={this.state.loadingButton?true:false}
													    size="small"
													>
														<CloseCircleOutlined /> Cancel
													</Button>
												</Link>
										    </Col>
										</Row>

						        </Card>
					        </Col>
					    </Row>
					</Form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(FormDiskon);