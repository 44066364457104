import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  Layout,
  Menu,
  Avatar,
  Space,
  BackTop,
  Drawer
} from 'antd';
import {
  ClusterOutlined,
  DashboardOutlined,
  HomeOutlined,
  HeartOutlined,
  TeamOutlined,
  SnippetsOutlined,
  MailOutlined,
  GroupOutlined,
  LineChartOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  GlobalOutlined,
  DropboxOutlined,
  BugOutlined,
  LoginOutlined,
  SettingOutlined,
  BankOutlined,
  FolderOpenOutlined,
  SmileOutlined,
  DatabaseOutlined,
  ToolOutlined,
  LayoutOutlined,
  UsergroupAddOutlined,
  ShoppingOutlined,
  DiffOutlined,
  SwapOutlined,
  ShoppingCartOutlined,
  ArrowsAltOutlined,
  CarOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import Cookies from 'js-cookie';
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Helmet} from "react-helmet";

import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import { setConfig, ajaxViewHandler } from "../store/actions";

import Config from '../Config';

const { SubMenu } = Menu;
const { Sider } = Layout;

const configClass = {
  apiUrl: {
    sider: ""
  }
}

class AdminSider extends PureComponent {
  config;

  constructor(props){
    super(props);
    this.config = new Config();
  }

  componentDidMount(){
    //this.getInfoSidebar();
  }

  notifyMe(msg) {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      var notification = new Notification(msg);
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          var notification = new Notification(msg);
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
  }

  async getInfoSidebar(){
      this.props.ajaxViewHandler('get', configClass.apiUrl.sider)
      .then(() =>{
        const data = this.props.responseMsg.results[0];
        if(this.props.isSuccess){
          /*
          this.setState({
            logoDesa: data.logo!=null&&data.logo!=''?data.logo:process.env.PUBLIC_URL + "/logo_landscape.png",
            namaDesa: data.name,
          });
          */
        }else{
          /*
          this.setState({
            namaDesa: 'gagal menampilkan info desa',
          });
          */
        }
      }).catch((response) => {
        /*
        this.setState({
            namaDesa: 'gagal menampilkan info desa',
        });
        */
      });
  }

  render() {
    return (
      <PerfectScrollbar>
              <Helmet>
                <title>{"admin | "+(this.props.nama!=undefined?this.props.nama:this.config.get_site_info().name)}</title>
              </Helmet>
              <Sider
                breakpoint="lg"
                width={this.props.isMobile?'100%':256}

                trigger={null}
                collapsible
                collapsed={this.props.collapsed}
                className="sider"
                /*
                onCollapse={(collapsed, type) => {
                  this.props.setConfig({
                    collapsed: collapsed
                  });
                }}
                */
                /*
                onBreakpoint={
                  console.log('break')
                  //!this.props.isMobile && this.props.setConfig({collapsed:!this.props.collapsed})
                }
                */
              >
                  <div className="brand">
                    <div className="logo">
                      <img alt="logo" src={this.config.get_site_info().logo_landscape} />
                      {/*{!this.props.collapsed && <h1>Abdi Desa</h1>}*/}
                    </div>
                </div>
                  <Menu mode="inline" defaultSelectedKeys={['1']} className="sidebar">
                    <Menu.Item key="1">
                      <DashboardOutlined />
                      <Link to="/admin/index">
                        {(!this.props.collapsed && 'Dashboard')}
                      </Link>
                    </Menu.Item>
                    <SubMenu
                        key="menuperusahaan"
                        title={
                          <span>
                            <ClusterOutlined />
                            <span>Perusahaan</span>
                          </span>
                        }
                    >
                      <Menu.Item key="perusahaan">
                          <Link to="/admin/perusahaan">
                            Perusahaan
                          </Link>
                      </Menu.Item>
                      <Menu.Item key="cabang">
                          <Link to="/admin/cabang">
                            Cabang
                          </Link>
                      </Menu.Item>
                      <Menu.Item key="it_pajak">
                        <Link to="/admin/pajak">
                          Jenis Pajak
                        </Link>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu
                            key="menumaster"
                            title={
                              <span>
                                  <DiffOutlined />
                                  <span>Master</span>
                              </span>
                            }
                    >
                          <Menu.Item key="kategori_produk">
                              <Link to="/admin/kategori_produk">
                                Kategori Produk
                              </Link>
                          </Menu.Item>
                          <Menu.Item key="produk">
                              <Link to="/admin/produk">
                                Produk
                              </Link>
                          </Menu.Item>
                          <Menu.Item key="jenis_pembayaran">
                              <Link to="/admin/jenis_pembayaran">
                                Jenis Pembayaran
                              </Link>
                          </Menu.Item>
                          <Menu.Item key="diagnosa">
                              <Link to="/admin/diagnosa">
                                Diagnosa
                              </Link>
                          </Menu.Item>
                          <Menu.Item key="dosis">
                              <Link to="/admin/dosis">
                                Dosis
                              </Link>
                          </Menu.Item>
                          <Menu.Item key="aturan_makan">
                              <Link to="/admin/aturan_makan">
                                Aturan Makan
                              </Link>
                          </Menu.Item>
                          <Menu.Item key="waktu">
                              <Link to="/admin/waktu">
                                Waktu
                              </Link>
                          </Menu.Item>
                          <Menu.Item key="racikan">
                              <Link to="/admin/racikan">
                                Racikan
                              </Link>
                          </Menu.Item>
                    </SubMenu>
                    <SubMenu
                            key="menutransaksi"
                            title={
                              <span>
                                  <DollarOutlined />
                                  <span>Transaksi</span>
                              </span>
                            }
                    >
                          <Menu.Item key="registrasi">
                              <Link to="/admin/registrasi">
                                Registrasi
                              </Link>
                          </Menu.Item>
                          <Menu.Item key="layanan">
                              <Link to="/admin/layanan">
                                Layanan
                              </Link>
                          </Menu.Item>
                          <Menu.Item key="pembayaran">
                              <Link to="/admin/pembayaran">
                                Pembayaran
                              </Link>
                          </Menu.Item>
                    </SubMenu>
                    <SubMenu
                            key="menupembelian"
                            title={
                              <span>
                                  <ShoppingCartOutlined />
                                  <span>Pembelian</span>
                              </span>
                            }
                    >
                          <Menu.Item key="pembelian">
                              <Link to="/admin/pembelian">
                                Pembelian
                              </Link>
                          </Menu.Item>
                          <Menu.Item key="data_pembelian">
                              <Link to="/admin/pembelian/data">
                                Data Pembelian
                              </Link>
                          </Menu.Item>
                          <Menu.Item key="data_stok">
                              <Link to="/admin/pembelian/stock">
                                Data Stock
                              </Link>
                          </Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="mn_karyawan"
                      title={
                        <span>
                          <UserOutlined />
                          <span>Karyawan</span>
                        </span>
                      }
                    >
                      <Menu.Item key="it_jabatan">
                          <Link to="/admin/jabatan">
                            Jabatan
                          </Link>
                      </Menu.Item>                          
                      <Menu.Item key="it_karyawan">
                          <Link to="/admin/karyawan">
                            Karyawan
                          </Link>
                      </Menu.Item>                          
                    </SubMenu>
                    <SubMenu
                      key="mn_suplier"
                      title={
                        <span>
                          <CarOutlined />
                          <span>Suplier</span>
                        </span>
                      }
                    >
                      <Menu.Item key="it_form_suplier">
                          <Link to="/admin/suplier/form">
                            Tambah Suplier
                          </Link>
                      </Menu.Item>                          
                      <Menu.Item key="it_suplier">
                          <Link to="/admin/suplier">
                            Daftar Suplier
                          </Link>
                      </Menu.Item>                          
                    </SubMenu>
                    <SubMenu
                      key="mn_customer"
                      title={
                        <span>
                          <UsergroupAddOutlined />
                          <span>Pasien</span>
                        </span>
                      }
                    >
                      <Menu.Item key="it_form_customer">
                          <Link to="/admin/pasien/form">
                            Tambah Pasien
                          </Link>
                      </Menu.Item>                          
                      <Menu.Item key="it_customer">
                          <Link to="/admin/pasien">
                            Daftar Pasien
                          </Link>
                      </Menu.Item>                          
                    </SubMenu>
                    <SubMenu
                      key="mn_laporan"
                      title={
                        <span>
                          <SnippetsOutlined />
                          <span>Laporan</span>
                        </span>
                      }
                    >
                      <Menu.Item key="it_laporan">
                          <Link to="/admin/laporan/transaksi">
                            Transaksi
                          </Link>
                      </Menu.Item>                          
                    </SubMenu>
                    
                    {/*
                    <SubMenu
                      key="tempat"
                      title={
                        <span>
                          <HomeOutlined />
                          <span>Tempat Tugas</span>
                        </span>
                      }
                    >
                      <Menu.Item key="tipe">
                        <Link to="/admin/tipe">
                          Tipe
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="tempat_tugas">
                        <Link to="/admin/tempat_tugas">
                          Tempat Tugas
                        </Link>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="tenaga_medis_menu"
                      title={
                        <span>
                          <TeamOutlined />
                          <span>Tenaga Medis</span>
                        </span>
                      }
                    >
                      <Menu.Item key="katgori">
                        <Link to="/admin/kategori">
                          Kategori
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="tenaga_medis">
                        <Link to="/admin/tenaga_medis">
                          Tenaga Medis
                        </Link>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="pasien"
                      title={
                        <span>
                          <SmileOutlined />
                          <span>Pasien</span>
                        </span>
                      }
                    >
                      <Menu.Item key="tambah_pasien">
                        <Link to="/admin/pasien/form">
                          Tambah Pasien
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="data_pasien">
                        <Link to="/admin/pasien">
                          Data Pasien
                        </Link>
                      </Menu.Item>
                    </SubMenu>
                    
                    <SubMenu
                      key="produk"
                      title={
                        <span>
                          <DatabaseOutlined />
                          <span>Produk</span>
                        </span>
                      }
                    >
                      <Menu.Item key="tambah_produk">
                        <Link to="/admin/produk/form">
                          Tambah Produk
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="data_produk">
                        <Link to="/admin/produk">
                          Data Produk
                        </Link>
                      </Menu.Item>
                    </SubMenu>
                  */}
                  </Menu>
              </Sider>
            </PerfectScrollbar>
    )
  }
}

const mapStateToProps = function(state) {
  return state.configReducers
}

export default connect(mapStateToProps, {setConfig, ajaxViewHandler})(AdminSider);
