import React from "react";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Breadcrumb,
  Card,
  Row,
  Col,
  Avatar,
  Form,
  Input,
  Button,
  Space,
  Select,
  DatePicker,
  message,
  Alert,
  Spin,
  Modal,
  Skeleton,
  Table,
  Tabs,
  Result,
} from "antd";
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  BarcodeOutlined,
  UserAddOutlined,
  PrinterOutlined,
  UserOutlined,
  WarningOutlined,
  SaveOutlined,
  FileSearchOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  ShoppingOutlined,
  FileOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import NumberFormat from "react-number-format";

import axios from "axios";
import Config from "../../Config";

import { connect } from "react-redux";
import {
  initHandler,
  ajaxViewHandler,
  ajaxHandler,
  setConfig,
} from "../../store/actions";
import { Helmet } from "react-helmet";

const FormItem = Form.Item;

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    },
  },
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const configClass = {
  title: "form pembelian",
  apiUrl: {
    produk: "master/api/produk",
    suplier: "master/api/suplier/",
    pembelian: "pembelian/api/pembelian/",
    kategori: "master/api/kategori/produk",
    jenis_bayar: "master/api/jenis_bayar/",
    pembelian: "pembelian/api/pembelian/",
  },
  pageUrl: {
    cetak_faktur: "/addon/cetak_faktur/",
  },
  breadCrumb: [
    <span>
      <ShoppingOutlined /> pembelian
    </span>,
    "form pembelian",
  ],
};

const config = new Config();

class FormPembelian extends React.Component {
  formRefPembelian = React.createRef();
  formRefProduk = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      errComponent: false,
      errTitle: "",
      errMsg: "",
      loadingButton: false,

      jenis_layanan: "barcode",

      dataProduk: null,

      loadingProduk: false,
      listProduk: [],

      loadingSuplier: false,
      listSuplier: [],

      loadingKategori: false,
      listKategori: [],

      loadingCarProduk: false,
      cartProduk: [],

      loadingJenisBayar: false,
      listJenisBayar: [],

      loadingBarcodeProduk: false,
      barcodeProdukIsNotFound: false,
      barcodeProdukResults: null,

      totalProduk: 0,
      total_finalProduk: 0,
      barcodeTextProduk: undefined,

      loadingSimpanPembelian: false,

      isFinish: false,

      tempKodeBarcode: null,
      tempIdProduk: null,
      suplier: undefined,

      method: "post",

      modalProduk: false,
      gambar: null,
      harga: 0,
      hpp: 0,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.initHandler();
  }

  componentDidMount() {
    this.loadMasterList();
  }

  loadMasterList() {
    const rule = [
      {
        destination: {
          stateProgressName: "loadingProduk",
          endPoint: configClass.apiUrl.produk + "?tipe=2",
          stateDestination: "listProduk",
        },
        nextDestination: {
          stateProgressName: "loadingSuplier",
          endPoint: configClass.apiUrl.suplier,
          stateDestination: "listSuplier",
          next: null,
        },
      },
      {
        destination: {
          stateProgressName: "loadingKategori",
          endPoint: configClass.apiUrl.kategori,
          stateDestination: "listKategori",
        },
        nextDestination: {
          stateProgressName: "loadingJenisBayar",
          endPoint: configClass.apiUrl.jenis_bayar,
          stateDestination: "listJenisBayar",
          next: null,
        },
      },
    ];

    this.setState({
      errComponent: false,
    });

    rule.map((item, index) => {
      if (index == rule.length - 1) {
        this.getMasterList(item, true);
      } else {
        this.getMasterList(item);
      }
    });
  }

  setGambar = (e) => {
    this.setState({
      gambar: e.target.files[0],
    });
  };

  async getMasterList(rule, isFinal = false) {
    var destination = rule.destination;
    var nextDestination = rule.nextDestination;
    this.setState({ [destination.stateProgressName]: true });
    this.props
      .ajaxViewHandler("get", destination.endPoint)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.count != undefined) {
            let newState = null;
            if (this.state[destination.stateDestination].length == 0) {
              newState = data;
            } else {
              if (destination.stateProgressName.includes("_searching")) {
                newState = data;
              } else {
                const oldState = this.state[destination.stateDestination];
                newState = {
                  count: data.count,
                  next: data.next,
                  previous: data.previous,
                  results: [...oldState.results, ...data.results],
                };
              }
            }
            this.setState({
              [destination.stateDestination]: newState,
            });
          } else if (data.results != undefined) {
            this.setState({
              [destination.stateDestination]: data.results,
            });
          } else {
            if (Array.isArray(data)) {
              this.setState({
                [destination.stateDestination]: data,
              });
            } else {
              this.setState({
                [destination.stateDestination]: [data],
              });
            }
          }
        } else {
          this.setState({
            errComponent: true,
          });
        }
        this.setState({ [destination.stateProgressName]: false });
      })
      .catch((response) => {
        console.log("gagal_get_master", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({
          errTitle: "gagal request data master",
          errMsg: response,
        });
        this.setState({ [destination.stateProgressName]: false });
      });
    //next function
    if (nextDestination != null) {
      const next = {
        destination: {
          stateProgressName: nextDestination.stateProgressName,
          endPoint: nextDestination.endPoint,
          stateDestination: nextDestination.stateDestination,
        },
        nextDestination: nextDestination.next,
      };
      this.getMasterList(next);
    }
  }

  generateSelect(stateName, url, key, value) {
    return this.state["loading" + stateName] ? (
      <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
    ) : (
      <Select
        showSearch
        placeholder="pilih"
        optionFilterProp="children"
        onSearch={(val) => {
          const rule = {
            destination: {
              stateProgressName: "loading" + stateName + "_searching",
              endPoint: url + "?search=" + val,
              stateDestination: "list" + stateName,
            },
            nextDestination: null,
          };
          this.getMasterList(rule);
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (
              this.state["list" + stateName].next != undefined &&
              this.state["list" + stateName].next != null
            ) {
              const rule = {
                destination: {
                  stateProgressName: "loading" + stateName + "Paging",
                  endPoint: this.state["list" + stateName].next,
                  stateDestination: "list" + stateName,
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            }
          }
        }}
        allowClear
      >
        {this.state["loading_" + stateName + "_searching"] && (
          <Select.Option value="">
            <Spin size="small" />
          </Select.Option>
        )}
        {this.state["list" + stateName].count != undefined
          ? this.state["list" + stateName].results.map((item) => (
              <Select.Option value={item[key]}>
                {item[key] + " - " + item[value] + ""}
              </Select.Option>
            ))
          : this.state["list" + stateName].map((item) => (
              <Select.Option value={item[key]}>
                {item[key] + " - " + item[value] + ""}
              </Select.Option>
            ))}
        {this.state["list" + stateName] != undefined &&
          this.state["list" + stateName].next != undefined && (
            <Select.Option value="">
              <Spin size="small" />
            </Select.Option>
          )}
      </Select>
    );
  }

  generateSelectProdukProduk(stateName, url, key, value) {
    return this.state["loading" + stateName] ? (
      <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
    ) : (
      <Select
        autoFocus
        showSearch
        placeholder={"cari / pilih " + stateName.toLowerCase()}
        optionFilterProp="children"
        onSearch={(val) => {
          const rule = {
            destination: {
              stateProgressName: "loading" + stateName + "_searching",
              endPoint: url + "&search=" + val,
              stateDestination: "list" + stateName,
            },
            nextDestination: null,
          };
          this.getMasterList(rule);
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (
              this.state["list" + stateName].next != undefined &&
              this.state["list" + stateName].next != null
            ) {
              const rule = {
                destination: {
                  stateProgressName: "loading" + stateName + "Paging",
                  endPoint: this.state["list" + stateName].next,
                  stateDestination: "list" + stateName,
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            }
          }
        }}
        allowClear
        style={{
          width: "100%",
        }}
        size={"large"}
        onChange={(val) => {
          if (stateName == "Produk") {
            this.pilihProduk(val);
          }
        }}
      >
        {this.state["loading" + stateName + "_searching"] == true && (
          <Select.Option value="">
            <Spin size="small" />
          </Select.Option>
        )}
        {this.state["list" + stateName].count != undefined
          ? this.state["list" + stateName].results.map((item) => (
              <Select.Option value={item[key] + ""}>
                {item["barcode"] + " - " + item[value]}
              </Select.Option>
            ))
          : this.state["list" + stateName].map((item) => (
              <Select.Option value={item[key] + ""}>
                {item["barcode"] + " - " + item[value]}
              </Select.Option>
            ))}
        {this.state["list" + stateName] != undefined &&
          this.state["list" + stateName].next != undefined && (
            <Select.Option value="">
              <Spin size="small" />
            </Select.Option>
          )}
      </Select>
    );
  }

  generateSelectPembayaran(stateName, url, key, value) {
    return this.state["loading" + stateName] ? (
      <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
    ) : (
      <Select
        autoFocus
        showSearch
        placeholder="pilih metode pembayaran"
        optionFilterProp="children"
        onSearch={(val) => {
          const rule = {
            destination: {
              stateProgressName: "loading" + stateName + "_searching",
              endPoint: url + "?search=" + val,
              stateDestination: "list" + stateName,
            },
            nextDestination: null,
          };
          this.getMasterList(rule);
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (
              this.state["list" + stateName].next != undefined &&
              this.state["list" + stateName].next != null
            ) {
              const rule = {
                destination: {
                  stateProgressName: "loading" + stateName + "Paging",
                  endPoint: this.state["list" + stateName].next,
                  stateDestination: "list" + stateName,
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            }
          }
        }}
        allowClear
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        {this.state["loading" + stateName + "_searching"] == true && (
          <Select.Option value="">
            <Spin size="small" />
          </Select.Option>
        )}
        {this.state["list" + stateName].count != undefined
          ? this.state["list" + stateName].results.map((item) => (
              <Select.Option value={item[key] + ""}>
                {item[key] + " - " + item[value]}
              </Select.Option>
            ))
          : this.state["list" + stateName].map((item) => (
              <Select.Option value={item[key] + ""}>
                {item[key] + " - " + item[value]}
              </Select.Option>
            ))}
        {this.state["list" + stateName] != undefined &&
          this.state["list" + stateName].next != undefined && (
            <Select.Option value="">
              <Spin size="small" />
            </Select.Option>
          )}
      </Select>
    );
  }

  async scanProduk(barcode) {
    // this.setState({loadingBarcode: true});
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.produk + "?barcode=" + barcode)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.results.length > 0) {
            this.setState(
              {
                barcodeProdukIsNotFound: false,
                barcodeProdukResults: data.results,
                loadingBarcodeProduk: false,
              },
              () => {
                let temp_cart = this.state.cartProduk;
                // let temp_label_produk = this.state.label_produk;
                let that = this;
                if (temp_cart.length == 0) {
                  let produk = this.state.barcodeProdukResults[0];
                  // let hpp = 0;
                  // let diskon_rupiah = 0;
                  // if(produk['diskon']!="" && produk['diskon'].rupiah>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty){
                  // 	console.log('tes_produk', produk);
                  // 	// console.log('tes_diskon_rupiah', produk['diskon'].rupiah)
                  // 	// diskon_rupiah = produk['diskon'].rupiah;
                  // }

                  temp_cart.push({
                    id: produk["id"],
                    nama: produk["nama"],
                    hpp: produk["hpp"],
                    qty: 1,
                  });
                  // temp_label_produk = produk['nama']+' '+hpp.toLocaleString('en-US');
                } else {
                  const index_cari = temp_cart.findIndex(
                    (data) => data.id == this.state.barcodeProdukResults[0].id
                  );
                  if (index_cari == -1) {
                    let produk = this.state.barcodeProdukResults[0];
                    // let hpp = 0;

                    temp_cart.push({
                      id: produk["id"],
                      nama: produk["nama"],
                      hpp: produk["hpp"],
                      qty: 1,
                    });
                    // temp_label_produk = produk['nama']+' '+hpp.toLocaleString('en-US');
                  } else {
                    let produk = this.state.barcodeProdukResults[0];
                    // let hpp = 0;

                    // temp_label_produk = produk['nama']+' '+hpp.toLocaleString('en-US')+' x '+(temp_cart[index_cari]['qty']+1);
                    temp_cart[index_cari]["qty"] =
                      temp_cart[index_cari]["qty"] + 1;
                  }
                }
                this.setState(
                  {
                    cartProduk: temp_cart,
                    // label_produk: temp_label_produk
                  },
                  () => {
                    // console.log('cart', this.state.cart)
                  }
                );
                var temp_total = 0;
                this.state.cartProduk.map((item) => {
                  temp_total += item["hpp"] * item["qty"];
                });
                this.setState({
                  totalProduk: temp_total,
                  total_finalProduk: temp_total,
                  barcodeTextProduk: "",
                });
              }
            );
            this.resetToAdd();
          } else {
            this.setState({
              barcodeProdukIsNotFound: true,
              barcodeProdukResults: null,
              loadingBarcodeProduk: false,
              barcodeTextProduk: "",
            });
            message.error({
              content: "produk tidak ditemukan",
              duration: 2,
              key: "toast",
            });
            // this.resetToAdd();
          }
          // this.barcodeRef.current.val = undefined;
        } else {
          const errTitle = "error get data";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
            loadingBarcodeProduk: false,
            barcodeProdukIsNotFound: false,
            barcodeTextProduk: "",
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.resetToAdd();
        }
        this.setState({ loadingProduk: false });
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({ loadingBarcode: false });
        this.resetToAdd();
      });
  }

  async pilihProduk(id) {
    // this.setState({loadingBarcode: true});
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler("get", configClass.apiUrl.produk + "/" + id)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data != undefined) {
            this.setState(
              {
                barcodeProdukIsNotFound: false,
                barcodeProdukResults: data,
                loadingBarcodeProduk: false,
              },
              () => {
                let temp_cart = this.state.cartProduk;
                // let temp_label_produk = this.state.label_produk;
                let that = this;
                if (temp_cart.length == 0) {
                  let produk = this.state.barcodeProdukResults;
                  // let hpp = 0;
                  // let diskon_rupiah = 0;
                  // if(produk['diskon']!="" && produk['diskon'].rupiah>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty){
                  // 	console.log('tes_produk', produk);
                  // 	// console.log('tes_diskon_rupiah', produk['diskon'].rupiah)
                  // 	// diskon_rupiah = produk['diskon'].rupiah;
                  // }

                  temp_cart.push({
                    id: produk["id"],
                    nama: produk["nama"],
                    hpp: produk["hpp"],
                    qty: 1,
                  });
                  // temp_label_produk = produk['nama']+' '+hpp.toLocaleString('en-US');
                } else {
                  const index_cari = temp_cart.findIndex(
                    (data) => data.id == this.state.barcodeProdukResults.id
                  );
                  if (index_cari == -1) {
                    let produk = this.state.barcodeProdukResults;
                    // let hpp = 0;

                    temp_cart.push({
                      id: produk["id"],
                      nama: produk["nama"],
                      hpp: produk["hpp"],
                      qty: 1,
                    });
                    // temp_label_produk = produk['nama']+' '+hpp.toLocaleString('en-US');
                  } else {
                    let produk = this.state.barcodeProdukResults;
                    // let hpp = 0;

                    // temp_label_produk = produk['nama']+' '+hpp.toLocaleString('en-US')+' x '+(temp_cart[index_cari]['qty']+1);
                    temp_cart[index_cari]["qty"] =
                      temp_cart[index_cari]["qty"] + 1;
                  }
                }
                this.setState(
                  {
                    cartProduk: temp_cart,
                    // label_produk: temp_label_produk
                  },
                  () => {
                    // console.log('cart', this.state.cart)
                  }
                );
                var temp_total = 0;
                this.state.cartProduk.map((item) => {
                  temp_total += item["hpp"] * item["qty"];
                });
                this.setState(
                  {
                    totalProduk: temp_total,
                    total_finalProduk: temp_total,
                    barcodeTextProduk: "",
                  },
                  () => {
                    this.formRefPembelian.current.setFieldsValue({
                      produks: undefined,
                    });
                  }
                );
              }
            );
          } else {
            this.setState({
              barcodeProdukIsNotFound: true,
              barcodeProdukResults: null,
              loadingBarcodeProduk: false,
              barcodeTextProduk: "",
            });
          }
          // this.barcodeRef.current.val = undefined;
          this.resetToAdd();
        } else {
          const errTitle = "error get data";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
            loadingBarcodeProduk: false,
            barcodeProdukIsNotFound: false,
            barcodeTextProduk: "",
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
        this.setState({ loadingProduk: false });
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({ loadingBarcode: false });
      });
  }

  removeProduk(id, nama) {
    Modal.confirm({
      title: "Konfirmasi Hapus?",
      icon: <ExclamationCircleOutlined />,
      content: "yakin menghapus item : " + nama + "?",
      okText: "Ya",
      okType: "danger",
      cancelText: "Tidak",
      onOk: () => this.prosesHapusItem(id),
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  prosesHapusItem(id) {
    const temp_cart = this.state.cartProduk;
    const index_cari = temp_cart.findIndex((data) => data.id == id);
    temp_cart.splice(index_cari, 1);

    this.setState({
      cartProduk: temp_cart,
    });
    var temp_total = 0;
    this.state.cartProduk.map((item) => {
      // temp_total += (item['hpp']*item['qty']);
      temp_total += parseFloat(item["hpp"]) * parseFloat(item["qty"]);
    });
    this.setState({
      totalProduk: temp_total,
      total_finalProduk: temp_total,
    });
  }

  setQtyProduk(e, id) {
    const temp_cart = this.state.cartProduk;
    const index_cari = temp_cart.findIndex((data) => data.id == id);
    if (e.target.value == "0") {
      temp_cart.splice(index_cari, 1);
    } else {
      temp_cart[index_cari]["qty"] = parseFloat(e.target.value);
    }
    this.setState({
      cartProduk: temp_cart,
    });
    var temp_total = 0;
    this.state.cartProduk.map((item) => {
      // temp_total += (item['hpp']*item['qty']);
      temp_total += parseFloat(item["hpp"]) * parseFloat(item["qty"]);
    });
    this.setState({
      totalProduk: temp_total,
      total_finalProduk: temp_total,
    });
  }

  setHargaProduk(val, id) {
    const temp_cart = this.state.cartProduk;
    const index_cari = temp_cart.findIndex((data) => data.id == id);
    if (val != "") {
      temp_cart[index_cari]["hpp"] = parseFloat(val);
    }
    this.setState({
      cartProduk: temp_cart,
    });
    var temp_total = 0;
    this.state.cartProduk.map((item) => {
      // temp_total += (item['hpp']*item['qty']);
      temp_total += parseFloat(item["hpp"]) * parseFloat(item["qty"]);
    });
    this.setState({
      totalProduk: temp_total,
      total_finalProduk: temp_total,
    });
  }

  handleSubmitProduk = (values) => {
    var datas = new FormData();
    for (var key in values) {
      if (values[key] == undefined) {
        datas.append(key, "");
      } else {
        datas.append(key, values[key].toString());
      }
    }
    if(this.state.suplier != undefined){
    	datas.append("suplier", this.state.suplier);
    }
    datas.append("harga", this.state.harga);
    datas.append("hpp", this.state.hpp);
    datas.append("tipe", 2);
    if (this.state.gambar != null) {
      datas.append("gambar", this.state.gambar, this.state.gambar.name);
    } else {
      datas.append("gambar", "");
    }
    this.postDataProduk(datas);
  };

  async postDataProduk(datas) {
    message.loading({ content: "Memproses...", duration: 0, key: "toast" });
    this.setState({
      errTitle: "",
      errMsg: "",
    });
    this.props
      .ajaxHandler("post", configClass.apiUrl.produk + "/", datas, true, true)
      .then(() => {
        this.setState({
          loadingButton: false,
        });
        if (this.props.isSuccess) {
          message.success({
            content: "berhasil menyimpan data produk",
            duration: 2,
            key: "toast",
          });
          var response = this.props.responseMsg;
          this.formRefPembelian.current.setFieldsValue({
            produks: response.id + "",
          });
          //preview yang berbentuk list
          const rule = [
            {
              destination: {
                stateProgressName: "loadingProduk_searching",
                endPoint: configClass.apiUrl.produk + "/" + response.id,
                stateDestination: "listProduk",
              },
              nextDestination: null,
            },
          ];

          this.setState({
            errComponent: false,
            modalProduk: false,
          });
          rule.map((item, index) => {
            this.getMasterList(item);
          });
          this.resetProduk();
          this.getProdukById(response.id);
        } else {
          const errTitle = "gagal menyimpan produk";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post", response);
        this.setState({
          loadingButton: false,
        });
        message.error({
          content: "kesalahan pengiriman data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  async getProdukById(val) {
    message.loading({
      content: "Display data produk...",
      duration: 0,
      key: "toast",
    });
    this.setState({
      errTitle: "",
      errMsg: "",
      dataProduk: null,
    });
    this.props
      .ajaxViewHandler("get", configClass.apiUrl.produk + "/" + val)
      .then(() => {
        this.setState({
          loadingProduk: false,
        });
        if (this.props.isSuccess) {
          message.destroy();

          var response = this.props.responseMsg;
          this.setState(
            {
              dataProduk: response,
            },
            () => {
              this.pilihProduk(val);
            }
          );
        } else {
          const errTitle = "gagal menampilkan data produk";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_preview_produk", response);
        this.setState({
          skeletonPasien: false,
        });
        message.error({
          content: "kesalahan pengiriman data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  resetProduk() {
    if (
      this.formRefProduk != undefined &&
      this.formRefProduk.current != undefined
    ) {
      this.formRefProduk.current.resetFields();
    }
  }

  handleSubmitPembelian = (values) => {
    values = { ...values, total_harga: this.state.total_finalProduk };
    var detail = [];
    this.state.cartProduk.map((item) => {
      detail = [
        ...detail,
        {
          produk: item.id,
          quantity: item.qty,
          harga: item.hpp
        },
      ];
    });
    values = { ...values, detail };
    this.postDataPembelian(values);
  };

  async postDataPembelian(datas) {
    this.setState({
      errTitle: "",
      errMsg: "",
      loadingSimpanPembelian: true,
    });

    message.loading({ content: "Memproses...", duration: 0, key: "toast" });

    var that = this;

    this.props
      .ajaxHandler(
        this.state.method,
        configClass.apiUrl.pembelian,
        datas,
        false,
        true
      )
      .then(() => {
        this.setState({
          loadingSimpanPembelian: false,
        });
        if (this.props.isSuccess) {
          message.success({
            content: "berhasil menyimpan data",
            duration: 2,
            key: "toast",
          });
          this.setState({
            isFinish: true,
          });
          var response = this.props.responseMsg;
          var that = this;
          Modal.confirm({
            title: "Berhasil Menyimpan",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            content:
              "tekan cetak untuk mencetak, tekan selesai untuk melanjutkan",
            okText: (
              <a
                href={configClass.pageUrl.cetak_faktur + response.id}
                target="_blank"
              >
                <PrinterOutlined /> Cetak
              </a>
            ),
            cancelText: "Selesai",
            onOk() {
              that.reset()
            },
            onCancel() {
              that.reset()
            },
          });
        } else {
          const errTitle = "gagal menyimpan data";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post", response);
        this.setState({
          loadingSimpanPembelian: false,
        });
        message.error({
          content: "kesalahan pengiriman data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  resetToAdd() {
    this.formRefPembelian.current.setFieldsValue({
      barcode: undefined,
      produks: undefined,
    });
    this.setState({
      tempKodeBarcode: null,
      tempIdProduk: null,
    });
  }

  reset() {
    this.setState({
      cartProduk: [],
      barcodeProdukIsNotFound: false,
      barcodeProdukResults: null,
      totalProduk: 0,
      total_finalProduk: 0,
      barcodeTextProduk: undefined,
      suplier: undefined,
      jenis_layanan: "barcode",
    });
    this.formRefPembelian.current.resetFields();
  }

  showModalProduk() {
    this.setState({
      modalProduk: true,
    });
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>
            {configClass.title + " | " + config.get_site_info().name}
          </title>
        </Helmet>
        <Breadcrumb>
          {configClass.breadCrumb.map((item) => (
            <Breadcrumb.Item>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className="card">
          <Form
            layout="vertical"
            name="fpembelian"
            onFinish={this.handleSubmitPembelian}
            size="small"
            ref={this.formRefPembelian}
            initialValues={{
              jenis_layanan: this.state.jenis_layanan,
              payment_type: "1",
            }}
          >
            <Row className="isi">
              <Col lg={24} sm={24} xs={24}>
                <Card
                  className="konten"
                  title={
                    <span className="konten-judul">
                      {configClass.title}
                      {this.state.loadingButton && (
                        <img
                          src={process.env.PUBLIC_URL + "/loading_crop.gif"}
                          height="50"
                        />
                      )}
                    </span>
                  }
                >
                  {this.state.errMsg != "" && (
                    <Alert
                      message={this.state.errTitle}
                      description={JSON.stringify(this.state.errMsg)}
                      type="error"
                      showIcon
                      style={{
                        marginBottom: 10,
                      }}
                    />
                  )}
                  <Row>
                    <Col
                      span={12}
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        fontStyle: "italic",
                        textAlign: "left",
                        marginBottom: 10,
                        color: "blue",
                      }}
                    >
                      {this.state.barcodeProdukResults != null
                        ? this.state.jenis_layanan == "barcode" &&
                          this.state.barcodeProdukResults != null &&
                          this.state.barcodeProdukResults.length > 0
                          ? this.state.barcodeProdukResults[0].nama
                          : this.state.barcodeProdukResults.nama
                        : ""}
                    </Col>
                    <Col
                      span={12}
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        textAlign: "right",
                        marginBottom: 10,
                      }}
                    >
                      {"Total Rp. " +
                        parseFloat(this.state.total_finalProduk).toLocaleString(
                          "en-us"
                        )}
                    </Col>
                    <Col span={24}>
                      <Input.Group compact>
                        <Form.Item
                          name="jenis_layanan"
                          style={{ width: "15%" }}
                        >
                          <Select
                            showSearch
                            placeholder="pilih jenis"
                            optionFilterProp="children"
                            style={{
                              width: "100%",
                            }}
                            onChange={(val) => {
                              this.setState(
                                {
                                  jenis_layanan: val,
                                },
                                () => {
                                  this.resetToAdd();
                                  if (val == "produk baru") {
                                    this.showModalProduk();
                                  }
                                }
                              );
                            }}
                            size={"large"}
                          >
                            <Select.Option value={"barcode"}>
                              <BarcodeOutlined /> barcode
                            </Select.Option>
                            <Select.Option value={"produk"}>
                              <FileOutlined /> produk
                            </Select.Option>
                            <Select.Option value={"produk baru"}>
                              <FileAddOutlined /> produk baru
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        {this.state.jenis_layanan == "barcode" && (
                          <Form.Item name="barcode" style={{ width: "75%" }}>
                            <Input
                              onChange={(e) => {
                                this.setState({
                                  tempKodeBarcode: e.target.value,
                                });
                              }}
                              autoFocus
                              size="large"
                              placeholder="scan barcode disini"
                              className="penjualan-cart-barcode"
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  var val = event.target.value;
                                  this.scanProduk(val);
                                }
                              }}
                              style={{ textTransform: "none" }}
                            />
                          </Form.Item>
                        )}
                        {(this.state.jenis_layanan == "produk" ||
                          this.state.jenis_layanan == "produk baru") && (
                          <Form.Item name="produks" style={{ width: "75%" }}>
                            {this.generateSelectProdukProduk(
                              "Produk",
                              configClass.apiUrl.produk + "?tipe=2",
                              "id",
                              "nama"
                            )}
                          </Form.Item>
                        )}
                        <Button
                          style={{ width: "10%" }}
                          key="btn-simpan-layanan"
                          loading={this.state.loadingSimpanPembelian}
                          type="primary"
                          htmlType="submit"
                          disabled={
                            this.state.cartProduk.length == 0 ||
                            this.state.isFinish == true
                              ? true
                              : false
                          }
                          size="large"
                        >
                          {!this.state.loadingSimpanPembelian && (
                            <SaveOutlined />
                          )}
                          Simpan
                        </Button>
                      </Input.Group>
                    </Col>
                  </Row>
                  <Row style={{ height: 48 }}>
                    <Col span={5} style={{ padding: 5 }}>
                      Suplier
                    </Col>
                    <Col span={19} style={{ padding: 5 }}>
                      <Form.Item
                        name="suplier"
                        rules={[
                          {
                            required: true,
                            message: "harus dipilih",
                          },
                        ]}
                      >
                        {this.generateSelect(
                          "Suplier",
                          configClass.apiUrl.suplier,
                          "id",
                          "nama"
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ height: 48 }}>
                    <Col span={5} style={{ padding: 5 }}>
                      Jenis Pembayaran
                    </Col>
                    <Col span={7} style={{ padding: 5 }}>
                      <Form.Item
                        name="payment_type"
                        rules={[
                          {
                            required: true,
                            message: "harus dipilih",
                          },
                        ]}
                      >
                        {this.generateSelectPembayaran(
                          "JenisBayar",
                          configClass.apiUrl.jenis_bayar,
                          "id",
                          "nama"
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={5} style={{ padding: 5 }}>
                      No Kartu / Akun
                    </Col>
                    <Col span={7} style={{ padding: 5 }}>
                      <Form.Item name="approval_code">
                        <Input
                          placeholder="no kartu / akun payment"
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ height: 48 }}>
                    <Col span={5} style={{ padding: 5 }}>
                      Dibayar
                    </Col>
                    <Col span={7} style={{ padding: 5 }}>
                      <Form.Item
                        label=""
                        name="preview_dibayar"
                        rules={[
                          {
                            required: true,
                            message: "harus diisi",
                          },
                        ]}
                      >
                        <NumberFormat
                          style={{ textTransform: "none", height: 38 }}
                          autoComplete="off"
                          thousandSeparator={true}
                          prefix={"Rp. "}
                          className="ant-input ant-input-sm"
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            this.formRefPembelian.current.setFieldsValue({
                              total_bayar: value,
                            });
                            var kembali = value - this.state.total_finalProduk;

                            this.setState({
                              kembali: kembali,
                            });

                            this.formRefPembelian.current.setFieldsValue({
                              preview_kembali:
                                "Rp. " +
                                parseFloat(kembali).toLocaleString("en-US"),
                            });
                          }}
                        />
                      </Form.Item>
                      <Form.Item name="total_bayar" style={{ display: "none" }}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={5} style={{ padding: 5 }}>
                      {this.state.kembali < 0 ? "Kekurangan" : "Kembali"}
                    </Col>
                    <Col span={7} style={{ padding: 5 }}>
                      <Form.Item label="" name="preview_kembali">
                        <Input
                          readOnly
                          style={{
                            textTransform: "none",
                            fontWeight: "bold",
                          }}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {this.state.kembali < 0 && (
                    <Row style={{ height: 48 }}>
                      <Col span={5} style={{ padding: 5 }}>
                        Jatuh Tempo
                      </Col>
                      <Col span={7} style={{ padding: 5 }}>
                        <Form.Item
                          label=""
                          name="jatuh_tempo_preview"
                          rules={[
                            {
                              required: true,
                              message: "harus diisi",
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: "100%", height: 38 }}
                            placeholder="tanggal lahir (dd-mm-yyyy)"
                            format="DD-MM-YYYY"
                            onChange={(date, dateString) => {
                              this.formRefPembelian.current.setFieldsValue({
                                jatuh_tempo: dateString,
                              });
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="jatuh_tempo"
                          style={{
                            display: "none",
                          }}
                        >
                          <Input type="hidden" />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <PerfectScrollbar className="panel-cart">
                    <Row
                      style={{
                        borderBottom: "1px solid #cccccc",
                        padding: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <Col span={3}>DEL</Col>
                      <Col span={4}>QTY</Col>
                      <Col span={9}>NAMA PRODUK</Col>
                      <Col span={4}>@HARGA</Col>
                      <Col span={4}>SUB TOTAL</Col>
                    </Row>
                    {this.state.cartProduk.length == 0 ? (
                      <Result
                        icon={<WarningOutlined style={{ color: "#f9af4d" }} />}
                        title={
                          <span style={{ color: "#f9af4d" }}>
                            belum ada produk dipilih!
                          </span>
                        }
                      />
                    ) : (
                      this.state.cartProduk.map((item, idx) => (
                        <Row
                          style={{
                            borderBottom: "1px solid #cccccc",
                            padding: 10,
                          }}
                        >
                          <Col span={3}>
                            <Button
                              disabled={
                                this.state.isFinish == true ? true : false
                              }
                              size="large"
                              className="btn-delete"
                              type="text"
                              onClick={(e) =>
                                this.removeProduk(item.id, item.nama)
                              }
                            >
                              <DeleteOutlined />
                            </Button>
                          </Col>
                          <Col span={4}>
                            <Input
                              size="large"
                              value={item.qty}
                              style={{
                                width: 40,
                                display: "inline-block",
                              }}
                              onChange={(e) => this.setQtyProduk(e, item.id)}
                              disabled={
                                this.state.isFinish == true ? true : false
                              }
                            />
                          </Col>
                          <Col span={9}>{item.nama}</Col>
                          <Col span={4}>
                             <NumberFormat
                             	value={item.hpp}
		                          style={{ textTransform: "none", height: 38 }}
		                          autoComplete="off"
		                          thousandSeparator={true}
		                          prefix={"Rp. "}
		                          className="ant-input ant-input-sm"
		                          onValueChange={(values) => {
		                            const { formattedValue, value } = values;
		                            this.setHargaProduk(value, item.id)
		                          }}
		                        />
                          </Col>
                          <Col span={4}>
                            {"Rp. " +
                              parseFloat(item.hpp * item.qty).toLocaleString(
                                "en-us"
                              )}
                          </Col>
                        </Row>
                      ))
                    )}
                  </PerfectScrollbar>
                </Card>
              </Col>
            </Row>
          </Form>
        </div>
        <Modal
          autoFocus={false}
          closable={true}
          visible={this.state.modalProduk}
          title="TAMAH PRODUK"
          onCancel={() => {
            this.setState({
              modalProduk: false,
            });
          }}
          footer={[]}
          width={800}
        >
          <Form
            {...layout}
            name="fdata"
            onFinish={this.handleSubmitProduk}
            size="small"
            ref={this.formRefProduk}
          >
            {/*data dasar*/}

            {this.state.errMsg != "" && (
              <Alert
                message={this.state.errTitle}
                description={JSON.stringify(this.state.errMsg)}
                type="error"
                showIcon
                style={{
                  marginBottom: 10,
                }}
              />
            )}

            <Form.Item
              label="KATEGORI"
              name="kategori"
              rules={[
                {
                  required: true,
                  message: "harus diisi",
                },
              ]}
            >
              {this.generateSelect(
                "Kategori",
                configClass.apiUrl.kategori,
                "id",
                "nama"
              )}
            </Form.Item>
            <Form.Item
              label="NAMA PRODUK"
              name="nama"
              rules={[
                {
                  required: true,
                  message: "harus diisi",
                },
              ]}
            >
              <Input
                style={{ textTransform: "none" }}
                placeholder="input nama"
              />
            </Form.Item>
            <Form.Item
              label="JENIS STOK"
              name="jenis_stock"
              rules={[
                {
                  required: true,
                  message: "harus diisi",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="pilih"
                optionFilterProp="children"
              >
                <Select.Option value="1">Active</Select.Option>
                <Select.Option value="2">Non Active</Select.Option>
                <Select.Option value="3">Bahan Baku</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="BARCODE / SKU"
              name="barcode"
              rules={[
                {
                  required: true,
                  message: "harus diisi",
                },
              ]}
            >
              <Input
                style={{ textTransform: "lowercase" }}
                placeholder="input barcode / sku"
              />
            </Form.Item>
            <Form.Item
              label="SATUAN"
              name="satuan"
              rules={[
                {
                  required: true,
                  message: "harus diisi",
                },
              ]}
            >
              <Input
                style={{ textTransform: "none" }}
                placeholder="input satuan"
              />
            </Form.Item>
            <Form.Item
              label="FOTO"
              name="gambar"
              rules={[
                {
                  required: true,
                  message: "harus diisi",
                },
              ]}
            >
              <Input
                accept="image/png, image/jpeg"
                type="file"
                placeholder="pilih gambar"
                onChange={this.setGambar}
              />
            </Form.Item>
            <Form.Item
              label="HPP"
              name="hpp_view"
              rules={[
                {
                  required: true,
                  message: "harus diisi",
                },
              ]}
            >
              <NumberFormat
                style={{ textTransform: "none" }}
                autoComplete="off"
                thousandSeparator={true}
                prefix={"Rp. "}
                className="ant-input ant-input-sm"
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  this.setState({
                    hpp: parseFloat(value),
                  });
                  // console.log('hpp', values);
                }}
              />
            </Form.Item>
            <Form.Item
              label="HARGA"
              name="harga_view"
              rules={[
                {
                  required: true,
                  message: "harus diisi",
                },
              ]}
            >
              <NumberFormat
                style={{ textTransform: "none" }}
                autoComplete="off"
                thousandSeparator={true}
                prefix={"Rp. "}
                className="ant-input ant-input-sm"
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  this.setState({
                    harga: parseFloat(value),
                  });
                  // console.log('hpp', values);
                }}
              />
            </Form.Item>

            <Row justify="end">
              <Col style={{ padding: 5 }}>
                <Button
                  key="btn-simpan"
                  loading={this.state.loading}
                  type="primary"
                  htmlType="submit"
                  disabled={this.state.errComponent ? true : false}
                  size="small"
                >
                  {!this.state.loading && <CheckCircleOutlined />}
                  Simpan
                </Button>
              </Col>
              <Col style={{ padding: 5 }}>
                <Button
                  type="danger"
                  key="btn-cancel"
                  disabled={this.state.loading ? true : false}
                  size="small"
                  onClick={() => {
                    this.setState({
                      modalProduk: false,
                    });
                  }}
                >
                  <CloseCircleOutlined /> Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  initHandler,
  setConfig,
  ajaxViewHandler,
  ajaxHandler,
})(FormPembelian);
