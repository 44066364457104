import React from 'react';
import { Link, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Avatar,
	Form, 
	Input,
	Button,
	Space,
	Select,
	DatePicker,
	message,
	Alert,
	Spin,
	Modal, 
	Skeleton,
	Table,
	Tabs,
	Result
} from 'antd';
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  BarcodeOutlined,
  UserAddOutlined,
  PrinterOutlined,
  UserOutlined,
  WarningOutlined,
  SaveOutlined,
  FileSearchOutlined
} from '@ant-design/icons';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import moment from 'moment';
import NumberFormat from 'react-number-format';

import axios from 'axios';
import Config from '../../Config';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import {Helmet} from "react-helmet";

const FormItem = Form.Item

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    }
  },
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const configClass = {
	title: 'form layanan',
	apiUrl: {
		rekam_medis: 'pasien/api/rekam/medis/',
		dokter: 'karyawan/api/karyawan/',
		diagnosa: 'master/api/diagnosa'
	},
}

const config = new Config();

class RekamMedis extends React.Component {
	formRef = React.createRef();

	constructor(props){
		super(props);

		this.state = {
			errComponent: false,
			errTitle: '',
			errMsg: '',
			loadingButton: false,

			loadingDataRm: false,
			paginationRm: {pageSize: 25},
			searchTextRm: '',
			dataSourceRm: [],
			columnsRm: [
				{
					title: 'TGL',
					width: 150,
					dataIndex: 'tanggal',
					key: 'tanggal',
					render: (value, row, index) => {
						return moment(value).format('DD-MM-YYYY hh:mm:ss')
					}
				},
				{
					title: 'DAIGNOSA',
					width: 150,
					dataIndex: 'diagnosa',
					key: 'diagnosa',
					render: (value, row, index) => {
						// return value;//.replace(/(?:\r\n|\r|\n)/g, '<br>');
						// return <div style={{scroll: 'auto'}}>{value}</div>
						return value.substring(0, 30)+'...';
					}
				},
				{
					title: 'LIHAT DETAIL',
					width: 150,
					dataIndex: 'id',
					key: 'aksi',
					fixed: 'right',
					render: (value, row, index) => {
						return (
							<Row key={"space-aksi-"+index} justify="center">
								<Col style={{padding: 5}}>
									<Button className="btn-default" size="large" onClick={() => this.showDetail(row)} shape="circle">
										<FileSearchOutlined />
									</Button>
								</Col>
							</Row>
						)
					},
				}

			],
			modalDetail: false,
			detail: null,
			detail_obat: null,
			detail_tindakan: null,

			loadingDokter: false,
			listDokter: [],

			loadingDiagnosa: false,
			listDiagnosa: [],
			diagnosa: null,

		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.getRiwayatRm();
		this.loadMasterList();		
	}

	componentDidUpdate(prevProps) {
		// console.log('prevprops', prevProps.transaksi);
		// console.log('newProps', this.props.transaksi);
		// alert(prevProps.transaksi);
		// console.log('prev', JSON.stringify(prevProps.transaksi));
		// console.log('now', JSON.stringify(this.props.transaksi));
		if(JSON.stringify(prevProps.transaksi) != JSON.stringify(this.props.transaksi)){
			this.getRiwayatRm();
		}
	}

	loadMasterList(){
		const rule = [
			{
				destination: {
					stateProgressName: 'loadingDokter',
					endPoint: configClass.apiUrl.dokter,
					stateDestination: 'listDokter',
				},
				nextDestination: {
					stateProgressName: 'loadingDiagnosa',
					endPoint: configClass.apiUrl.diagnosa,
					stateDestination: 'listDiagnosa',
					next: null
				}
			}
		];

		this.setState({
			errComponent: false
		});

		rule.map((item, index) => {
			if(index == rule.length-1){
				this.getMasterList(item, true);
			}else{
				this.getMasterList(item);
			}
		});
	}

	async getMasterList(rule, isFinal=false) {
		var destination = rule.destination;
		var nextDestination = rule.nextDestination;
		this.setState({[destination.stateProgressName]: true});
		this.props.ajaxViewHandler('get', destination.endPoint)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				if(data.count != undefined){
					let newState = null;
					if(this.state[destination.stateDestination].length == 0){
						newState = data;
					}else{
						if(destination.stateProgressName.includes('_searching')){
							newState = data
						}else{
							const oldState = this.state[destination.stateDestination];
							newState = {
								count: data.count,
								next: data.next,
								previous: data.previous,
								results: [...oldState.results, ...data.results]
							};
						}
					}
					this.setState({
		            	[destination.stateDestination]: newState
		            });
				}else if(data.results != undefined){
	        		this.setState({
		            	[destination.stateDestination]: data.results
		            });
	        	}else{
	        		if(Array.isArray(data)){
			            this.setState({
			            	[destination.stateDestination]: data
			            })
			        }else{
			        	this.setState({
			            	[destination.stateDestination]: [data]
			            })
			        }
		        }
		    }else{
		    	this.setState({
	        		errComponent: true
	        	});
		    }
		    this.setState({[destination.stateProgressName]: false});
		}).catch((response) => {
			console.log('gagal_get_master', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({
				errTitle: 'gagal request data master',
				errMsg: response
			});
			this.setState({[destination.stateProgressName]: false});
		});
        //next function
        if(nextDestination != null){
        	const next = {
        		destination: {
	        		stateProgressName: nextDestination.stateProgressName,
	        		endPoint: nextDestination.endPoint,
	        		stateDestination: nextDestination.stateDestination,
	        	},
        		nextDestination: nextDestination.next

        	}
        	this.getMasterList(next);
        }
	}

	generateSelect(stateName, url, key, value){
		return (
			this.state['loading'+stateName]?(
				<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
			):(
				<Select
					showSearch
					placeholder="pilih"
					optionFilterProp="children"
					onSearch={ val => {
						const rule = {
							destination: {
								stateProgressName: 'loading'+stateName+'_searching',
								endPoint: url+'?search='+val,
								stateDestination: 'list'+stateName
							},
							nextDestination: null
						}
						this.getMasterList(rule);
					}}
					onPopupScroll={ e => {
						e.persist(); 
						let target = e.target;
						if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
							if(this.state['list'+stateName].next != undefined && this.state['list'+stateName].next != null){
								const rule = {
									destination: {
										stateProgressName: 'loading'+stateName+'Paging',
										endPoint: this.state['list'+stateName].next,
										stateDestination: 'list'+stateName
									},
									nextDestination: null
								}
								this.getMasterList(rule);
							}
						}
					}}
					allowClear
					labelInValue
					onChange={ (val) =>{
						if(stateName == 'Diagnosa' && val != undefined && val != null && val != ''){
							this.setState({
								diagnosa: val.label
							});
						}
					}}
				>
					{(this.state['loading_'+stateName+'_searching'] &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
					{this.state['list'+stateName].count != undefined ? (
						this.state['list'+stateName].results.map((item) => (
							<Select.Option value={item[key]}>{item[value]+""}</Select.Option>
						))
					) : (
						this.state['list'+stateName].map((item) => (
							<Select.Option value={item[key]}>{item[value]+""}</Select.Option>
						))
					)}
					{(this.state['list'+stateName] != undefined && this.state['list'+stateName].next != undefined &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
				</Select>
			)
		);
	}

	async getRiwayatRm(limit=this.state.paginationRm.pageSize, offset=0, search=this.state.searchTextRm){
		var params = this.props.jenis == 'transaksi' ? 'transaksi='+this.props.transaksi[0].code : 'pasien='+this.props.transaksi[0].pasien;
		this.setState({loadingDataRm: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.rekam_medis+'?'+params+'&limit='+limit+'&offset='+offset+'&search='+search)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				const paginationRm = { ...this.state.paginationRm };
				paginationRm.total = data.count;
				this.setState({
					dataSourceRm: data.results,
					paginationRm
				});
	    }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	    }
	    this.setState({loadingDataRm: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingDataRm: false});
		});
	}

	handleTableChangeRm = async (paginationRm, filters, sorter) =>{
    	const newPagination = { ...this.state.paginationRm };
    	newPagination.pageSize = paginationRm.pageSize;
		this.setState({
			loadingDataRm: true,
			paginationRm
		});
		if(paginationRm.current==1){
			this.getRiwayatRm(paginationRm.pageSize);
		}else{
			this.getRiwayatRm(paginationRm.pageSize, (paginationRm.current - 1)*paginationRm.pageSize);
		}

		
	}

	handleSubmit = (values) =>{
		// var datas = new FormData();
		// for ( var key in values ) {
		// 	if(values[key] == undefined){
		// 		datas.append(key, '');
		// 	}else{
		// 		datas.append(key, values[key].toString());
		// 	}
		// }

		values = {...values, diagnosa: this.state.diagnosa};
		values = {...values, pasien: this.props.transaksi[0].pasien};
		if(this.props.isDokter == true){
			values = {...values, dokter: this.props.id};
		}
		values = {...values, transaksi: this.props.transaksi[0].id};

		// console.log('tes_data', values);
		
		this.postData(values);
	}

	async postData(datas) {
		message.loading({ content: 'Memproses...', duration: 0, key: 'toast' });
		this.setState({
			errTitle: '',
			errMsg: '',
			loadingButton: true
		});
		this.props.ajaxHandler('post', configClass.apiUrl.rekam_medis, datas, true, true)
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menyimpan data', duration: 2, key: 'toast' });
				var response = this.props.responseMsg;
				this.formRef.current.setFieldsValue({
					// terapi: undefined,
					diagnosa_preview: undefined
				});
				this.getRiwayatRm();
			}else{
				const errTitle = 'gagal menyimpan data rekam medis'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_post', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	showDetail(row){
		var detail_obat_temp = "";
		if(row.obat != undefined && row.obat != null && row.obat != ""){
			row.obat.map((item, idx) => {
				if(idx == 0){
					detail_obat_temp = "- "+item.produk_nama;
				}else{
					detail_obat_temp = "\r\n- "+item.produk_nama;
				}
			});
		}
		var detail_tindakan_temp = "";
		if(row.tindakan != undefined && row.tindakan != null && row.tindakan != ""){
			row.tindakan.map((item, idx) => {
				if(idx == 0){
					detail_tindakan_temp = "- "+item.produk_nama;
				}else{
					detail_tindakan_temp = "\r\n- "+item.produk_nama;
				}
			});
		}
		this.setState({
			modalDetail: true,
			detail: row,
			detail_obat: detail_obat_temp,
			detail_tindakan: detail_tindakan_temp,
		});
	}

	reset(){
		this.formRef.current.setFieldsValue({
			diagnosa_preview: undefined,
			// terapi: undefined
		});
		this.setState({
			diagnosa: null
		});
	}

	render() {
		return(
			<div>
				<Form 
					{...layout}
					name="frm"
					onFinish={this.handleSubmit}
					size="large"
					ref={this.formRef}
				>
					{(this.state.errMsg!='' &&
						<Alert
							message={this.state.errTitle}
							description={JSON.stringify(this.state.errMsg)}
							type="error"
							showIcon
							style={{
								marginBottom: 10
							}}
						/>
					)}

					
					{(this.props.isDokter != undefined && this.props.isDokter == false &&
						<Form.Item
							label="DOKTER"
							name="dokter"
							rules={[
								{ 
									required: true, 
									message: 'harus dipilih' 
								}
							]}
						>
							{this.generateSelect('Dokter', configClass.apiUrl.dokter, 'id', 'nama')}
						</Form.Item>
					)}
					<Form.Item
						label="DIAGNOSA"
						name="diagnosa_preview"
						rules={[
							{ 
								required: true, 
								message: 'harus diisi' 
							}
						]}
					>
						{this.generateSelect('Diagnosa', configClass.apiUrl.diagnosa, 'id', 'nama')}
					</Form.Item>
					<Row justify="end">
						<Col style={{padding: 5}}>
							<Button 
								key="btn-simpan"
								loading={this.state.loading}
								type="primary" 
								htmlType="submit"
								disabled={this.state.errComponent?true:false}
								size="large"
							>
								{(!this.state.loading && <SaveOutlined />)}
								Simpan
							</Button>
						</Col>
						<Col style={{padding: 5}}>
							<Button 
								type="danger"
								key="btn-cancel"
								disabled={this.state.loading?true:false}
								size="large"
								onClick={() => this.reset()}
							>
								<CloseCircleOutlined /> Reset
							</Button>
						</Col>
					</Row>   
				</Form>
				<Table 
						// style={{ whiteSpace: 'pre'}}
						title={() => 
							<Row>
								<Col lg={8} sm={24} xs={24}><span className="konten-judul">Total {this.state.paginationRm.total} record</span></Col>
								<Col lg={{span: 8, offset: 8}} sm={24} xs={24}>
									<Input.Search 
										size="small"
										placeholder="ketikkan pencarian" 
										onSearch={value => {
											this.setState({
												searchTextRm: value
											}, () =>{
												this.getRiwayatRm()
											});
										}} 
										allowClear
									/>										
								</Col>
							</Row>					        			
						}
						bordered
						columns={this.state.columnsRm} 
						dataSource={this.state.dataSourceRm} 
						loading={this.state.loadingDataRm} 
						scroll={{ x: 240, y: 450 }}
						size="small"
						pagination={this.state.paginationRm}
						onChange={this.handleTableChangeRm}
				/>
				<Modal
				  autoFocus={false}
				  closable={true}
		      visible={this.state.modalDetail}
		      title="DETAIL REKAM MEDIS"
		      onCancel={() =>{
		       this.setState({
		         modalDetail: false
		       })
		     }}
		     footer={[]}
		     width={800}
		   	>
		   		<Row>
		   			<Col span={6} className="titik-dua">DIAGNOSA</Col>
		   			<Col span={18}>{this.state.detail != null ? <Input.TextArea value={this.state.detail.diagnosa} rows={2} readOnly /> : ''}</Col>
		   		</Row>
		   		<Row>
		   			<Col span={6} className="titik-dua">OBAT</Col>
		   			<Col span={18}>{this.state.detail != null ? <Input.TextArea value={this.state.detail_obat} rows={8} readOnly /> : ''}</Col>
		   		</Row>
		   		<Row>
		   			<Col span={6} className="titik-dua">TINDAKAN</Col>
		   			<Col span={18}>{this.state.detail != null ? <Input.TextArea value={this.state.detail_tindakan} rows={8} readOnly /> : ''}</Col>
		   		</Row>
		    </Modal>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(RekamMedis);