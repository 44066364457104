import React from 'react';
import { Link, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Avatar,
	Form, 
	Input,
	Button,
	Space,
	Select,
	DatePicker,
	message,
	Alert,
	Spin,
	Modal, 
	Skeleton,
	Table,
	Tabs,
	Result
} from 'antd';
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  BarcodeOutlined,
  UserAddOutlined,
  PrinterOutlined,
  UserOutlined,
  WarningOutlined,
  SaveOutlined,
  FullscreenOutlined
} from '@ant-design/icons';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import moment from 'moment';
import NumberFormat from 'react-number-format';
// import { FullScreen } from "react-full-screen";
import FullScreen from 'react-fullscreen-crossbrowser';

import axios from 'axios';
import Config from '../../Config';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import {Helmet} from "react-helmet";

import RekamMedis from './RekamMedis';
import Layanan from './Layanan';
import Obat from './Obat';

const FormItem = Form.Item

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    }
  },
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const configClass = {
	title: 'form layanan',
	apiUrl: {
		transaksi: 'transaksi/api/transaksi/',
		pasien: 'pasien/api/pasien?is_registrasi=true',
	},
	breadCrumb: [
		(
			<span>
				<DollarOutlined /> transaksi
			</span>
		),
		'layanan'
	]

}

const config = new Config();


class FormLayanan extends React.Component {
	formRef = React.createRef();

	constructor(props){
		super(props);

		this.state = {
			errComponent: false,
			errTitle: '',
			errMsg: '',
			loadingButton: false,

			loadingPasien: false,
			listPasien: [],

			pasien: undefined,
			transaksi: undefined,

			dataTransaksi: null,
			loadingDataTransaksi: false,

			jenis: 'transaksi',

			loadingProduk: false,
			listProduk: [],

			aksi: 'tambah',
			method: 'post',
			idLama: '',

			isFullscreenEnabled: false,

			modalPasien: false,
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.loadMasterList();
	}

	handleSubmit = (values) =>{
		var datas = new FormData();
		for ( var key in values ) {
			if(values[key] == undefined){
				datas.append(key, '');
			}else{
				datas.append(key, values[key].toString());
			}
		}
		
		this.postData(datas);
	}

	reset(){
		this.formRef.current.setFieldsValue({
			pasien: undefined,
			jenis: 'transaksi'
		});
		this.resetPasien();
		this.setState({
			jenis: 'transaksi'
		});
	}

	async getRegistrasi(){
		var params = this.state.jenis == 'transaksi' ? 'code='+this.state.transaksi : 'pasien='+this.state.pasien+'&last=true';
		this.setState({loadingDataTransaksi: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.transaksi+'?'+params)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				this.setState({
					dataTransaksi: data.results
				}, () =>{
					// this.getRiwayatRm();
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingDataTransaksi: false});
		}).catch((response) => {
			console.log('gagal_get_registrasi', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingDataRm: false});
		});
	}

	handleTableChangeRm = async (paginationRm, filters, sorter) =>{
    	const newPagination = { ...this.state.paginationRm };
    	newPagination.pageSize = paginationRm.pageSize;
		this.setState({
			loadingDataRm: true,
			paginationRm
		});
		if(paginationRm.current==1){
			this.getRiwayatRm(paginationRm.pageSize);
		}else{
			this.getRiwayatRm(paginationRm.pageSize, (paginationRm.current - 1)*paginationRm.pageSize);
		}

		
	}

	async postData(datas) {
		message.loading({ content: 'Memproses...', duration: 0, key: 'toast' });
		this.setState({
			errTitle: '',
			errMsg: ''
		});
		this.props.ajaxHandler('post', configClass.apiUrl.registrasi, datas, true, true)
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil melakukan registrasi', duration: 2, key: 'toast' });

				var response = this.props.responseMsg;
				var that = this;
				Modal.confirm({
					    title: 'Registrasi Sukses',
					    icon: <CheckCircleOutlined style={{color: 'green'}} />,
					    content: 'tekan cetak untuk mencetak, tekan selesai untuk melanjutkan',
					    okText: <a href={"/addon/cetak_registrasi/"+response.id} target="_blank"><PrinterOutlined /> Cetak</a>,
					    cancelText: 'Selesai',
					    onOk() {
					    	that.reset()
					    },
					    onCancel() {
					    	that.reset()
					    }
				});
			}else{
				const errTitle = 'gagal menyimpan data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_post', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	loadMasterList(){
		const rule = [
			{
				destination: {
					stateProgressName: 'loadingPasien',
					endPoint: configClass.apiUrl.pasien,
					stateDestination: 'listPasien',
				},
				nextDestination: null
			},
		];

		this.setState({
			errComponent: false
		});

		rule.map((item, index) => {
			if(index == rule.length-1){
				this.getMasterList(item, true);
			}else{
				this.getMasterList(item);
			}
		});
	}

	async getMasterList(rule, isFinal=false) {
		var destination = rule.destination;
		var nextDestination = rule.nextDestination;
		this.setState({[destination.stateProgressName]: true});
		this.props.ajaxViewHandler('get', destination.endPoint)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				if(data.count != undefined){
					let newState = null;
					if(this.state[destination.stateDestination].length == 0){
						newState = data;
					}else{
						if(destination.stateProgressName.includes('_searching')){
							newState = data
						}else{
							const oldState = this.state[destination.stateDestination];
							newState = {
								count: data.count,
								next: data.next,
								previous: data.previous,
								results: [...oldState.results, ...data.results]
							};
						}
					}
					this.setState({
		            	[destination.stateDestination]: newState
		            });
				}else if(data.results != undefined){
	        		this.setState({
		            	[destination.stateDestination]: data.results
		            });
	        	}else{
	        		if(Array.isArray(data)){
			            this.setState({
			            	[destination.stateDestination]: data
			            })
			        }else{
			        	this.setState({
			            	[destination.stateDestination]: [data]
			            })
			        }
		        }
		        if(isFinal){
		        	if(this.props.match.params.id != undefined){
						this.preview(this.props.match.params.id);
					}
		        }
		    }else{
		    	this.setState({
	        		errComponent: true
	        	});
		    }
		    this.setState({[destination.stateProgressName]: false});
		}).catch((response) => {
			//console.log('gagal_get_master', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({
				errTitle: 'gagal request data master',
				errMsg: response
			});
			this.setState({[destination.stateProgressName]: false});
		});
        //next function
        if(nextDestination != null){
        	const next = {
        		destination: {
	        		stateProgressName: nextDestination.stateProgressName,
	        		endPoint: nextDestination.endPoint,
	        		stateDestination: nextDestination.stateDestination,
	        	},
        		nextDestination: nextDestination.next

        	}
        	this.getMasterList(next);
        }
	}

	

	generateSelect(stateName, url, key, value){
		return (
			this.state['loading'+stateName]?(
				<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
			):(
				<Select
					autoFocus 
					showSearch
					placeholder="cari / pilih pasien"
					optionFilterProp="children"
					onSearch={ val => {
						const rule = {
							destination: {
								stateProgressName: 'loading'+stateName+'_searching',
								endPoint: url+'&search='+val,
								stateDestination: 'list'+stateName
							},
							nextDestination: null
						}
						this.getMasterList(rule);
					}}
					onPopupScroll={ e => {
						e.persist(); 
						let target = e.target;
						if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
							if(this.state['list'+stateName].next != undefined && this.state['list'+stateName].next != null){
								const rule = {
									destination: {
										stateProgressName: 'loading'+stateName+'Paging',
										endPoint: this.state['list'+stateName].next,
										stateDestination: 'list'+stateName
									},
									nextDestination: null
								}
								this.getMasterList(rule);
							}
						}
					}}
					allowClear
					style={{
						width: '100%'
					}}
					onChange={(val) =>{
						this.setState({
							pasien: val,
						}, () =>{
							this.getRegistrasi();
						});
					}}
					size={"large"}
				>
					{(this.state['loading'+stateName+'_searching'] == true &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
					{this.state['list'+stateName].count != undefined ? (
						this.state['list'+stateName].results.map((item) => (
							<Select.Option value={item[key]+''}>{item[key]+" - "+item[value]+" - "+item.no_hp}</Select.Option>
						))
					) : (
						this.state['list'+stateName].map((item) => (
							<Select.Option value={item[key]+''}>{item[key]+" - "+item[value]+" - "+item.no_hp}</Select.Option>
						))
					)}
					{(this.state['list'+stateName] != undefined && this.state['list'+stateName].next != undefined &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
				</Select>
			)
		);
	}

	render() {
		// if(this.props.layanan_str.length>0 && this.props.layanan_str.includes('Pembelian / Pemesanan Obat') == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		// if(this.props.isRedirect){
		// 	return <Redirect to={configClass.pageUrl.data} />
		// }

		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+config.get_site_info().name}</title>
				</Helmet>
				<FullScreen 
										enabled={this.state.isFullscreenEnabled}
										onChange={isFullscreenEnabled => this.setState({isFullscreenEnabled})}
									>
				<div className="card">
					<Form 
						layout="vertical"
						name="fdata"
					    onFinish={this.handleSubmit}
					    size="small"
					    ref={this.formRef}
					    initialValues={{
					    	jenis: this.state.jenis
					    }}
					    
					>
						{/*data dasar*/}
						<Row className="isi">
					       	<Col lg={24} sm={24} xs={24}>
					        	<Card
					        		className="konten"
						            title={
						            	<span className="konten-judul">
						            		{configClass.title}
						            		{(this.state.loadingButton &&
								        		<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
								        	)}
						            	</span>
						            }
						        >
						        		{(this.state.errMsg!='' &&
								        	<Alert
										      message={this.state.errTitle}
										      description={JSON.stringify(this.state.errMsg)}
										      type="error"
										      showIcon
										      style={{
										      	marginBottom: 10
										      }}
										    />
										)}
										{/*
										<Row justify="end">
											<Col style={{padding: 5}}>
									        	<Button 
													key="btn-baru"
													loading={this.state.loadingButton}
													type="primary" 
													htmlType="Button"
													disabled={this.state.errComponent?true:false}
													size="small"
													style={{
														background: '#A3D921',
														color: '#000000',
														border: 'none'
													}}
													onClick={() => this.showModalPasien()}
												>
													<UserAddOutlined />
													Pasien Baru
												</Button>
											</Col>
										</Row>
										*/}
										<Row>
											<Col span={24}>
												<Input.Group compact>
													<Form.Item
										            	name="jenis"
										            	style={{width: '15%'}}
										            >
										            	<Select
															showSearch
															placeholder="pilih jenis"
															optionFilterProp="children"
															style={{
																width: '100%'
															}}
															onChange={(val) =>{
																this.setState({
																	jenis: val,
																	dataTransaksi: null,
																}, () =>{
																	// this.formRef.pasien.current.focus({cursor: 'start'});
																	this.formRef.current.setFieldsValue({
																		key: undefined
																	});
																});
															}}
															size={"large"}
														>
															<Select.Option value={"transaksi"}><BarcodeOutlined /> barcode</Select.Option>
															<Select.Option value={"pasien"}><UserOutlined /> pasien</Select.Option>
														</Select>
										            </Form.Item>
										            {(this.state.jenis == 'transaksi' &&
										            	<Form.Item
											            	name="key"
											            	style={{width: '85%'}}
											            >
															<Input 
																autoFocus
														      	value={this.state.barcodeText}
														      	onChange={(event) =>{
														      		this.setState({
														      			transaksi: event.target.value
														      		})
														      	}}
														      	ref={this.barcodeRef}
														      	size="large" 
														      	placeholder="scan barcode disini" 
														      	className="penjualan-cart-barcode" 
														      	onKeyPress={event => {
													                if (event.key === 'Enter') {
													                	event.preventDefault();
													                	this.getRegistrasi();
													                }
													            }}
													            style={{textTransform: 'none'}}
														    />
													    </Form.Item>
										            )}
										            {((this.state.jenis == 'pasien') &&
										            	<Form.Item
											            	name="key"
											            	style={{width: '85%'}}
											            >
													    	{this.generateSelect('Pasien', configClass.apiUrl.pasien, 'id', 'nama')}
													    </Form.Item>
										            )}
										        </Input.Group>
											</Col>
										</Row>
										<Row>
											{this.state.loadingDataTransaksi == true 
												?
													<Col span={24}>
														<Skeleton active />
													</Col>
												:
													(this.state.dataTransaksi!= null && this.state.dataTransaksi.length >0 
														?
															<Col span={24} style={{fontSize: 18, fontWeight: 'bold'}}>
																{this.state.dataTransaksi[0].pasien_nama}
															</Col>
														:
															(this.state.dataTransaksi!= null &&
																<Col span={24}>
																<Result
																	    icon={<WarningOutlined style={{color: '#f9af4d'}} />}
																	    title={
																	    	<span style={{color: '#f9af4d'}}>
																	    		data tidak ditemukan
																	    	</span>
																	    }
																/>
																</Col>
															)
													)
											}
										</Row>
										{/*
										<Row>
											<Col span={24}>
												<Input.Group compact>
													<Form.Item
										            	name="pasien"
										            	style={{width: '45%'}}
										            >
														<Input 
													      	value={this.state.barcodeText}
													      	onChange={(event) =>{
													      		this.setState({
													      			barcodeText: event.target.value
													      		})
													      	}}
													      	ref={this.barcodeRef}
													      	prefix={this.state.loadingBarcode ? <Spin /> : <BarcodeOutlined />}
													      	size="small" 
													      	placeholder="scan barcode disini" 
													      	className="penjualan-cart-barcode" 
													      	onKeyPress={event => {
												                if (event.key === 'Enter') {
												                	event.preventDefault();
												                	this.scanProduk(event.target.value);
												                }
												            }}
													    />
												    </Form.Item>
												    <Input value={"atau"} readOnly style={{width: '5%', textAlign: 'center', background: '#CCCCCC', textTransform: 'none'}} />
												    <Form.Item
										            	name="pasien_select"
										            	style={{width: '50%'}}
										            >
												    	{this.generateSelect('Pasien', configClass.apiUrl.pasien, 'id', 'nama')}
												    </Form.Item>
												</Input.Group>
											</Col>
										</Row>
										*/}
										

						        </Card>
					        </Col>
					    </Row>
					</Form>
					{(this.state.dataTransaksi != null && this.state.dataTransaksi.length>0 &&
						<Row className="isi">
							<Col span={24}>
								<Card
								    className="konten"
									title=""
								>
									<Row>
										<Col span={24}>
											<Tabs defaultActiveKey="riwayat_rm">
											    <Tabs.TabPane tab="RIWAYAT REKAM MEDIS" key="riwayat_rm">
											      <RekamMedis transaksi={this.state.dataTransaksi} jenis={this.state.jenis} />
											    </Tabs.TabPane>
											    <Tabs.TabPane tab="RESEP OBAT" key="obat">
											      <Obat transaksi={this.state.dataTransaksi} jenis={this.state.jenis} />
											    </Tabs.TabPane>
											    <Tabs.TabPane tab="TINDAKAN" key="layanan">
											    	<Layanan transaksi={this.state.dataTransaksi} jenis={this.state.jenis} />
											    </Tabs.TabPane>
											</Tabs>
										</Col>
										{/*
										<Col span={1}>
											<Button
												onClick={() => this.setState({isFullscreenEnabled: true})}
											>
												<FullscreenOutlined />
											</Button>
										</Col>
										*/}
									</Row>
								</Card>
							</Col>
						</Row>
					)}
				</div>
				</FullScreen>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(FormLayanan);