import React from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Form,
	DatePicker,
	Select,
	Spin
} from 'antd';
import {
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  SnippetsOutlined,
  RedoOutlined,
  OrderedListOutlined,
  PrinterOutlined,
  FileSearchOutlined
} from '@ant-design/icons';
import moment from "moment";
import 'moment/locale/id';

import axios from 'axios';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
	ajaxDownloadHandler
} from "../../store/actions";

import Config from '../../Config';
import {Helmet} from "react-helmet";

const configClass = {
	title: 'laporan transaksi',
	apiUrl: {
		data: 'laporan/api/transaksi/',
		cabang: 'perusahaan/api/cabang/',
		kasir: 'karyawan/api/karyawan/',
	},
	pageUrl: {
		
	},
	breadCrumb: [
		(
			<span>
				<SnippetsOutlined /> laporan
			</span>
		),
		'transaksi'
	]

}

const config = new Config();

class Transaksi extends React.Component {
	formRef = React.createRef();

	constructor(props){
		super(props);
		this.state = {
			errMsg: '',
			loadingData: false,
			pagination: {pageSize: 25},
			searchText: '',
			searchColumn: '',
			dataSource: [],
			columns: [
				{
					title: 'CABANG',
					width: 150,
					dataIndex: 'cabang_nama',
					key: 'cabang_nama'
				},
				{
					title: 'TANGGAL',
					width: 150,
					dataIndex: 'tanggal',
					key: 'tanggal',
					render: (value, row, index) => {
						moment.locale("id");
						const waktu = moment(value).format("dddd Do MMM YYYY H:mm")
						return (
							waktu
						)
					}
				},
				{
					title: 'PASIEN',
					width: 150,
					dataIndex: 'pasien_nama',
					key: 'pasien_nama'
				},
				{
					title: 'KASIR',
					width: 150,
					dataIndex: 'kasir_nama',
					key: 'kasir_nama'
				},
				{
					title: 'TOTAL',
					width: 150,
					dataIndex: 'total',
					key: 'total',
					render: (value, row, index) => {
						return (
							value.toLocaleString('id')
						)
					}
				},
				{
					title: 'CODE',
					width: 150,
					dataIndex: 'code',
					key: 'code'
				},
				{
					title: 'TIPE PEMBAYARAN',
					width: 150,
					dataIndex: 'payment_type_nama',
					key: 'payment_type_nama'
				},
				/*
				{
					title: 'AKSI',
					width: 150,
					dataIndex: 'id',
					key: 'aksi',
					fixed: 'right',
					render: (value, row, index) => {
						return (
							<Row key={"space-aksi-"+index} justify="center">
								<Col style={{padding: 5}}>
									<Button className="btn-default" size="small" shape="circle" title="lihat detail">
										<OrderedListOutlined />
									</Button>
								</Col>
								<Col style={{padding: 5}}>
									<Button className="btn-default" size="small" shape="circle" title="cetak nota">
										<PrinterOutlined />
									</Button>
								</Col>
							</Row>
						)
					},
				}
				*/
			],
			loadingCabang: false,
			listCabang: [],
			loadingKasir: false,
			listKasir: [],
			total: 0,
			filter: "",
			url_download: "",
			namaFile: "LAPTRANS_",
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.getData();
		this.loadMasterList();
	}

	showDeleteConfirm(id) {
	  Modal.confirm({
	    title: 'Konfirmasi Hapus?',
	    icon: <ExclamationCircleOutlined />,
	    content: 'yakin menghapus data?',
	    okText: 'Ya',
	    okType: 'danger',
	    cancelText: 'Tidak',
	    onOk: () => this.prosesHapus(id),
	    onCancel() {
	      //console.log('Cancel');
	    },
	  });
	}

	prosesHapus(id) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('delete', configClass.apiUrl.data+id+'/')
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menghapus data', duration: 2, key: 'toast' });
				this.getData();
			}else{
				const errTitle = 'gagal menghapus data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_delete', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	getColumnSearchProps = dataIndex => ({
	    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
	      <div style={{ padding: 8 }}>
	        <Input
	          ref={node => {
	            this.searchInput = node;
	          }}
	          placeholder={`Search ${dataIndex}`}
	          value={selectedKeys[0]}
	          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
	          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          style={{ width: 188, marginBottom: 8, display: 'block' }}
	        />
	        <Button
	          type="primary"
	          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          icon={<SearchOutlined />}
	          size="small"
	          style={{ width: 90, marginRight: 8 }}
	        >
	          Search
	        </Button>
	        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
	          Reset
	        </Button>
	      </div>
	    ),
	    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	    onFilter: (value, record) =>
	      record[dataIndex]
	        .toString()
	        .toLowerCase()
	        .includes(value.toLowerCase()),
	    onFilterDropdownVisibleChange: visible => {
	      if (visible) {
	        setTimeout(() => this.searchInput.select());
	      }
	    },
	    render: text =>
	      this.state.searchedColumn === dataIndex ? (
	        <Highlighter
	          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
	          searchWords={[this.state.searchText]}
	          autoEscape
	          textToHighlight={text.toString()}
	        />
	      ) : (
	        text
	      ),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
	    confirm();
	    this.setState({
	      searchText: selectedKeys[0],
	      searchedColumn: dataIndex,
	    });
	};

	handleReset = clearFilters => {
	    clearFilters();
	    this.setState({ searchText: '' });
	};

	async getData(limit=this.state.pagination.pageSize, offset=0, search=this.state.searchText){
		this.setState({loadingData: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		var urlFinal = configClass.apiUrl.data+'?limit='+limit+'&offset='+offset+'&search='+search;
		var urlDownload = config.get_server_url()+"/"+configClass.apiUrl.data+"?export=true";
		if(this.state.filter != ""){
			urlFinal = configClass.apiUrl.data+this.state.filter+'&limit='+limit+'&offset='+offset+'&search='+search;
			urlDownload = config.get_server_url()+"/"+configClass.apiUrl.data+this.state.filterData+"&export=true";
		}

		this.props.ajaxViewHandler('get', urlFinal)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				const pagination = { ...this.state.pagination };
				pagination.total = data.count;
				this.setState({
					dataSource: data.results,
					pagination,
					total: data.total,
					url_download: urlDownload
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingData: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingData: false});
		});
	}

	async download() {
		this.setState({
			loadingButton: true
		});
		message.loading({ content: 'Mengunduh...', key: 'toast' });
		this.props.ajaxDownloadHandler('get', this.state.url_download)
		.then(() =>{
			var a = document.createElement('a');
            var url = window.URL.createObjectURL(this.props.responseMsg);
            a.href = url;
            a.download = this.state.namaFile;
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            message.success({ content: 'berhasil diunduh', duration: 2, key: 'toast' });
            this.setState({
				loadingButton: false,
				successDownload: true
			});
		}).catch((response) => {
			console.log('gagal_get_master', response);
			message.error({ content: 'gagal request download, coba lagi', key: 'toast' });
			this.setState({
				loadingButton: false
			});
		});
	}

	handleTableChange = async (pagination, filters, sorter) =>{
    	const newPagination = { ...this.state.pagination };
    	newPagination.pageSize = pagination.pageSize;
		this.setState({
			loadingData: true,
			pagination
		});
		if(pagination.current==1){
			this.getData(pagination.pageSize);
		}else{
			this.getData(pagination.pageSize, (pagination.current - 1)*pagination.pageSize);
		}

		
	}

	handleSubmit = (values) =>{
		var tempFilter = "";
		for ( var key in values ) {
			if(values[key] != undefined){
				if(tempFilter == ""){
					tempFilter = "?"+key+"="+values[key];
				}else{
					tempFilter += "&"+key+"="+values[key];
				}
			}
		}
		this.setState({
			filter: tempFilter
		}, () =>{
			this.getData();
		});
	};

	loadMasterList(){
		const rule = [
			{
				destination: {
					stateProgressName: 'loadingCabang',
					endPoint: configClass.apiUrl.cabang,
					stateDestination: 'listCabang',
				},
				nextDestination: {
					stateProgressName: 'loadingKasir',
					endPoint: configClass.apiUrl.kasir,
					stateDestination: 'listKasir',
					next: null,
				}
			},
		];

		this.setState({
			errComponent: false
		});

		rule.map((item, index) => {
			if(index == rule.length-1){
				this.getMasterList(item, true);
			}else{
				this.getMasterList(item);
			}
		});
	}

	async getMasterList(rule, isFinal=false) {
		var destination = rule.destination;
		var nextDestination = rule.nextDestination;
		this.setState({[destination.stateProgressName]: true});
		this.props.ajaxViewHandler('get', destination.endPoint)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				if(data.count != undefined){
					let newState = null;
					if(this.state[destination.stateDestination].length == 0){
						newState = data;
					}else{
						if(destination.stateProgressName.includes('_searching')){
							newState = data
						}else{
							const oldState = this.state[destination.stateDestination];
							newState = {
								count: data.count,
								next: data.next,
								previous: data.previous,
								results: [...oldState.results, ...data.results]
							};
						}
					}
					this.setState({
		            	[destination.stateDestination]: newState
		            });
				}else if(data.results != undefined){
	        		this.setState({
		            	[destination.stateDestination]: data.results
		            });
	        	}else{
	        		if(Array.isArray(data)){
			            this.setState({
			            	[destination.stateDestination]: data
			            })
			        }else{
			        	this.setState({
			            	[destination.stateDestination]: [data]
			            })
			        }
		        }
		        if(isFinal){
		        	if(this.props.match.params.id != undefined){
						// this.preview(this.props.match.params.id);
					}
		        }
		    }else{
		    	this.setState({
	        		errComponent: true
	        	});
		    }
		    this.setState({[destination.stateProgressName]: false});
		}).catch((response) => {
			//console.log('gagal_get_master', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({
				errTitle: 'gagal request data master',
				errMsg: response
			});
			this.setState({[destination.stateProgressName]: false});
		});
        //next function
        if(nextDestination != null){
        	const next = {
        		destination: {
	        		stateProgressName: nextDestination.stateProgressName,
	        		endPoint: nextDestination.endPoint,
	        		stateDestination: nextDestination.stateDestination,
	        	},
        		nextDestination: nextDestination.next

        	}
        	this.getMasterList(next);
        }
	}

	generateSelect(stateName, url, key, value){
		return (
			this.state['loading'+stateName]?(
				<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
			):(
				<Select
					autoFocus 
					showSearch
					placeholder="cari / pilih"
					optionFilterProp="children"
					onSearch={ val => {
						const rule = {
							destination: {
								stateProgressName: 'loading'+stateName+'_searching',
								endPoint: url+'&search='+val,
								stateDestination: 'list'+stateName
							},
							nextDestination: null
						}
						this.getMasterList(rule);
					}}
					onPopupScroll={ e => {
						e.persist(); 
						let target = e.target;
						if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
							if(this.state['list'+stateName].next != undefined && this.state['list'+stateName].next != null){
								const rule = {
									destination: {
										stateProgressName: 'loading'+stateName+'Paging',
										endPoint: this.state['list'+stateName].next,
										stateDestination: 'list'+stateName
									},
									nextDestination: null
								}
								this.getMasterList(rule);
							}
						}
					}}
					allowClear
					style={{
						width: '100%'
					}}
				>
					{(this.state['loading'+stateName+'_searching'] == true &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
					{this.state['list'+stateName].count != undefined ? (
						this.state['list'+stateName].results.map((item) => (
							<Select.Option value={item[key]}>{item[value]}</Select.Option>
						))
					) : (
						this.state['list'+stateName].map((item) => (
							<Select.Option value={item[key]}>{item[value]}</Select.Option>
						))
					)}
					{(this.state['list'+stateName] != undefined && this.state['list'+stateName].next != undefined &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
				</Select>
			)
		);
	}

	render() {
		// if(this.props.isSuperadmin == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Row className="isi">
				       	<Col span={24}>
				        	<Card
				        		className="konten"
					            title={
					            	<Row>
					            		<Col lg={{ span: 20 }} xs={{ span:24 }}>
							            	<span className="konten-judul">
							            		{configClass.title}
							            		{(this.state.loadingData &&
									        		<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="30" alt="loading" />
									        	)}
							            	</span>
							            </Col>
						            	
						            </Row>
					            }
					        >
					        	{(this.state.errMsg!='' &&
						        	<Alert
								      message={this.state.errTitle}
								      description={JSON.stringify(this.state.errMsg)}
								      type="error"
								      showIcon
								      style={{
								      	marginBottom: 10
								      }}
								    />
								)}
					        	<Form 
											layout="horizontal"
											onFinish={this.handleSubmit}
										  size="small"
										  ref={this.formRef}
										>
						        	<Row justify>
						        		<Col span={5}>
						        			<Form.Item
						        				label="dari"
						        				name="tanggal_mulai_picker"
						        			>
						        				<DatePicker
                                placeholder="dd-mm-yyyy"
                                format="DD-MM-YYYY"
                                style={{ width: "100%" }}
                                onChange={(date, dateString) => {
                                  this.formRef.current.setFieldsValue({
                                    tanggal_mulai: dateString,
                                  });
                                }}
                              />
						        			</Form.Item>
						        			<Form.Item
                            name="tanggal_mulai"
                            style={{
                              display: "none",
                            }}
                          >
                            <Input type="hidden" />
                           </Form.Item>
						        		</Col>
						        		<Col span={5} style={{paddingLeft: 10}}>
						        			<Form.Item
						        				label="sampai"
						        				name="tanggal_akhir_picker"
						        			>
						        				<DatePicker
                                placeholder="dd-mm-yyyy"
                                format="DD-MM-YYYY"
                                style={{ width: "100%" }}
                                onChange={(date, dateString) => {
                                  this.formRef.current.setFieldsValue({
                                    tanggal_akhir: dateString,
                                  });
                                }}
                              />
						        			</Form.Item>
						        			<Form.Item
                            name="tanggal_akhir"
                            style={{
                              display: "none",
                            }}
                          >
                            <Input type="hidden" />
                           </Form.Item>
						        		</Col>
						        		<Col span={6} style={{paddingLeft: 10}}>
						        			<Form.Item
						        				label="cabang"
						        				name="cabang"
						        			>
						        				{this.generateSelect("Cabang", configClass.apiUrl.cabang, "id", "nama")}
						        			</Form.Item>
						        		</Col>
						        		<Col span={6} style={{paddingLeft: 10}}>
						        			<Form.Item
						        				label="Kasir"
						        				name="kasir"
						        			>
						        				{this.generateSelect("Kasir", configClass.apiUrl.kasir, "id", "nama")}
						        			</Form.Item>
						        		</Col>
						        		<Col span={1} style={{paddingLeft: 10}}>
						        			<Button className="btn-success" size="small" title="lihat laporan"  htmlType="submit" name="aksi" value="tampil">
														<FileSearchOutlined />
													</Button>
						        		</Col>
						        		<Col span={1} style={{paddingLeft: 10}}>
						        			{(this.state.dataSource.length>0 &&
							        			<Button className="btn-success" size="small" title="lihat laporan"  htmlType="submit" name="aksi" value="cetak" onClick={() => this.download()}>
															<PrinterOutlined />
														</Button>
													)}
						        		</Col>
						        	</Row>
						        </Form>
					        	<Table 
					        		title={() => 
					        			<Row>
					        				<Col lg={8} sm={24} xs={24}>
					        					<span className="konten-judul" style={{textTransform: "none"}}>
					        						TOTAL : Rp. {this.state.total.toLocaleString('id')}
					        					</span>
					        				</Col>
											<Col lg={{span: 8, offset: 8}} sm={24} xs={24}>
												<Input.Search 
													size="small"
													placeholder="ketikkan pencarian" 
													onSearch={value => {
														this.setState({
															searchText: value
														}, () =>{
																this.getData();
														});
													}} 
													allowClear
												/>										
											</Col>
										</Row>					        			
					        		}
					        		bordered
					        		columns={this.state.columns} 
					        		dataSource={this.state.dataSource} 
					        		loading={this.state.loadingData} 
					        		scroll={{ x: 240, y: 450 }}
					        		size="small"
					        		pagination={this.state.pagination}
					        		onChange={this.handleTableChange}
					        	/>
					        </Card>
				        </Col>
				    </Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler,
			ajaxDownloadHandler
		}
	)(Transaksi);