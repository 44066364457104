import React from 'react';
import { Link, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Avatar,
	Form, 
	Input,
	Button,
	Space,
	Select,
	DatePicker,
	message,
	Alert,
	Spin,
	Modal, 
	Skeleton,
	Table,
	Tabs,
	Result
} from 'antd';
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  BarcodeOutlined,
  UserAddOutlined,
  PrinterOutlined,
  UserOutlined,
  WarningOutlined,
  SaveOutlined,
  FileSearchOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import moment from 'moment';
import NumberFormat from 'react-number-format';

import axios from 'axios';
import Config from '../../Config';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import {Helmet} from "react-helmet";

const FormItem = Form.Item

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    }
  },
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const configClass = {
	apiUrl: {
		produk: 'master/api/produk',
		detail_transaksi: 'transaksi/api/detail/transaksi/'		
	},
	pageUrl: {
		cetak_no: '/addon/cetak_antrian/'
	},
}

const config = new Config();

class Layanan extends React.Component {
	formRefLayanan = React.createRef();

	constructor(props){
		super(props);

		this.state = {
			errComponent: false,
			errTitle: '',
			errMsg: '',
			loadingButton: false,

			jenis_layanan: 'produk',

			loadingLayanan: false,
			listLayanan: [],

			loadingCarLayanan: false,
			cartLayanan: [],

			loadingBarcodeLayanan: false,
			barcodeLayananIsNotFound: false,
			barcodeLayananResults: null,

			totalLayanan: 0,
			total_finalLayanan: 0,
			barcodeTextLayanan: undefined,

			loadingSimpanLayanan: false,

			isFinish: false,
			method: 'post',

		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.loadMasterList();
		if(this.props.transaksi != null && this.props.transaksi[0].transaksi != null && this.props.transaksi[0].transaksi.length>0)		{
			var transaksiExist = this.props.transaksi[0].transaksi;
			var tempCart = [];

			transaksiExist.map((item, idx) => {
				if(item.tipe != "2"){
					tempCart.push({
						id: item.produk,
						nama: item.produk_nama,
						harga: parseFloat(item.harga),
						qty: item.quantity,
						idx: item.id
					});
				}
			});
			this.setState({
				cartLayanan: tempCart
			});
		}
	}

	componentDidUpdate(prevProps) {
		// console.log('prevprops', prevProps.transaksi);
		// console.log('newProps', this.props.transaksi);
		// alert(prevProps.transaksi);
		if(JSON.stringify(prevProps.transaksi) != JSON.stringify(this.props.transaksi)){
			this.reset();
			if(this.props.transaksi != null && this.props.transaksi[0].transaksi != null && this.props.transaksi[0].transaksi.length>0)		{
				var transaksiExist = this.props.transaksi[0].transaksi;
				var tempCart = [];

				transaksiExist.map((item, idx) => {
					if(item.keterangan == ""){
						tempCart.push({
							id: item.produk,
							nama: item.produk_nama,
							harga: parseFloat(item.harga),
							qty: item.quantity,
							idx: item.id
						});
					}
				});
				this.setState({
					cartLayanan: tempCart
				});
			}
		}
	}

	loadMasterList(){
		const rule = [
			{
				destination: {
					stateProgressName: 'loadingLayanan',
					endPoint: configClass.apiUrl.produk+'?tipe=1',
					stateDestination: 'listLayanan',
				},
				nextDestination: null
			},
		];

		this.setState({
			errComponent: false
		});

		rule.map((item, index) => {
			if(index == rule.length-1){
				this.getMasterList(item, true);
			}else{
				this.getMasterList(item);
			}
		});
	}

	async getMasterList(rule, isFinal=false) {
		var destination = rule.destination;
		var nextDestination = rule.nextDestination;
		this.setState({[destination.stateProgressName]: true});
		this.props.ajaxViewHandler('get', destination.endPoint)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				if(data.count != undefined){
					let newState = null;
					if(this.state[destination.stateDestination].length == 0){
						newState = data;
					}else{
						if(destination.stateProgressName.includes('_searching')){
							newState = data
						}else{
							const oldState = this.state[destination.stateDestination];
							newState = {
								count: data.count,
								next: data.next,
								previous: data.previous,
								results: [...oldState.results, ...data.results]
							};
						}
					}
					this.setState({
		            	[destination.stateDestination]: newState
		            });
				}else if(data.results != undefined){
	        		this.setState({
		            	[destination.stateDestination]: data.results
		            });
	        	}else{
	        		if(Array.isArray(data)){
			            this.setState({
			            	[destination.stateDestination]: data
			            })
			        }else{
			        	this.setState({
			            	[destination.stateDestination]: [data]
			            })
			        }
		        }
		    }else{
		    	this.setState({
	        		errComponent: true
	        	});
		    }
		    this.setState({[destination.stateProgressName]: false});
		}).catch((response) => {
			console.log('gagal_get_master', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({
				errTitle: 'gagal request data master',
				errMsg: response
			});
			this.setState({[destination.stateProgressName]: false});
		});
        //next function
        if(nextDestination != null){
        	const next = {
        		destination: {
	        		stateProgressName: nextDestination.stateProgressName,
	        		endPoint: nextDestination.endPoint,
	        		stateDestination: nextDestination.stateDestination,
	        	},
        		nextDestination: nextDestination.next

        	}
        	this.getMasterList(next);
        }
	}

	generateSelect(stateName, url, key, value){
		return (
			this.state['loading'+stateName]?(
				<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
			):(
				<Select
					showSearch
					placeholder="pilih"
					optionFilterProp="children"
					onSearch={ val => {
						const rule = {
							destination: {
								stateProgressName: 'loading'+stateName+'_searching',
								endPoint: url+'?search='+val,
								stateDestination: 'list'+stateName
							},
							nextDestination: null
						}
						this.getMasterList(rule);
					}}
					onPopupScroll={ e => {
						e.persist(); 
						let target = e.target;
						if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
							if(this.state['list'+stateName].next != undefined && this.state['list'+stateName].next != null){
								const rule = {
									destination: {
										stateProgressName: 'loading'+stateName+'Paging',
										endPoint: this.state['list'+stateName].next,
										stateDestination: 'list'+stateName
									},
									nextDestination: null
								}
								this.getMasterList(rule);
							}
						}
					}}
					allowClear
				>
					{(this.state['loading_'+stateName+'_searching'] &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
					{this.state['list'+stateName].count != undefined ? (
						this.state['list'+stateName].results.map((item) => (
							<Select.Option value={item[key]}>{item[key]+" - "+item[value]+""}</Select.Option>
						))
					) : (
						this.state['list'+stateName].map((item) => (
							<Select.Option value={item[key]}>{item[key]+" - "+item[value]+""}</Select.Option>
						))
					)}
					{(this.state['list'+stateName] != undefined && this.state['list'+stateName].next != undefined &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
				</Select>
			)
		);
	}

	generateSelectProdukLayanan(stateName, url, key, value){
		return (
			this.state['loading'+stateName]?(
				<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
			):(
				<Select
					autoFocus 
					showSearch
					placeholder={"cari / pilih "+stateName.toLowerCase()}
					optionFilterProp="children"
					onSearch={ val => {
						const rule = {
							destination: {
								stateProgressName: 'loading'+stateName+'_searching',
								endPoint: url+'&search='+val,
								stateDestination: 'list'+stateName
							},
							nextDestination: null
						}
						this.getMasterList(rule);
					}}
					onPopupScroll={ e => {
						e.persist(); 
						let target = e.target;
						if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
							if(this.state['list'+stateName].next != undefined && this.state['list'+stateName].next != null){
								const rule = {
									destination: {
										stateProgressName: 'loading'+stateName+'Paging',
										endPoint: this.state['list'+stateName].next,
										stateDestination: 'list'+stateName
									},
									nextDestination: null
								}
								this.getMasterList(rule);
							}
						}
					}}
					allowClear
					style={{
						width: '100%'
					}}
					size={"large"}
					onChange={(val) =>{
						if(stateName == 'Layanan'){
							this.pilihLayanan(val);
						}
					}}
				>
					{(this.state['loading'+stateName+'_searching'] == true &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
					{this.state['list'+stateName].count != undefined ? (
						this.state['list'+stateName].results.map((item) => (
							<Select.Option value={item[key]+''}>{item['barcode']+" - "+item[value]}</Select.Option>
						))
					) : (
						this.state['list'+stateName].map((item) => (
							<Select.Option value={item[key]+''}>{item['barcode']+" - "+item[value]}</Select.Option>
						))
					)}
					{(this.state['list'+stateName] != undefined && this.state['list'+stateName].next != undefined &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
				</Select>
			)
		);
	}

	async scanLayanan(barcode){
		// this.setState({loadingBarcode: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.produk+'?barcode='+barcode)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				if(data.results.length > 0){
					this.setState({
						barcodeLayananIsNotFound: false,
						barcodeLayananResults: data.results,
						loadingBarcodeLayanan: false
					}, () =>{
						let temp_cart = this.state.cartLayanan;
					    // let temp_label_produk = this.state.label_produk;
					    let that = this;
					    if(temp_cart.length==0){
					      let produk = this.state.barcodeLayananResults[0];
					      // let harga = 0;
					      // let diskon_rupiah = 0;
					      // if(produk['diskon']!="" && produk['diskon'].rupiah>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty){
					      // 	console.log('tes_produk', produk);
					      // 	// console.log('tes_diskon_rupiah', produk['diskon'].rupiah)
					      // 	// diskon_rupiah = produk['diskon'].rupiah;
					      // }
					      
					      temp_cart.push({
					          id: produk['id'],
					          nama: produk['nama'],
					          harga: produk['harga'],
					          qty: 1
					      });
					      // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US');
					    }else{
					      const index_cari = temp_cart.findIndex(data => data.id==this.state.barcodeLayananResults[0].id);
					      if(index_cari==-1){
					        let produk = this.state.barcodeLayananResults[0];
					        // let harga = 0;
					        
					        temp_cart.push({
					        	id: produk['id'],
						        nama: produk['nama'],
						        harga: produk['harga'],
						        qty: 1,						        
					        });
					        // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US');
					      }else{
					        let produk = this.state.barcodeLayananResults[0];
					        // let harga = 0;
					        
					        // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US')+' x '+(temp_cart[index_cari]['qty']+1);
					        temp_cart[index_cari]['qty'] = temp_cart[index_cari]['qty']+1;
					      }
					    }
					    this.setState({
					      cartLayanan: temp_cart,
					      // label_produk: temp_label_produk
					    }, () =>{
					    	// console.log('cart', this.state.cart)
					    }); 
					    var temp_total = 0;
					    this.state.cartLayanan.map(item => {
					      temp_total += (item['harga']*item['qty']);
					    })
					    this.setState({
					      totalLayanan: temp_total,
					      total_finalLayanan: temp_total,
					      barcodeTextLayanan: ''
					    });
					});
					this.resetToAdd();
				}else{
					this.setState({
						barcodeLayananIsNotFound: true,
						barcodeLayananResults: null,
						loadingBarcodeLayanan: false,
					    barcodeTextLayanan: ''
					});
					this.resetToAdd();
				}
				// this.barcodeRef.current.val = undefined;
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg,
	        		loadingBarcodeLayanan: false,
	        		barcodeLayananIsNotFound: false,
					barcodeTextLayanan: ''
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        	this.resetToAdd();
	        }
	        this.setState({loadingProduk: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingBarcode: false});
			this.resetToAdd();
		});
	}

	async pilihLayanan(id){
		// this.setState({loadingBarcode: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.produk+'/'+id)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				if(data != undefined){
					this.setState({
						barcodeLayananIsNotFound: false,
						barcodeLayananResults: data,
						loadingBarcodeLayanan: false
					}, () =>{
						let temp_cart = this.state.cartLayanan;
					    // let temp_label_produk = this.state.label_produk;
					    let that = this;
					    if(temp_cart.length==0){
					      let produk = this.state.barcodeLayananResults;
					      // let harga = 0;
					      // let diskon_rupiah = 0;
					      // if(produk['diskon']!="" && produk['diskon'].rupiah>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty){
					      // 	console.log('tes_produk', produk);
					      // 	// console.log('tes_diskon_rupiah', produk['diskon'].rupiah)
					      // 	// diskon_rupiah = produk['diskon'].rupiah;
					      // }
					      
					      temp_cart.push({
					          id: produk['id'],
					          nama: produk['nama'],
					          harga: produk['harga'],
					          qty: 1
					      });
					      // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US');
					    }else{
					      const index_cari = temp_cart.findIndex(data => data.id==this.state.barcodeLayananResults.id);
					      if(index_cari==-1){
					        let produk = this.state.barcodeLayananResults;
					        // let harga = 0;
					        
					        temp_cart.push({
					        	id: produk['id'],
						        nama: produk['nama'],
						        harga: produk['harga'],
						        qty: 1,						        
					        });
					        // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US');
					      }else{
					        let produk = this.state.barcodeLayananResults;
					        // let harga = 0;
					        
					        // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US')+' x '+(temp_cart[index_cari]['qty']+1);
					        temp_cart[index_cari]['qty'] = temp_cart[index_cari]['qty']+1;
					      }
					    }
					    this.setState({
					      cartLayanan: temp_cart,
					      // label_produk: temp_label_produk
					    }, () =>{
					    	// console.log('cart', this.state.cart)
					    }); 
					    var temp_total = 0;
					    this.state.cartLayanan.map(item => {
					      temp_total += (item['harga']*item['qty']);
					    })
					    this.setState({
					      totalLayanan: temp_total,
					      total_finalLayanan: temp_total,
					      barcodeTextLayanan: ''
					    }, () =>{
					    	this.formRefLayanan.current.setFieldsValue({
					    		produks: undefined
					    	});
					    });
					});
				}else{
					this.setState({
						barcodeLayananIsNotFound: true,
						barcodeLayananResults: null,
						loadingBarcodeLayanan: false,
					    barcodeTextLayanan: ''
					});
				}
				// this.barcodeRef.current.val = undefined;
						this.resetToAdd();
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg,
	        		loadingBarcodeLayanan: false,
	        		barcodeLayananIsNotFound: false,
					barcodeTextLayanan: ''
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingProduk: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingBarcode: false});
		});
	}

	removeLayanan(id, nama, isExisting){
			Modal.confirm({
		    title: 'Konfirmasi Hapus?',
		    icon: <ExclamationCircleOutlined />,
		    content: 'yakin menghapus item : '+nama+'?',
		    okText: 'Ya',
		    okType: 'danger',
		    cancelText: 'Tidak',
		    onOk: () => this.prosesHapusItem(id, isExisting),
		    onCancel() {
		      //console.log('Cancel');
		    },
		  });
	}

	prosesHapusItem(id, isExisting){
			const temp_cart = this.state.cartLayanan;
			var index_cari;
			if(isExisting){
				index_cari = temp_cart.findIndex(data => data.idx==id);
				this.hapusExisting(id);
			}else{
				index_cari = temp_cart.findIndex(data => data.id==id);
				temp_cart.splice(index_cari, 1);
		    
		    this.setState({
		      cartLayanan: temp_cart
		    }); 
		    var temp_total = 0;
		    this.state.cartLayanan.map(item => {
		      // temp_total += (item['harga']*item['qty']);
		      temp_total += (parseFloat(item['harga'])*parseFloat(item['qty']));
		    })
		    this.setState({
		      totalLayanan: temp_total,
		      total_finalLayanan: temp_total,
		    });
			}
	}

	setQtyLayanan(e, id){
	    const temp_cart = this.state.cartLayanan;
	    const index_cari = temp_cart.findIndex(data => data.id==id);
	    if(e.target.value=='0'){
	      temp_cart.splice(index_cari, 1);
	    }else{
	      temp_cart[index_cari]['qty'] = parseFloat(e.target.value);
	    }
	    this.setState({
	      cartLayanan: temp_cart
	    }); 
	    var temp_total = 0;
	    this.state.cartLayanan.map(item => {
	      // temp_total += (item['harga']*item['qty']);
	      temp_total += (parseFloat(item['harga'])*parseFloat(item['qty']));
	    })
	    this.setState({
	      totalLayanan: temp_total,
	      total_finalLayanan: temp_total,
	    });
	}

	handleSubmitLayanan = (values) =>{
		var datas = values;
		var detail = [];
		this.state.cartLayanan.map(item => {
			detail = [...detail, {
				produk: item.id,
				quantity: item.qty,
				keterangan: '',
				transaksi: this.props.transaksi[0].id
			}];
		});

		// datas = {detail};

		// console.log('detail', detail);
		this.postDataLayanan({'detail': detail});

	}

	async hapusExisting(id) {
		this.setState({
			errTitle: '',
			errMsg: '',
		});

		message.loading({ content: 'Memproses...', duration: 0, key: 'toast' });

		var that = this;

		this.props.ajaxHandler('del', configClass.apiUrl.detail_transaksi+id, null, false, true)
		.then(() =>{
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menghapus item', duration: 2, key: 'toast' });

				const temp_cart = this.state.cartLayanan;
				const index_cari = temp_cart.findIndex(data => data.id==id);
				temp_cart.splice(index_cari, 1);
			    
			  this.setState({
			    cartLayanan: temp_cart
			  }); 
			  var temp_total = 0;
			  this.state.cartLayanan.map(item => {
			    // temp_total += (item['harga']*item['qty']);
			    temp_total += (parseFloat(item['harga'])*parseFloat(item['qty']));
			  })
			  this.setState({
			    totalLayanan: temp_total,
			    total_finalLayanan: temp_total,
			  });
			}else{
				const errTitle = 'gagal menghapus item'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_del', response);
			this.setState({
				loadingSimpanLayanan: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	async postDataLayanan(datas) {
		this.setState({
			errTitle: '',
			errMsg: '',
			loadingSimpanLayanan: true
		});

		message.loading({ content: 'Memproses...', duration: 0, key: 'toast' });

		var that = this;

		this.props.ajaxHandler(this.state.method, configClass.apiUrl.detail_transaksi, datas, false, true)
		.then(() =>{
			this.setState({
				loadingSimpanLayanan: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menyimpan data', duration: 2, key: 'toast' });
				this.setState({
					isFinish: true
				});
			}else{
				const errTitle = 'gagal menyimpan data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_post', response);
			this.setState({
				loadingSimpanLayanan: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	resetToAdd(){
		this.formRefLayanan.current.setFieldsValue({
			barcode: undefined,
			produks: undefined
		});
	}

	reset(){
		this.setState({
			cartLayanan: [],
			barcodeLayananIsNotFound: false,
			barcodeLayananResults: null,
			totalLayanan: 0,
			total_finalLayanan: 0,
			barcodeTextLayanan: undefined,
		});
	}

	render() {
		return(
			<div>
				<Form 
														layout="vertical"
														name="flayanan"
													    onFinish={this.handleSubmitLayanan}
													    size="small"
													    ref={this.formRefLayanan}
													    initialValues={{
													    	jenis_layanan: this.state.jenis_layanan
													    }}
													>
												    	<Row>
												    		<Col span={12} style={{fontSize: 20, fontWeight: 'bold', fontStyle: 'italic', textAlign: 'left', marginBottom: 10, color: 'blue'}}>
												    			{this.state.barcodeLayananResults != null
												    				?
												    					(this.state.jenis_layanan == 'barcode' && this.state.barcodeLayananResults != null && this.state.barcodeLayananResults.length > 0 ? this.state.barcodeLayananResults[0].nama : this.state.barcodeLayananResults.nama)
												    				:
												    					''	
												    			}
												    		</Col>
												    		<Col span={12} style={{fontSize: 20, fontWeight: 'bold', textAlign: 'right', marginBottom: 10}}>
												    			{'Total Rp. '+parseFloat(this.state.total_finalLayanan).toLocaleString('en-us')}
												    		</Col>
																<Col span={24}>
																<Input.Group compact>
																	<Form.Item
														            	name="jenis_layanan"
														            	style={{width: '15%'}}
														            >
														            	<Select
																			showSearch
																			placeholder="pilih jenis"
																			optionFilterProp="children"
																			style={{
																				width: '100%'
																			}}
																			onChange={(val) =>{
																				this.setState({
																					jenis_layanan: val
																				}, () =>{
																					this.resetToAdd();
																					// this.formRef.pasien.current.focus({cursor: 'start'});
																					// this.formRefLayanan.current.setFieldsValue({
																					// 	produk: undefined
																					// });
																				});
																			}}
																			size={"large"}
																		>
																			<Select.Option value={"barcode"}><BarcodeOutlined /> barcode</Select.Option>
																			<Select.Option value={"produk"}><UserOutlined /> produk</Select.Option>
																		</Select>
														            </Form.Item>
														            {(this.state.jenis_layanan == 'barcode' &&
														            	<Form.Item
															            	name="barcode"
															            	style={{width: '75%'}}
															            >
																			<Input 
																				autoFocus
																		      	size="large" 
																		      	placeholder="scan barcode disini" 
																		      	className="penjualan-cart-barcode" 
																		      	onKeyPress={event => {
																	                if (event.key === 'Enter') {
																	                	event.preventDefault();
																	                	this.scanLayanan(event.target.value);
																	                }
																	            }}
																	            style={{textTransform: 'none'}}
																		    />
																	    </Form.Item>
														            )}
														            {((this.state.jenis_layanan == 'produk') &&
														            	<Form.Item
															            	name="produks"
															            	style={{width: '75%'}}
															            >
																	    	{this.generateSelectProdukLayanan('Layanan', configClass.apiUrl.produk+'?tipe=1', 'id', 'nama')}
																	    </Form.Item>
														            )}
														            <Button 
														            	style={{width: '10%'}}
																		key="btn-simpan-layanan"
																		loading={this.state.loadingSimpanLayanan}
																		type="primary" 
																		htmlType="submit"
																		disabled={this.state.cartLayanan.length==0 || this.state.isFinish==true ? true : false}
																		size="large"
																	>
																		{(!this.state.loadingSimpanLayanan && <SaveOutlined />)}
																		Simpan
																	</Button>
														        </Input.Group>
															</Col>
														</Row>
													</Form>
													<PerfectScrollbar className="panel-cart">
														<Row style={{borderBottom: '1px solid #cccccc', padding: 10, fontWeight: 'bold'}}>
															<Col span={3}>DEL</Col>
															<Col span={4}>QTY</Col>
															<Col span={9}>NAMA LAYANAN</Col>
															<Col span={4}>@HARGA</Col>
															<Col span={4}>SUB TOTAL</Col>
														</Row>
														{this.state.cartLayanan.length == 0
															?
																<Result
																    icon={<WarningOutlined style={{color: '#f9af4d'}} />}
																    title={
																    	<span style={{color: '#f9af4d'}}>
																    		belum ada layanan dipilih!
																    	</span>
																    }
																 />
															:
																this.state.cartLayanan.map((item, idx) => (
																	<Row style={{borderBottom: '1px solid #cccccc', padding: 10}}>
																		<Col span={3}>
																			<Button
																				disabled={this.state.isFinish==true ? true : false}
																				size="large"
																				className="btn-delete" 
																				type="text"
																				onClick={(e) => item.idx != undefined ? this.removeLayanan(item.idx, item.nama, true) : this.removeLayanan(item.id, item.nama, false)}
																			>
																				<DeleteOutlined />
																			</Button>
																		</Col>
																		<Col span={4}>
																			<Input 
																				size="large"
																				value={item.qty} 
																				style={{
																					width: 40,
																					display: 'inline-block'
																				}}
																				onChange={(e) => this.setQtyLayanan(e, item.id)} 
																				disabled={this.state.isFinish==true ? true : false}
																			/>
																		</Col>
																		<Col span={9}>
																			{item.nama}
																		</Col>
																		<Col span={4}>
																			{'Rp. '+parseFloat(item.harga).toLocaleString('en-us')}
																		</Col>
																		<Col span={4}>
																			{'Rp. '+parseFloat(item.harga*item.qty).toLocaleString('en-us')}
																		</Col>
																	</Row>
																))
														}
													</PerfectScrollbar>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(Layanan);