import React from 'react';
import { Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert
} from 'antd';
import {
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  ToolOutlined,
  RedoOutlined
} from '@ant-design/icons';

import axios from 'axios';
import moment from 'moment';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";

import Config from '../../Config';
import {Helmet} from "react-helmet";

const configClass = {
	title: 'data diskon',
	apiUrl: {
		data: 'pengaturan/api/diskon/'
	},
	pageUrl: {
		form: '/admin/diskon/form/'
	},
	breadCrumb: [
		(
			<span>
				<ToolOutlined /> pengaturan
			</span>
		),
		'diskon'
	]

}

const config = new Config();

class DataDiskon extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			errMsg: '',
			loadingData: false,
			pagination: {pageSize: 25},
			searchText: '',
			searchColumn: '',
			dataSource: [],
			columns: [
				{
					title: 'PRODUK',
					width: 150,
					dataIndex: 'produk_detail',
					key: 'produk_detail',
					render: (value, row, index) => {
						return value!="" ? value.nama : '-'
					}
				},
				{
					title: 'DISKON RUPIAH',
					width: 150,
					dataIndex: 'rupiah',
					key: 'rupiah',
					render: (value, row, index) => {
						return parseFloat(value).toLocaleString('en-us')
					}
				},
				{
					title: 'DISKON PERSEN',
					width: 150,
					dataIndex: 'persen',
					key: 'persen',
					render: (value, row, index) => {
						return value
					}
				},
				{
					title: 'MIN QTY',
					width: 100,
					dataIndex: 'min_qty',
					key: 'min_qty'
				},
				/*
				{
					title: 'MAX QTY',
					width: 100,
					dataIndex: 'max_qty',
					key: 'max_qty'
				},
				*/
				{
					title: 'CREATED',
					width: 150,
					align: 'center',
					dataIndex: 'created',
					key: 'created',
					render: (value, row, index) => {
						return moment(value).format('DD-MM-YYYY hh:mm:ss')
					}
				},
				{
					title: 'TGL MULAI',
					width: 150,
					align: 'center',
					dataIndex: 'tgl_start',
					key: 'tgl_start',
					render: (value, row, index) => {
						return moment(value).format('DD-MM-YYYY hh:mm:ss')
					}
				},
				{
					title: 'TGL SELESAI',
					width: 150,
					align: 'center',
					dataIndex: 'tgl_end',
					key: 'tgl_end',
					render: (value, row, index) => {
						return moment(value).format('DD-MM-YYYY hh:mm:ss')
					}
				},
				{
					title: 'AKSI',
					width: 80,
					dataIndex: 'id',
					key: 'aksi',
					fixed: 'right',
					render: (value, row, index) => {
						return (
							<Row key={"space-aksi-"+index} justify="center">
								<Col style={{padding: 5}}>
									<Link to={configClass.pageUrl.form+value}>
										<Button className="btn-edit" size="small" shape="circle">
											<FormOutlined />
										</Button>
									</Link>
								</Col>
								<Col style={{padding: 5}}>
									<Button className="btn-delete" size="small" onClick={() => this.showDeleteConfirm(value)} shape="circle">
										<CloseSquareOutlined />
									</Button>
								</Col>
							</Row>
						)
					},
				}

			]
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.getData();
	}

	showDeleteConfirm(id) {
	  Modal.confirm({
	    title: 'Konfirmasi Hapus?',
	    icon: <ExclamationCircleOutlined />,
	    content: 'yakin menghapus data?',
	    okText: 'Ya',
	    okType: 'danger',
	    cancelText: 'Tidak',
	    onOk: () => this.prosesHapus(id),
	    onCancel() {
	      //console.log('Cancel');
	    },
	  });
	}

	prosesHapus(id) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('delete', configClass.apiUrl.data+id+'/')
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menghapus data', duration: 2, key: 'toast' });
				this.getData();
			}else{
				const errTitle = 'gagal menghapus data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_delete', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	getColumnSearchProps = dataIndex => ({
	    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
	      <div style={{ padding: 8 }}>
	        <Input
	          ref={node => {
	            this.searchInput = node;
	          }}
	          placeholder={`Search ${dataIndex}`}
	          value={selectedKeys[0]}
	          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
	          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          style={{ width: 188, marginBottom: 8, display: 'block' }}
	        />
	        <Button
	          type="primary"
	          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
	          icon={<SearchOutlined />}
	          size="small"
	          style={{ width: 90, marginRight: 8 }}
	        >
	          Search
	        </Button>
	        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
	          Reset
	        </Button>
	      </div>
	    ),
	    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	    onFilter: (value, record) =>
	      record[dataIndex]
	        .toString()
	        .toLowerCase()
	        .includes(value.toLowerCase()),
	    onFilterDropdownVisibleChange: visible => {
	      if (visible) {
	        setTimeout(() => this.searchInput.select());
	      }
	    },
	    render: text =>
	      this.state.searchedColumn === dataIndex ? (
	        <Highlighter
	          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
	          searchWords={[this.state.searchText]}
	          autoEscape
	          textToHighlight={text.toString()}
	        />
	      ) : (
	        text
	      ),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
	    confirm();
	    this.setState({
	      searchText: selectedKeys[0],
	      searchedColumn: dataIndex,
	    });
	};

	handleReset = clearFilters => {
	    clearFilters();
	    this.setState({ searchText: '' });
	};

	async getData(limit=this.state.pagination.pageSize, offset=0, search=this.state.searchText){
		this.setState({loadingData: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.data+'?limit='+limit+'&offset='+offset+'&search='+search)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				const pagination = { ...this.state.pagination };
				pagination.total = data.count;
				this.setState({
					dataSource: data.results,
					pagination
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingData: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingData: false});
		});
	}

	handleTableChange = async (pagination, filters, sorter) =>{
    	const newPagination = { ...this.state.pagination };
    	newPagination.pageSize = pagination.pageSize;
		this.setState({
			loadingData: true,
			pagination
		});
		if(pagination.current==1){
			this.getData(pagination.pageSize);
		}else{
			this.getData(pagination.pageSize, (pagination.current - 1)*pagination.pageSize);
		}

		
	}

	render() {
		// if(this.props.isSuperadmin == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Row className="isi">
				       	<Col span={24}>
				        	<Card
				        		className="konten"
					            title={
					            	<Row>
					            		<Col lg={{ span: 20 }} xs={{ span:24 }}>
							            	<span className="konten-judul">
							            		{configClass.title}
							            		{(this.state.loadingData &&
									        		<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="30" alt="loading" />
									        	)}
							            	</span>
							            </Col>
						            	<Col lg={{ span: 4 }} sm={{ span: 24 }} xs={{ span:24 }} style={{textAlign: 'right'}}>
						            		<Button 
										        	loading={this.state.loadingButton}
										        	className="btn-refresh"
										        	size="small"
										        	onClick={() => this.getData()} 
										        >
										          <RedoOutlined />
										    </Button>
						            		<Link to={configClass.pageUrl.form}>
							            		<Button 
										        	loading={this.state.loadingButton}
										        	className="btn-primary"
										        	size="small" 
										        >
										          <PlusOutlined /> Tambah
										        </Button>
										    </Link>
						            	</Col>
						            </Row>
					            }
					        >
					        	{(this.state.errMsg!='' &&
						        	<Alert
								      message={this.state.errTitle}
								      description={JSON.stringify(this.state.errMsg)}
								      type="error"
								      showIcon
								      style={{
								      	marginBottom: 10
								      }}
								    />
								)}
					        	<Table 
					        		title={() => 
					        			<Row>
					        				<Col lg={8} sm={24} xs={24}><span className="konten-judul">Total {this.state.pagination.total} record</span></Col>
											<Col lg={{span: 8, offset: 8}} sm={24} xs={24}>
												<Input.Search 
													size="small"
													placeholder="ketikkan pencarian" 
													onSearch={value => {
														this.setState({
															searchText: value
														}, () =>{
																this.getData();
														});
													}} 
													allowClear
												/>										
											</Col>
										</Row>					        			
					        		}
					        		bordered
					        		columns={this.state.columns} 
					        		dataSource={this.state.dataSource} 
					        		loading={this.state.loadingData} 
					        		scroll={{ x: 240, y: 450 }}
					        		size="small"
					        		pagination={this.state.pagination}
					        		onChange={this.handleTableChange}
					        	/>
					        </Card>
				        </Col>
				    </Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(DataDiskon);