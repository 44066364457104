import React from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../store/actions";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Input,
	Button,
	Table,
	Modal,
	message,
	Alert,
	Select,
	Tooltip,
	Spin,
	Form,
	Skeleton
} from 'antd';

import moment from 'moment';

import Config from '../Config';
import {Helmet} from "react-helmet";
import queryString from 'query-string';


const configClass = {
	title: 'cetak nota pembayaran',
	apiUrl: {
		preview: 'transaksi/api/transaksi/',
		userProfile: "auth/api/user/info"
	},

}

class CetakNota extends React.Component {
	config;
	
	constructor(props){
	    super(props);
	    this.config = new Config();
	    this.state = {
	    	loadingCart: false,
	    	dataCart: null,
	    	nama_outlet: this.config.get_site_info().name,
	    	nama_user: '',
	    	alamat_outlet: '',
	    }

	    // this.preview(this.props.match.params.id);
	    
	}

	componentDidMount(){
		this.preview(this.props.match.params.id);
	}

	async preview(id) {
		message.loading({ content: 'Menampilkan nota...', duration: 0, key: 'toast' });
		this.setState({
			loadingCart: true
		});

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview+id+'/cetak')
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				message.destroy();
				this.setState({
					loadingCart: false,
					dataCart: data
				});
				setTimeout(function(){
					window.print();

				}, 3000);
	        }else{
	        	const errTitle = 'error preview data lama';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	render() {		
    	return (
			  <div id="invoice-POS">
			  		<Helmet>
			  			<title>cetak nota pembayaran</title>
			  		</Helmet>
			    
				    <center id="top">
				      <div class="logo"></div>
				      <div class="info"> 
				        <span className="nama-outlet">{this.state.dataCart != null && this.state.dataCart.cabang_nama}</span><br />
				        <span className="alamat"></span>
				      </div>
				    </center>
				    
				    <Row className="panel-info">
				    	<Col span={18}>
				    		<Row>
						    	<Col span={8}>No</Col>
						    	<Col span={16}>{this.state.dataCart != null && ': '+this.state.dataCart.code}</Col>
						    	<Col span={8}>Customer</Col>
						    	<Col span={16}>{this.state.dataCart != null && this.state.dataCart.pasien_nama != undefined ? ': '+this.state.dataCart.pasien_nama : ': -'}</Col>
						    	<Col span={8}>Kasir</Col>
						    	<Col span={16}>{this.state.dataCart != null && ': '+this.state.dataCart.kasir_nama}</Col>
						    </Row>
					    </Col>
					    <Col span={6}>
					    	<Row>
					    		<Col span={24}>
					    			{this.state.dataCart != null && moment(this.state.dataCart.tanggal).format('DD-MM-YYYY')}
					    		</Col>
					    	</Row>
					    </Col>
				    </Row>
				    
				    <div id="bot">
						<div id="table">
							<table>
								<tr className="invoice-column-name">
									<td className="label-item">Item</td>
									<td className="label-qty">Qty</td>
									<td className="label-harga">Harga</td>
									<td className="label-subtotal">Subtotal</td>
								</tr>
								{this.state.loadingCart 
									?
										<tr>
											<td colspan="4">
												<Skeleton active />
											</td>
										</tr>
									:
										
										
										this.state.dataCart != null && this.state.dataCart != undefined && this.state.dataCart.transaksi.map((item, idx) => (
											<tr class="service">
												<td className="tableitem itemtext" valign="top">{item.produk_nama}</td>
												<td className="tableitem itemtext" valign="top" align="center">{parseFloat(item.quantity)}</td>
												<td className="tableitem itemtext" valign="top" align="center">{parseFloat(item.sub_total).toLocaleString('en-us')}</td>
												<td className="tableitem itemtextright" valign="top">
														{parseFloat(item.harga * item.quantity).toLocaleString('en-us')}
												</td>
											</tr>
										))
										
								}
								<tr className="invoice-summary-panel-up">
									<td colspan="3" className="right-bold">Total</td>
									<td className="right-thin">{this.state.dataCart != null && (parseFloat(this.state.dataCart.total_transaksi)).toLocaleString('en-us')}</td>
								</tr>
								<tr className="invoice-summary-panel">
									<td colspan="3" className="right-bold">Total Bayar ({this.state.dataCart != null && this.state.dataCart.payment_type_nama})</td>
									<td className="right-thin">{this.state.dataCart != null && parseFloat(this.state.dataCart.total_bayar).toLocaleString('en-us')}</td>
								</tr>
								{(this.state.dataCart!=null && this.state.dataCart.payment_type == 1 &&
									<tr className="invoice-summary-panel">
										<td colspan="3" className="right-bold">{'Kembalian'}</td>
										<td className="right-thin">{this.state.dataCart != null && (parseFloat(this.state.dataCart.total_bayar) - parseFloat(this.state.dataCart.total_transaksi)).toLocaleString('en-us')}</td>
									</tr>
								)}
							</table>
						</div>

						<div id="legalcopy">
							<p class="legal">Barang Yang sudah Di beli Tidak bisa Ditukar kembali.<br />Harga sudah termasuk PPN</p>
						</div>
					</div>
			   </div>

    	)
    }

}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler,
			setConfig
		}
	)(CetakNota);