import React from "react";
import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Card,
  Row,
  Col,
  Input,
  Button,
  Table,
  Modal,
  message,
  Alert,
  Select,
  Spin,
  Form,
  Skeleton,
  Result
} from "antd";
import {
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  RedoOutlined,
  ShoppingCartOutlined,
  FileSearchOutlined,
  CheckCircleOutlined,
  WarningOutlined
} from "@ant-design/icons";
import NumberFormat from "react-number-format";

import PerfectScrollbar from "react-perfect-scrollbar";

import axios from "axios";

import moment from "moment";

import { connect } from "react-redux";
import {
  initHandler,
  ajaxViewHandler,
  ajaxHandler,
  setConfig,
} from "../../store/actions";

import Config from "../../Config";
import { Helmet } from "react-helmet";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

const configClass = {
  title: "data stock",
  apiUrl: {
    data: "stock/api/stock/",
  },
  pageUrl: {
    form: "/admin/pembelian/",
  },
  breadCrumb: [
    <span>
      <ShoppingCartOutlined /> pembelian
    </span>,
    "data stock",
  ],
};

const config = new Config();

class DataStock extends React.Component {
  formRefPembayaran = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      errMsg: "",
      loadingData: false,
      pagination: { pageSize: 25 },
      searchText: "",
      searchColumn: "",
      dataSource: [],
      columns: [
        {
          title: "PRODUK",
          width: 150,
          dataIndex: "produk_nama",
          key: "produk_nama",
        },
        {
          title: "QTY",
          width: 150,
          dataIndex: "quantity",
          key: "quantity",
          render: (value, row, index) => {
            return parseFloat(value);
          },
        },
      ],
    };
  }

  UNSAFE_componentWillMount() {
    this.props.initHandler();
  }

  componentDidMount() {
    this.getData();
  }

  async getData(
    limit = this.state.pagination.pageSize,
    offset = 0,
    search = this.state.searchText
  ) {
    this.setState({ loadingData: true });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler(
        "get",
        configClass.apiUrl.data +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          "&search=" +
          search
      )
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          const pagination = { ...this.state.pagination };
          pagination.total = data.count;
          this.setState({
            dataSource: data.results,
            pagination,
          });
        } else {
          const errTitle = "error get data";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
        this.setState({ loadingData: false });
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({ loadingData: false });
      });
  }

  handleTableChange = async (pagination, filters, sorter) => {
    const newPagination = { ...this.state.pagination };
    newPagination.pageSize = pagination.pageSize;
    this.setState({
      loadingData: true,
      pagination,
    });
    if (pagination.current == 1) {
      this.getData(pagination.pageSize);
    } else {
      this.getData(
        pagination.pageSize,
        (pagination.current - 1) * pagination.pageSize
      );
    }
  };

  render() {
    // if(this.props.isSuperadmin == false){
    // 	return <Redirect to="/admin/forbidden" />
    // }
    return (
      <div>
        <Helmet>
          <title>
            {configClass.title + " | " + config.get_site_info().name}
          </title>
        </Helmet>
        <Breadcrumb>
          {configClass.breadCrumb.map((item) => (
            <Breadcrumb.Item>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className="card">
          <Row className="isi">
            <Col span={24}>
              <Card
                className="konten"
                title={
                  <Row>
                    <Col lg={{ span: 20 }} xs={{ span: 24 }}>
                      <span className="konten-judul">
                        {configClass.title}
                        {this.state.loadingData && (
                          <img
                            src={process.env.PUBLIC_URL + "/loading_crop.gif"}
                            height="30"
                            alt="loading"
                          />
                        )}
                      </span>
                    </Col>
                    <Col
                      lg={{ span: 4 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        loading={this.state.loadingButton}
                        className="btn-refresh"
                        size="small"
                        onClick={() => this.getData()}
                      >
                        <RedoOutlined />
                      </Button>
                      
                    </Col>
                  </Row>
                }
              >
                {this.state.errMsg != "" && (
                  <Alert
                    message={this.state.errTitle}
                    description={JSON.stringify(this.state.errMsg)}
                    type="error"
                    showIcon
                    style={{
                      marginBottom: 10,
                    }}
                  />
                )}
                <Table
                  title={() => (
                    <Row>
                      <Col lg={8} sm={24} xs={24}>
                        <span className="konten-judul">
                          Total {this.state.pagination.total} record
                        </span>
                      </Col>
                      <Col lg={{ span: 8, offset: 8 }} sm={24} xs={24}>
                        <Input.Search
                          size="small"
                          placeholder="ketikkan pencarian"
                          onSearch={(value) => {
                            this.setState(
                              {
                                searchText: value,
                              },
                              () => {
                                this.getData();
                              }
                            );
                          }}
                          allowClear
                        />
                      </Col>
                    </Row>
                  )}
                  bordered
                  columns={this.state.columns}
                  dataSource={this.state.dataSource}
                  loading={this.state.loadingData}
                  scroll={{ x: 240, y: 450 }}
                  size="small"
                  pagination={this.state.pagination}
                  onChange={this.handleTableChange}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  initHandler,
  setConfig,
  ajaxViewHandler,
  ajaxHandler,
})(DataStock);
