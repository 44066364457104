import React, { PureComponent } from 'react'
import { Redirect, Link } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Avatar,
	Form, 
	Input,
	Button,
	Space,
	Select,
	DatePicker,
	message,
	Alert,
	Spin,
	Skeleton
} from 'antd';
import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../store/actions";
import {Helmet} from "react-helmet";
import '../assets/invoice.css';
import Config from '../Config';
import moment from 'moment';

import QRCode from 'qrcode.react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from './Logo';


const configClass = {
	title: 'cetak resep',
	apiUrl: {
		// preview: "transaksi/api/transaksi/",
		preview: "transaksi/api/detail/transaksi/",
		userProfile: "auth/api/user/info"
	}
}

class CetakResep extends PureComponent {
	config;
	
	constructor(props){
	    super(props);
	    this.config = new Config();
	    this.state = {
	    	loading: true,
	    	data_registrasi: null,
	    	dataResult: null,
	    }

	    // this.preview(this.props.match.params.id);
	    
	}

	componentDidMount(){
		this.preview(this.props.match.params.id);
	}

	async preview(id) {
		message.loading({ content: 'Menampilkan data...', duration: 0, key: 'toast' });

		this.setState({
			errTitle: '',
			errMsg: '',
			loading: true
		});

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview+id+'/cetak')
		.then(() =>{
			const data = this.props.responseMsg;
			var that = this;
			if(this.props.isSuccess){
				message.destroy();
				this.setState({
					loading: false,
					data_registrasi: data
				}, () =>{
					setTimeout(function(){
						// window.print();
						that.jsPdfGenerator();

					}, 100);
				});
	        }else{
	        	const errTitle = 'error preview data registrasi';
	        	this.setState({
	        		loading: false,
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data registrasi, coba lagi', duration: 2, key: 'toast' });
			this.setState({
				loading: false
			});
		});
	}

	jsPdfGenerator = () => {
        // Example From https://parall.ax/products/jspdf
        const pjg = this.config.cm_to_pixel(5);
        const lbr = this.config.cm_to_pixel(2);
        var doc = new jsPDF('l', 'pt', [pjg, lbr]);

        var counter = 1;
        this.state.data_registrasi.transaksi.map((item, idx) => {
        	console.log('item', item);
        	if(item.tipe == "2"){
	        	let x = this.config.cm_to_pixel(0.36);
	        	let y = this.config.cm_to_pixel(0.36);

	        	if(counter>1){
					doc.addPage();
					// x = this.config.cm_to_pixel(0.36);
	    //     		y = this.config.cm_to_pixel(0.36);
				}

		        // const splitNamaPasien = doc.splitTextToSize(this.state.data_registrasi.pasien_nama.substring(0, 50), this.config.cm_to_pixel(5));
		        doc.setFontType('bold');
		        doc.setFontSize(12);
				doc.text(x, y, this.state.data_registrasi.pasien_nama.substring(0, 30));

		  		// doc.addImage(Logo, 'PNG', this.config.cm_to_pixel(3+0.36), 2.2, this.config.cm_to_pixel(1.5), this.config.cm_to_pixel(1.5));

				y+=this.config.cm_to_pixel(0.3);

		        // const splitNamaObat = doc.splitTextToSize(item.produk_nama.substring(0, 50), this.config.cm_to_pixel(7));
		        doc.setFontType('normal');
		        doc.setFontSize(8);
				doc.text(x, y, item.produk_nama.substring(0, 40));

				y+=this.config.cm_to_pixel(0.3);

				var dosis = item.dosis_nama;
				const splitDosis = doc.splitTextToSize(dosis, this.config.cm_to_pixel(7));
				// const splitDosis = item.keterangan;
		        doc.setFontType('normal');
		        doc.setFontSize(8);
				doc.text(x, y, splitDosis);

				y+=this.config.cm_to_pixel(0.3);

				var aturan_makan = item.aturan_makan_nama;
				const splitAturanMakan = doc.splitTextToSize(aturan_makan, this.config.cm_to_pixel(7));
				// const splitDosis = item.keterangan;
		        doc.setFontType('normal');
		        doc.setFontSize(8);
				doc.text(x, y, splitAturanMakan);

				y+=this.config.cm_to_pixel(0.3);

				var waktu = item.waktu_nama;
				const splitWaktu = doc.splitTextToSize(waktu, this.config.cm_to_pixel(7));
				// const splitDosis = item.keterangan;
		        doc.setFontType('normal');
		        doc.setFontSize(8);
				doc.text(x, y, splitWaktu);

				
				if(item.keterangan != null && item.keterangan != ''){
					y+=this.config.cm_to_pixel(0.3);

					var keterangan = item.keterangan;
					const splitKeterangan = doc.splitTextToSize(keterangan, this.config.cm_to_pixel(7));
					// const splitDosis = item.keterangan;
			        doc.setFontType('normal');
			        doc.setFontSize(8);
					doc.text(x, y, splitKeterangan);
				}

				counter+=1;
			}
		});


		// var table1Row = [];
	 //    var table2Row = [];
	 //    var tableCounter = 1;
	 //    this.state.data_registrasi.transaksi.map((item, idx) => {
	 //    	table1Row.push([
	 //    		{ content: tableCounter, styles: { halign: 'center', valign: 'middle' } },
	 //    		{ content: item.pasien_nama }
	 //    	]);
	 //    	table1Row.push([
	 //    		{ content: tableCounter, styles: { halign: 'center', valign: 'middle' } },
	 //    		{ content: item.produk_nama.substring(0, 45) }
	 //    	]);
	 //    	table1Row.push([
	 //    		{ content: tableCounter, styles: { halign: 'center', valign: 'middle' } },
	 //    		{ content: item.keterangan.substring(0, 45) }
	 //    	]);
	 //    	tableCounter+=1;
	 //    	if(idx > 0){
	 //    		doc.addPage();
	 //    	}
	 //    });

	 //    doc.autoTable({
	 //    	showHead: 'never',
	 //    	pageBreak: 'avoid',
	 //    	rowPageBreak: 'avoid',
	 //    	startY: y,
	 //    	startX: x,
	 //    	theme: 'plain',
	 //    	// styles: {lineColor: '#000000', lineWidth: 0.001, cellPadding: 0.1, fontSize: 7},
	 //    	// headStyles: { fillColor: false, textColor: '#000000' },
		// 	// head: [['No', 'Nama Lengkap', 'NIK', 'Jenis Kelamin', 'Tempat Lahir', 'Tanggal Lahir', 'Agama', 'Pendidikan', 'Jenis Pekerjaan']],
		// 	bodyStyles: { minCellHeight: 1 },
		// 	body: table1Row
		// });

        // doc.setFontType('bold');
        // doc.setFontSize(14);
        // doc.text(2, 2, 'This is the second title.')
        
        // Save the Data
        this.setState({
        	dataResult: doc.output('datauristring')
        });
        doc.save('resep_'+this.state.data_registrasi.code+'.pdf');
    }

    setCenterText(doc, text, y) {
	    var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
	    var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
	    doc.text(textOffset, y, text);
	}

	render() {		
    	return (
			  <div>
			  		<Helmet>
			  			<title>cetak resep</title>
			  		</Helmet>
			  		<React.Fragment>
			  			<iframe frameBorder={0} src={this.state.dataResult} width={"100%"} height={500} />
			  		</React.Fragment>
			  		{/*this.state.loading 
			  			?
			  				<Skeleton active />
			  			:
			  				this.state.data_registrasi.transaksi.map((item, idx) =>(
			  					(item.keterangan != "" &&
				  					<div className="resep">
				  						<Row>
						  					<Col span={18}>
						  						<div className="resep-nama">
													{this.state.data_registrasi.pasien_nama}
												</div>
						  						<div className="resep-obat">
													{item.produk_nama.substring(0, 45)}
												</div>
												<div className="resep-dosis">
													{item.keterangan}
												</div>
						  					</Col>
						  					<Col span={6}>
						  						<QRCode 
													value={this.state.data_registrasi.code} 
													size={70}
												/>
						  					</Col>
					  					</Row>
					  				</div>
				  				)
			  				))
					*/}
					{/*(this.state.data_registrasi != null &&
						<div res="target">
							<QRCode 
								value={this.state.data_registrasi.code} 
								size={70}
							/>
							<img ref="img"/>
						</div>
					)*/}
			   </div>

    	)
    }


}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(CetakResep);