import React from 'react';
import { Link, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Avatar,
	Form, 
	Input,
	Button,
	Space,
	Select,
	DatePicker,
	message,
	Alert,
	Spin,
	Divider,
	Result,
	Modal, 
	Radio
} from 'antd';
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ShoppingCartOutlined,
  SearchOutlined,
  BarcodeOutlined,
  TagOutlined,
  RightOutlined,
  WarningOutlined,
  TeamOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import {FloatingButton, Item} from "react-floating-button";

import axios from 'axios';
import Config from '../../Config';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import {Helmet} from "react-helmet";

const FormItem = Form.Item

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    }
  },
};

const configClass = {
	title: 'tambah stock titipan',
	apiUrl: {
		pembelian: "transaksi/api/titipbarang/",
		produk: "master/api/produk",
		preview: "master/api/produk/",
		kategori: "master/api/kategori/produk",
		outlet: "master/api/kategori/outlet",
		suplier: 'master/api/suplier/'
	},
	pageUrl: {
		data: '/admin/pembelian/'
	},
	breadCrumb: [
		(
			<span>
				<TeamOutlined /> barang titipan
			</span>
		),
		'tambah stock'
	]

}

const config = new Config();

class FormTitipan extends React.Component {
	formRef = React.createRef();
	barcodeRef = React.createRef();
	formPembayaran = React.createRef();
	no_mejaref = React.createRef();

	constructor(props){
		super(props);

		this.state = {
			errComponent: false,
			errTitle: '',
			errMsg: '',
			loadingButton: false,

			loadingBarcode: false,
			barcodeIsNotFound: false,
			barcodeResults: [],

			loadingProduk: false,
			listProduk: [],
			loadingSuplier: false,
			listSuplier: [],
			
			loadingOutletModal: false,
			listOutletModal: [],
			loadingSuplierModal: false,
			listSuplierModal: [],
			loadingKategoriModal: false,
			listKategoriModal: [],

			loadingCart: [],
			cart: [],

			gambar: null,
			
			aksi: 'tambah',
			method: 'post',
			idLama: '',

			searchText: '',
			barcodeText: '',

			modalPembayaran: false,
			kategori: '',

			total: 0,
			total_final: 0,
			dibayar: 0,
			kekurangan: 0,
			// diskon_persen: '',
			// diskon_rp: '',

			modalProduk: false,
			harga_beli: 0,
			harga_jual: 0,
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.barcodeRef.current.focus();
		this.props.setConfig({
			collapsed: true
		});

		// this.getOutlet();
		this.getSuplier();

		this.loadMasterList();
	}

	loadMasterList(){
		const rule = [
			{
				destination: {
					stateProgressName: 'loadingKategoriModal',
					endPoint: configClass.apiUrl.kategori,
					stateDestination: 'listKategoriModal',
				},
				nextDestination: {
					stateProgressName: 'loadingOutletModal',
					endPoint: configClass.apiUrl.outlet,
					stateDestination: 'listOutletModal',
					next: null
				}
			},
			{
				destination: {
					stateProgressName: 'loadingSuplierModal',
					endPoint: configClass.apiUrl.suplier,
					stateDestination: 'listSuplierModal',
				},
				nextDestination: null
			}
		];

		this.setState({
			errComponent: false
		});

		rule.map((item, index) => {
			if(index == rule.length-1){
				this.getMasterList(item, true);
			}else{
				this.getMasterList(item);
			}
		});
	}

	async getMasterList(rule, isFinal=false) {
		var destination = rule.destination;
		var nextDestination = rule.nextDestination;
		this.setState({[destination.stateProgressName]: true});
		this.props.ajaxViewHandler('get', destination.endPoint)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				if(data.count != undefined){
					let newState = null;
					if(this.state[destination.stateDestination].length == 0){
						newState = data;
					}else{
						if(destination.stateProgressName.includes('_searching')){
							newState = data
						}else{
							const oldState = this.state[destination.stateDestination];
							newState = {
								count: data.count,
								next: data.next,
								previous: data.previous,
								results: [...oldState.results, ...data.results]
							};
						}
					}
					this.setState({
		            	[destination.stateDestination]: newState
		            });
				}else if(data.results != undefined){
	        		this.setState({
		            	[destination.stateDestination]: data.results
		            });
	        	}else{
	        		if(Array.isArray(data)){
			            this.setState({
			            	[destination.stateDestination]: data
			            })
			        }else{
			        	this.setState({
			            	[destination.stateDestination]: [data]
			            })
			        }
		        }
		        if(isFinal){
		        	if(this.props.match.params.id != undefined){
						this.preview(this.props.match.params.id);
					}
		        }
		    }else{
		    	this.setState({
	        		errComponent: true
	        	});
		    }
		    this.setState({[destination.stateProgressName]: false});
		}).catch((response) => {
			//console.log('gagal_get_master', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({
				errTitle: 'gagal request data master',
				errMsg: response
			});
			this.setState({[destination.stateProgressName]: false});
		});
        //next function
        if(nextDestination != null){
        	const next = {
        		destination: {
	        		stateProgressName: nextDestination.stateProgressName,
	        		endPoint: nextDestination.endPoint,
	        		stateDestination: nextDestination.stateDestination,
	        	},
        		nextDestination: nextDestination.next

        	}
        	this.getMasterList(next);
        }
	}

	async scanProduk(barcode){
		this.setState({loadingBarcode: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.produk+'?barcode='+barcode)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				if(data.results.length > 0){
					this.setState({
						barcodeIsNotFound: false,
						barcodeResults: data.results,
						loadingBarcode: false
					}, () =>{
						let temp_cart = this.state.cart;
					    // let temp_label_produk = this.state.label_produk;
					    let that = this;
					    if(temp_cart.length==0){
					      let produk = this.state.barcodeResults[0];
					      // let harga = 0;
					      // let diskon_rupiah = 0;
					      // if(produk['diskon']!="" && produk['diskon'].rupiah>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty){
					      // 	console.log('tes_produk', produk);
					      // 	// console.log('tes_diskon_rupiah', produk['diskon'].rupiah)
					      // 	// diskon_rupiah = produk['diskon'].rupiah;
					      // }
					      
					      temp_cart.push({
					          id: produk['id'],
					          nama: produk['nama'],
					          harga: produk['hpp'],
					          harga_jual: produk['harga'],
					          qty: 1,
					          gambar: produk['gambar'],
					          // diskon_rp: produk['diskon']!="" && produk['diskon'].rupiah>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty ? produk['diskon'].rupiah : 0,
					          // diskon_persen: produk['diskon']!="" && produk['diskon'].persen>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty ? produk['diskon'].persen : 0,
					      });
					      // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US');
					    }else{
					      const index_cari = temp_cart.findIndex(data => data.id==this.state.barcodeResults[0].id);
					      if(index_cari==-1){
					        let produk = this.state.barcodeResults[0];
					        // let harga = 0;
					        
					        temp_cart.push({
					        	id: produk['id'],
						        nama: produk['nama'],
						        harga: produk['hpp'],
						        harga_jual: produk['harga'],
						        qty: 1,
						        gambar: produk['gambar'],
						        // diskon_rp: produk['diskon']!="" && produk['diskon'].rupiah>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty ? produk['diskon'].rupiah : 0,
						        // diskon_persen: produk['diskon']!="" && produk['diskon'].persen>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty ? produk['diskon'].persen : 0,
					        });
					        // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US');
					      }else{
					        let produk = this.state.barcodeResults[0];
					        // let harga = 0;
					        
					        // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US')+' x '+(temp_cart[index_cari]['qty']+1);
					        temp_cart[index_cari]['qty'] = temp_cart[index_cari]['qty']+1;
					        // temp_cart[index_cari]['diskon_rp'] = produk['diskon']!="" && produk['diskon'].rupiah>0 && temp_cart[index_cari]['qty']>=produk['diskon'].min_qty && temp_cart[index_cari]['qty']<=produk['diskon'].max_qty ? produk['diskon'].rupiah : 0;
					        // temp_cart[index_cari]['diskon_persen'] = produk['diskon']!="" && produk['diskon'].persen>0 && temp_cart[index_cari]['qty']>=produk['diskon'].min_qty && temp_cart[index_cari]['qty']<=produk['diskon'].max_qty ? produk['diskon'].persen : 0;
					      }
					    }
					    this.setState({
					      cart: temp_cart,
					      // label_produk: temp_label_produk
					    }, () =>{
					    	// console.log('cart', this.state.cart)
					    }); 
					    var temp_total = 0;
					    this.state.cart.map(item => {
					      temp_total += (item['harga']*item['qty']);
					    })
					    this.setState({
					      total: temp_total,
					      total_final: temp_total,
					      barcodeText: ''
					    });
					});
				}else{
					this.setState({
						barcodeIsNotFound: true,
						barcodeResults: [],
						loadingBarcode: false,
					    barcodeText: ''
					});
				}
				// this.barcodeRef.current.val = undefined;
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg,
	        		loadingBarcode: false,
	        		barcodeIsNotFound: false,
					barcodeText: ''
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingProduk: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingBarcode: false});
		});
	}

	async getProduk(suplier=this.state.suplier_dipilih, limit=50000, offset=0, search=this.state.searchText){
		this.setState({loadingProduk: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.produk+'?suplier='+suplier+'&jns_barang=2&limit='+limit+'&offset='+offset+'&search='+search)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				this.setState({
					listProduk: data.results,
					suplier_dipilih: suplier
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({
	        	loadingProduk: false,
	        	suplier_dipilih: suplier
	        });
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingProduk: false});
		});
	}

	async getProdukById(id){
		this.setState({loadingProduk: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.produk+'/'+id)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				this.setState({
					listProduk: [
						// {
							data
						// }
					],
					modalProduk: false
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({
	        	loadingProduk: false,
	        });
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingProduk: false});
		});
	}

	async getSuplier(limit=50000, offset=0){
		this.setState({loadingSuplier: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.suplier+'?limit='+limit+'&offset='+offset)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				this.setState({
					listSuplier: data.results
				}, () =>{
					// if(data.results.length > 0){
					// 	this.getProduk(data.results[0].id)
					// }
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingSuplier: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingSuplier: false});
		});
	}

	async getOutlet(limit=50000, offset=0, search=this.state.searchText){
		this.setState({loadingOutlet: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.outlet+'?limit='+limit+'&offset='+offset)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				this.setState({
					listOutlet: data.results
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingOutlet: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingOutlet: false});
		});
	}

	kurangi(id){
	    const temp_cart = this.state.cart;
	    const index_cari = temp_cart.findIndex(data => data.id==id);
	    if(temp_cart[index_cari]['qty']==1){//hapus item
	      temp_cart.splice(index_cari, 1);
	    }else{//kurangi item
	      temp_cart[index_cari]['qty'] = temp_cart[index_cari]['qty']-1;
	    }
	    this.setState({
	      cart: temp_cart
	    }); 
	    var temp_total = 0;
	    this.state.cart.map(item => {
	      temp_total += (item['harga']*item['qty']);
	    })
	    this.setState({
	      total: temp_total,
	      total_final: temp_total,
	    });
	    
	}
	tambah(id){
	    const temp_cart = this.state.cart;
	    const index_cari = temp_cart.findIndex(data => data.id==id);
	    temp_cart[index_cari]['qty'] = temp_cart[index_cari]['qty']+1;
	    this.setState({
	      cart: temp_cart
	    }); 
	    var temp_total = 0;
	    this.state.cart.map(item => {
	      temp_total += (item['harga']*item['qty']);
	    })
	    this.setState({
	      total: temp_total,
	      total_final: temp_total,
	    });
	}

	setHarga(values, id){
		const {formattedValue, value} = values;
		const temp_cart = this.state.cart;
	    const index_cari = temp_cart.findIndex(data => data.id==id);
	    
	    temp_cart[index_cari]['harga'] = value;
	    
	    this.setState({
	      cart: temp_cart
	    }); 
	    var temp_total = 0;
	    this.state.cart.map(item => {
	      temp_total += (item['harga']*item['qty']);
	    })
	    this.setState({
	      total: temp_total,
	      total_final: temp_total,
	    });
	}

	setHargaJual(values, id){
		const {formattedValue, value} = values;
		const temp_cart = this.state.cart;
	    const index_cari = temp_cart.findIndex(data => data.id==id);
	    
	    temp_cart[index_cari]['harga_jual'] = value;
	    
	    this.setState({
	      cart: temp_cart
	    });
	}

	setQty(e, id){
	    const temp_cart = this.state.cart;
	    const index_cari = temp_cart.findIndex(data => data.id==id);
	    if(e.target.value=='0'){
	      temp_cart.splice(index_cari, 1);
	    }else{
	      temp_cart[index_cari]['qty'] = e.target.value;
	    }
	    this.setState({
	      cart: temp_cart
	    }); 
	    var temp_total = 0;
	    this.state.cart.map(item => {
	      temp_total += (item['harga']*item['qty']);
	    })
	    this.setState({
	      total: temp_total,
	      total_final: temp_total,
	    });
	}

	addToCart(id){
		let temp_cart = this.state.cart;
	    // let temp_label_produk = this.state.label_produk;
	    let that = this;
	    const index_cari_produk = this.state.listProduk.findIndex(data => data.id==id);
	    if(temp_cart.length==0){
	      let produk = this.state.listProduk[index_cari_produk];
	      // let harga = 0;
	      // let diskon_rupiah = 0;
	      // if(produk['diskon']!="" && produk['diskon'].rupiah>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty){
	      // 	console.log('tes_produk', produk);
	      // 	// console.log('tes_diskon_rupiah', produk['diskon'].rupiah)
	      // 	// diskon_rupiah = produk['diskon'].rupiah;
	      // }
	      
	      temp_cart.push({
	          id: produk['id'],
	          nama: produk['nama'],
	          harga: produk['hpp'],
	          harga_jual: produk['harga'],
	          qty: 1,
	          gambar: produk['gambar'],
	          // diskon_rp: produk['diskon']!="" && produk['diskon'].rupiah>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty ? produk['diskon'].rupiah : 0,
	          // diskon_persen: produk['diskon']!="" && produk['diskon'].persen>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty ? produk['diskon'].persen : 0,
	      });
	      // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US');
	    }else{
	      const index_cari = temp_cart.findIndex(data => data.id==id);
	      if(index_cari==-1){
	        let produk = this.state.listProduk[index_cari_produk];
	        // let harga = 0;
	        
	        temp_cart.push({
	        	id: produk['id'],
		        nama: produk['nama'],
		        harga: produk['hpp'],
		        harga_jual: produk['harga'],
		        qty: 1,
		        gambar: produk['gambar'],
		        // diskon_rp: produk['diskon']!="" && produk['diskon'].rupiah>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty ? produk['diskon'].rupiah : 0,
		        // diskon_persen: produk['diskon']!="" && produk['diskon'].persen>0 && 1>=produk['diskon'].min_qty && 1<=produk['diskon'].max_qty ? produk['diskon'].persen : 0,
	        });
	        // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US');
	      }else{
	        let produk = this.state.listProduk[index_cari_produk];

	        // let harga = 0;
	        
	        // temp_label_produk = produk['nama']+' '+harga.toLocaleString('en-US')+' x '+(temp_cart[index_cari]['qty']+1);
	        temp_cart[index_cari]['qty'] = temp_cart[index_cari]['qty']+1;
	        // let jml = produk['diskon']!="" && produk['diskon'].persen>0 && temp_cart[index_cari]['qty']>=produk['diskon'].min_qty && temp_cart[index_cari]['qty']<=produk['diskon'].max_qty ? produk['diskon'].persen : 0;
	        // // alert(produk['diskon'].min_qty+", "+produk['diskon'].max_qty+", "+jml+", "+temp_cart[index_cari]['qty']);
	        // temp_cart[index_cari]['diskon_rp'] = produk['diskon']!="" && produk['diskon'].rupiah>0 && temp_cart[index_cari]['qty']>=produk['diskon'].min_qty && temp_cart[index_cari]['qty']<=produk['diskon'].max_qty ? produk['diskon'].rupiah : 0;
	        // temp_cart[index_cari]['diskon_persen'] = produk['diskon']!="" && produk['diskon'].persen>0 && temp_cart[index_cari]['qty']>=produk['diskon'].min_qty && temp_cart[index_cari]['qty']<=produk['diskon'].max_qty ? produk['diskon'].persen : 0;
	      }
	    }
	    this.setState({
	      cart: temp_cart,
	      // label_produk: temp_label_produk
	    }, () =>{
	    	// console.log('cart', this.state.cart)
	    }); 
	    var temp_total = 0;
	    this.state.cart.map(item => {
	      temp_total += ((item['harga']*item['qty']));// - item['diskon_rp'] - (((item.harga*item.qty)/100)*item.diskon_persen))
	    })
	    this.setState({
	      total: temp_total,
	      total_final: temp_total,
	      barcodeIsNotFound: false
	    });
	}

	setGambar = (e) => {
	    this.setState({
			'gambar': e.target.files[0]
		});
	}



	async preview(id) {
		message.loading({ content: 'Menampilkan data sebelumnya...', duration: 0, key: 'toast' });

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview+id)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				//this.formRef.current.setFieldsValue(data);
				for ( var key in data ) {
				    const dateRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4}$/;
				    const dateTimeRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;

				    const dateRegex2 = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
				    const dateTimeRegex2 = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
				    if(dateRegex.test(data[key])){//jika format tanggal			    	
				    	this.formRef.current.setFieldsValue({
				    		[key+'_picker']: moment(data[key], 'DD-MM-YYYY'),
							//[key]: moment(data[key], 'DD-MM-YYYY')
						});
				    }else if(dateTimeRegex.test(data[key])){//jika format tanggal dan jam	    	
				    	this.formRef.current.setFieldsValue({
				    		[key+'_picker']: moment(data[key], 'DD-MM-YYYY HH:mm:ss'),
						});
				    }else if(dateRegex2.test(data[key])){//jika format tanggal			    	
				    	this.formRef.current.setFieldsValue({
				    		[key+'_picker']: moment(data[key], 'DD-MM-YYYY'),
							//[key]: moment(data[key], 'DD-MM-YYYY')
						});
				    }else if(dateTimeRegex2.test(data[key])){//jika format tanggal dan jam	    	
				    	this.formRef.current.setFieldsValue({
				    		[key+'_picker']: moment(data[key], 'DD-MM-YYYY HH:mm:ss'),
						});
				    }/*else{
				    	this.formRef.current.setFieldsValue({
				    		[key]: data[key]
				    	});
				    }*/
				    if(key == 'gambar'){
				    	this.setState({
				    		preview_gambar: data[key]
				    	});
				    }else{
				    	this.formRef.current.setFieldsValue({
				    		[key]: data[key]
				    	});
				    }
	        	}
	        	message.destroy();
	        	this.setState({
	        		aksi: 'edit',
	        		method: 'put',
	        		idLama: id+'/'
	        	});
	        }else{
	        	const errTitle = 'error preview data lama';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	handleSubmit = (values) =>{
		// var datas = values;
		var datas = {
		    // "outlet": 1,
		    "total_harga": values['total'],
		    // "suplier": values['suplier'],
		};
		
		var detail = [];
		this.state.cart.map(item => {
			detail = [...detail, {
				produk: item.id,
				qty: item.qty,
				harga: parseFloat(item.harga),
				harga_jual: parseFloat(item.harga_jual)
			}];
		});

		datas = {...datas, 'detail': detail};

		// console.log('datas', datas);
		this.postData(datas);

	}

// 	handleSubmit = (values) =>{
// 		// diskon_persen: undefined
// 		// diskon_rp: undefined
// 		// diskon_rp_preview: undefined
// 		// jenis_bayar: "Cash"
// 		// kembali: "Rp. 0"
// 		// nama_cust: undefined
// 		// no_meja: undefined
// 		// pp1: 0
// 		// ppn: 40500
// 		// preview_dibayar: 445500
// 		// total: 405000
// 		// total_bayar: 445500
// 		var datas = values;
// 		for ( var key in values ) {
// 			if(values[key] == undefined){
// 				datas = {...datas, [key]: null};
// 			}else{
// 				datas = {...datas, [key]: values[key]+""};
// 			}
// 		}
// ​
// 		console.log('datas', datas);
// 		// var datas = {
// 		//     "outlet": 1,
// 		//     "total_harga": this.tate.total,
// 		//     "total_bayar": this.state,
// 		//     "jenis_bayar": "cash",
// 		//     "diskon":null,
// 		//     "tgl_pesan":null,
// 		//     "no_meja":null,
// 		//     "detail":[
// 		//         {
// 		//             "produk":8,
// 		//             "qty":2,
// 		//             "harga":1000,
// 		//             "diskon_rp":0,
// 		//             "diskon_persen":0
// 		//         }
// 		//     ]
// 		// };
		
// 		// var datas = new FormData();
// 		// for ( var key in values ) {
// 		// 	if(values[key] == undefined){
// 		// 		datas.append(key, '');
// 		// 	}else{
// 		// 		datas.append(key, values[key].toString());
// 		// 	}
// 		// }
// 		// datas.append('provider', this.props.id);
// 		// if(this.state.gambar != null){
// 		// 	datas.append('gambar', this.state.gambar, this.state.gambar.name);
// 		// }else{
// 		// 	datas.append('gambar', '');
// 		// }
// 		// this.postData(datas);
// 	}

	reset(){
				this.setState({
					errComponent: false,
					errTitle: '',
					errMsg: '',
					loadingButton: false,

					loadingBarcode: false,
					barcodeIsNotFound: false,
					barcodeResults: [],

					loadingProduk: false,
					listProduk: [],
					loadingSuplier: false,
					listSuplier: [],
					
					loadingOutletModal: false,
					listOutletModal: [],
					loadingSuplierModal: false,
					listSuplierModal: [],
					loadingKategoriModal: false,
					listKategoriModal: [],

					loadingCart: [],
					cart: [],

					gambar: null,
					
					aksi: 'tambah',
					method: 'post',
					idLama: '',

					searchText: '',
					barcodeText: '',

					modalPembayaran: false,
					kategori: '',

					total: 0,
					total_final: 0,
					dibayar: 0,
					kekurangan: 0,
					// diskon_persen: '',
					// diskon_rp: '',

					modalProduk: false,
					harga_beli: 0,
					harga_jual: 0,

					suplier_dipilih: '',
				}, () =>{
					this.getSuplier();
					this.loadMasterList();
				});
	}

	async postData(datas) {
		message.loading({ content: 'Memproses...', duration: 0, key: 'toast' });
		var that = this;

		this.props.ajaxHandler(this.state.method, configClass.apiUrl.pembelian, datas, false, true)
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menyimpan data', duration: 2, key: 'toast' });
				// window.location.reload();
				this.setState({
					id: this.props.responseMsg.id,
					modalPembayaran: false,
				}, () =>{
					Modal.confirm({
					    title: 'Transaksi sukses',
					    icon: <CheckCircleOutlined style={{color: 'green'}} />,
					    content: 'tekan cetak untuk mencetak, tekan selesai untuk melanjutkan',
					    okText: <a href={"/admin/nota_konsinyasi/"+this.props.responseMsg.id} target="_blank"><PrinterOutlined /> Cetak</a>,
					    cancelText: 'Selesai',
					    onOk() {
					    	that.reset()
					    },
					    onCancel() {
					    	that.reset()
					    }
					});
				});
			}else{
				const errTitle = 'gagal menyimpan data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_post', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	handleSubmitProduk = (values) =>{
		var datas = new FormData();
		for ( var key in values ) {
			if(values[key] == undefined){
				datas.append(key, '');
			}else{
				datas.append(key, values[key].toString());
			}
		}
		// datas.append('provider', this.props.id);
		datas.append('harga', this.state.harga_jual);
		if(this.state.gambar != null){
			datas.append('gambar', this.state.gambar, this.state.gambar.name);
		}else{
			datas.append('gambar', '');
		}
		this.postDataProduk(datas);
	}

	async postDataProduk(datas) {
		message.loading({ content: 'Memproses...', duration: 0, key: 'toast' });

		this.props.ajaxHandler(this.state.method, configClass.apiUrl.produk+'/', datas, true, true)
		.then(() =>{
			this.setState({
				loadingButtonProduk: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menyimpan, mohon menunggu sedang menampilkan produk...', duration: 2, key: 'toast' });
				// var results = this.props.responseMsg;
				this.getProdukById(this.props.responseMsg.id);
			}else{
				const errTitle = 'gagal menyimpan data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_post', response);
			this.setState({
				loadingButtonProduk: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	generateSelect(stateName, url, key, value){
		console.log('cek_for_error', stateName);
		return (
			this.state['loading'+stateName]?(
				<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
			):(
				<Select
					showSearch
					placeholder="pilih"
					optionFilterProp="children"
					onSearch={ val => {
						const rule = {
							destination: {
								stateProgressName: 'loading'+stateName+'_searching',
								endPoint: url+'?search='+val,
								stateDestination: 'list'+stateName
							},
							nextDestination: null
						}
						this.getMasterList(rule);
					}}
					onPopupScroll={ e => {
						e.persist(); 
						let target = e.target;
						if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
							if(this.state['list'+stateName].next != undefined && this.state['list'+stateName].next != null){
								const rule = {
									destination: {
										stateProgressName: 'loading'+stateName+'Paging',
										endPoint: this.state['list'+stateName].next,
										stateDestination: 'list'+stateName
									},
									nextDestination: null
								}
								this.getMasterList(rule);
							}
						}
					}}
				>
					{(this.state['loading_'+stateName+'searching'] &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
					{this.state['list'+stateName].count != undefined ? (
						this.state['list'+stateName].results.map((item) => (
							<Select.Option value={item[key]}>{item[key]+" - "+item[value]+""}</Select.Option>
						))
					) : (
						this.state['list'+stateName].map((item) => (
							<Select.Option value={item[key]}>{item[key]+" - "+item[value]+""}</Select.Option>
						))
					)}
					{(this.state['list'+stateName] != undefined && this.state['list'+stateName].next != undefined &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
				</Select>
			)
		);
	}

	generateSelectSuplier(stateName, url, key, value){
		return (
			this.state['loading'+stateName]?(
				<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
			):(
				<Select
					style={{
						width: '100%'
					}}
					showSearch
					placeholder="pilih suplier terlebih dahulu"
					optionFilterProp="children"
					onSearch={ val => {
						const rule = {
							destination: {
								stateProgressName: 'loading'+stateName+'_searching',
								endPoint: url+'?search='+val,
								stateDestination: 'list'+stateName
							},
							nextDestination: null
						}
						this.getMasterList(rule);
					}}
					onPopupScroll={ e => {
						e.persist(); 
						let target = e.target;
						if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
							if(this.state['list'+stateName].next != undefined && this.state['list'+stateName].next != null){
								const rule = {
									destination: {
										stateProgressName: 'loading'+stateName+'Paging',
										endPoint: this.state['list'+stateName].next,
										stateDestination: 'list'+stateName
									},
									nextDestination: null
								}
								this.getMasterList(rule);
							}
						}
					}}
					onChange={(val) => {
						this.setState({
							suplier_dipilih: val
						}, () =>{
							this.getProduk()
						});
						
					}}
				>
					{(this.state['loading_'+stateName+'searching'] &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
					{this.state['list'+stateName].count != undefined ? (
						this.state['list'+stateName].results.map((item) => (
							<Select.Option value={item[key]}>{item[key]+" - "+item[value]+""}</Select.Option>
						))
					) : (
						this.state['list'+stateName].map((item) => (
							<Select.Option value={item[key]}>{item[key]+" - "+item[value]+""}</Select.Option>
						))
					)}
					{(this.state['list'+stateName] != undefined && this.state['list'+stateName].next != undefined &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
				</Select>
			)
		);
	}

	showPembayaran = () =>{
		var that = this;
		this.setState({
			modalPembayaran: true
		}, () =>{
			if(this.formPembayaran.current != null){
				this.formPembayaran.current.setFieldsValue({
					// jenis_bayar: "Cash",
				    total: this.state.total,
				    preview_dibayar: this.state.total,//+((this.state.total_final/100)*this.state.ppn)+((this.state.total_final/100)*this.state.pp1),
				    total_bayar: this.state.total,//+((this.state.total_final/100)*this.state.ppn)+((this.state.total_final/100)*this.state.pp1),
				    kekurangan: 'Rp. 0',
				    // ppn: this.state.total!=this.state.total_final ? ((this.state.total_final/100)*this.state.ppn) : ((this.state.total/100)*this.state.ppn),
				    // pp1: this.state.total!=this.state.total_final ? ((this.state.total_final/100)*this.state.pp1) : ((this.state.total/100)*this.state.pp1),
				    // diskon_persen: '',
					// diskon_rp: '',
				});
				// if(this.no_mejaref.current != null){
				// 	this.no_mejaref.current.focus();
				// }
				// this.formPembayaran.current.no_meja.focus();
			}
		});
	}

	render() {
		// if(this.props.layanan_str.length>0 && this.props.layanan_str.includes('Pembelian / Pemesanan Obat') == false){
		// 	return <Redirect to="/admin/forbidden" />
		// }
		// if(this.props.isRedirect){
		// 	return <Redirect to={configClass.pageUrl.data} />
		// }

		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+config.get_site_info().name}</title>
				</Helmet>
				
				<Card className="konten">
					<Row className="isi" justify="space-between">
						<Col xl={{span: 15}} xxl={{span: 16}} className="penjualan-panel-kiri">
							<Row>
								<Col span={12} style={{paddingRight: 2}}>
									
								      <Input 
								      	value={this.state.barcodeText}
								      	onChange={(event) =>{
								      		this.setState({
								      			barcodeText: event.target.value
								      		})
								      	}}
								      	ref={this.barcodeRef}
								      	prefix={this.state.loadingBarcode ? <Spin /> : <BarcodeOutlined />}
								      	size="small" 
								      	placeholder="scan barcode disini" 
								      	className="penjualan-cart-barcode" 
								      	onKeyPress={event => {
							                if (event.key === 'Enter') {
							                	event.preventDefault();
							                	this.scanProduk(event.target.value);
							                }
							            }}
								      />
								    
								    {/*
									<Input 
										className="penjualan-cart-barcode"
										prefix={<BarcodeOutlined />}
										size="small" 
										placeholder="scan barcode disini" 
										onKeyPress={event => {
							                if (event.key === 'Enter') {
							                	event.preventDefault();
							                	this.setState({
							                		searchText: event.target.value
							                	}, () =>{
							                		this.getProduk();
							                	});
							                }
							            }}
									/>
									*/}
								</Col>
								<Col span={12} style={{paddingLeft: 2}}>
									<Input.Search
										allowClear
										className="penjualan-cart-cari"
										size="small" 
										placeholder="atau ketikkan pencarian disini" 
										onSearch={(val) =>{
											this.setState({
												searchText: val
											}, () =>{
												this.getProduk()
											});
										}}
									/>
								</Col>
							</Row>
							<Row>
								<Col span={24} style={{padding: 3}}>
										{this.generateSelectSuplier('Suplier', configClass.apiUrl.suplier, 'id', 'nama')}
					        	</Col>
							</Row>
							<PerfectScrollbar>
								{this.state.loadingProduk 
									?
										<div className="panel-cart-produk" align="center" style={{marginTop: 20, color: '#2596FF'}}>
											<Spin /><br />
											fetching product, please wait...
										</div>
									:
										<Row className="panel-cart-produk">
											{this.state.listProduk.map((item, idx) =>(
												<Col xxl={6} xl={8} className="cart-bound">
													<a href="#" onClick={() => this.addToCart(item.id)}>
														<div className="panel-cart-item">
															<div 
																className="penjualan-cart-image" 
																style={{
																	backgroundImage: "url(" + item.gambar + ")",
																	// backgroundImage: "url("+process.env.PUBLIC_URL + "/sample_produk/baju batik.jpg"+")"
																}}
															>
																<div className="penjualan-cart-price">
																	{'Rp. '+parseFloat(item.hpp).toLocaleString('en-us')}
																</div>
															</div>
															<div className="penjualan-cart-nama">
																{item.nama}
															</div>
														</div>
													</a>
												</Col>
											))}
										</Row>
								}
								{(this.state.modalProduk == false &&
									<FloatingButton>
										<Item
											backgroundColor="#F9F09A"
											imgSrc={process.env.PUBLIC_URL + "/plus.png"}
											onClick={() =>{
												this.setState({
													modalProduk: true
												})
											}}
										/>
									</FloatingButton>
								)}
							</PerfectScrollbar>
							{/*
							<PerfectScrollbar>
								<div className="penjualan-cart-kategori">
									{this.state.loadingKategori 
									?
										<div align="center" style={{marginTop: 20, color: '#2596FF'}}>
											<Spin />											
										</div>
									:
										this.state.listKategori.map((item, idx) =>(
											<Button 
												className={this.state.kategori==item.id ? "penjualan-cart-kategori-item" : "penjualan-cart-kategori-item-selected"}
												onClick={
													() => this.getProduk(item.id)
												}
											>
												{item.nama}
											</Button>
										))
									}
									
								</div>
							</PerfectScrollbar>
							*/}
						</Col>

						<Col xl={{span: 8}} xxl={{span: 7}} className="penjualan-panel-kanan">
							{(this.state.barcodeIsNotFound == true &&
								<Alert
							      message="Produk tidak ditemukan"
							      showIcon
							      description="produk yang anda input tidak ditemukan dalam sistem"
							      type="error"
							      closable
							    />
						    )}
							<div className="penjualan-panel-kanan-konten">
								<PerfectScrollbar className="panel-cart">
									<Row className="panel-daftar-belanja">
										<Col span={24}>Daftar Konsinyasi</Col>
									</Row>
									{this.state.cart.length == 0
										?
											<Result
											    icon={<WarningOutlined style={{color: '#f9af4d'}} />}
											    title={
											    	<span style={{color: '#f9af4d'}}>
											    	daftar konsinyasi kosong!
											    	</span>
											    }
											 />
										:
											this.state.cart.map((item, idx) => (
												<Row className="penjualan-cart-item">
													<Col span={7}>
														
																<Button
																	size="small"
																	type="text"
																	style={{
																		display: 'inline-block'
																	}}
																	onClick={
																		() => this.kurangi(item.id)
																	}
																>
																-
																</Button>
																<Input 
																	size="small"
																	value={item.qty} 
																	style={{
																		width: 40,
																		display: 'inline-block'
																	}}
																	onChange={(e) => this.setQty(e, item.id)} 
																/>
																<Button
																	size="small"
																	type="text"
																	style={{
																		display: 'inline-block'
																	}}
																	onClick={() => this.tambah(item.id)}
																>
																+
																</Button>
													</Col>
													<Col span={10} className="penjualan-cart-item-panel">
														<div className="penjualan-cart-item-nama">
															{item.nama}
														</div>
													</Col>
													<Col span={7} className="penjualan-cart-item-sub-total-panel">
														<div className="penjualan-cart-item-sub-total">
															{'Rp. '+parseFloat(item.harga*item.qty).toLocaleString('en-us')}
														</div>
													</Col>
													<Col span={24}>
														<Row justify="space-between">
															<Col span={5}>
																Harga Beli
															</Col>
															<Col span={6}>
																<NumberFormat 
																	value={item.harga}
											        				placeholder="harga beli"
																	style={{textTransform: 'none', fontSize: 10}}
																	autoComplete="off" 
																	thousandSeparator={true} 
																	prefix={'@ Rp. '}
																	className="ant-input ant-input-sm"
																	onValueChange={(values) => this.setHarga(values, item.id)}
															      //   onValueChange={(values) => {
																     //    const {formattedValue, value} = values;
																     //    this.formPembayaran.current.setFieldsValue({
																	    //     diskon_rp: value,
																	    //     diskon_persen: '',
																	    // });
															      //   }}
															  	/>
														  	</Col>
														  	<Col span={2}>&nbsp;</Col>
														  	<Col span={5}>
																Harga Jual
															</Col>
															<Col span={6}>
																<NumberFormat 
																	value={item.harga_jual}
											        				placeholder="harga jual"
																	style={{textTransform: 'none', fontSize: 10}}
																	autoComplete="off" 
																	thousandSeparator={true} 
																	prefix={'@ Rp. '}
																	className="ant-input ant-input-sm"
																	onValueChange={(values) => this.setHargaJual(values, item.id)}
															      //   onValueChange={(values) => {
																     //    const {formattedValue, value} = values;
																     //    this.formPembayaran.current.setFieldsValue({
																	    //     diskon_rp: value,
																	    //     diskon_persen: '',
																	    // });
															      //   }}
															  	/>
														  	</Col>
														</Row>
													</Col>
												</Row>
											))
									}
								</PerfectScrollbar>
							</div>
							<div className="total-cart-panel">
								<Button 
									className="btn-primary" style={{width: '100%'}}
									disabled={this.state.cart.length>0?false:true}
									onClick={this.showPembayaran}
								>
									<Row>
										<Col span={7}>
											Simpan
										</Col>
										<Col span={15} style={{fontSize: 18}}>
										 {'Rp. '+parseFloat(this.state.total).toLocaleString('en-us')}
										</Col>
										<Col span={2}>
											<RightOutlined style={{fontSize: 18}} />
										</Col>
									</Row>	
								</Button>
							</div>
						</Col>
					</Row>
				</Card>
				<Modal
				  autoFocus={false}
				  closable={false}
				  className="penjualan-pembayaran"
		          visible={this.state.modalPembayaran}
		          title={
		          	<Row>
			          	<Col span={24} className="penjualan-pembayaran-total">
			          		{'Rp. '+parseFloat(this.state.total).toLocaleString('en-us')}
			          	</Col>
			        </Row>
		          }
		          onCancel={() =>{
		          	this.setState({
		          		modalPembayaran: false
		          	});
		          }}
		          footer={[]}
		        >
		        	<Form
		        		ref={this.formPembayaran}
				        labelCol={{ span: 10 }}
				        wrapperCol={{ span: 14 }}
				        layout="horizontal"
				        size={"small"}
				        initialValues={{
				        	jenis_bayar: "Cash",
				        	total: this.state.total,
				        	preview_dibayar: this.state.total,
				        	total_bayar: this.state.total,
				        	kekurangan: 'Rp. 0',	
				        }}
				        onFinish={this.handleSubmit}
				    >
				    	<Form.Item label="Total Rp" name="total" style={{display: 'none'}}>
				          <Input value={this.state.total} />
				        </Form.Item>
				        <Row>
				        	<Col span={24} style={{padding: 3, textAlign: 'center', fontSize: 18}}>
				        		apakah data yang diinputkan sudah benar?
				        	</Col>
				        </Row>
				        <Row
				        	justify="end"
				        >
				        	<Col style={{padding: 5}}>
						        <Button 
						        	type="primary" 
						        	htmlType="submit"
						        	disabled={this.state.kembali < 0 ? true : false}
						        >
						        	Simpan
						        </Button>
					        </Col>
					        <Col style={{padding: 5}}>
						        <Button 
						        	type="danger"
						        	htmlType="button"
						        	onClick={() =>{
							          	this.setState({
							          		modalPembayaran: false
							          	});
							        }}
						        >
						          Cancel
						        </Button>
						    </Col>
						</Row>
			        </Form>
		        </Modal>
		        <Modal
				  autoFocus={false}
				  closable={true}
		          visible={this.state.modalProduk}
		          title="tambah produk"
		          onCancel={() =>{
		          	this.setState({
		          		modalProduk: false
		          	});
		          }}
		          footer={[]}
		        >
		        	<Form 
						layout="vertical"
						name="fdata"
					    onFinish={this.handleSubmitProduk}
					    size="small"
					    ref={this.formRef}
					>
						{/*data dasar*/}
						<Row className="isi">
					       	<Col lg={24} sm={24} xs={24}>
					        	<Card
					        		className="konten"
						            title={
						            	<span className="konten-judul">
						            		form produk
						            		{(this.state.loadingButton &&
								        		<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
								        	)}
						            	</span>
						            }
						        >
						        		{(this.state.errMsg!='' &&
								        	<Alert
										      message={this.state.errTitle}
										      description={JSON.stringify(this.state.errMsg)}
										      type="error"
										      showIcon
										      style={{
										      	marginBottom: 10
										      }}
										    />
										)}
										<Row justify="space-between">
						                	<Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="SUPLIER"
										            name="suplier"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus dipilih' 
										            	}
										            ]}
										        >
										            {this.generateSelect('SuplierModal', configClass.apiUrl.suplier, 'id', 'nama')}
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
						                	<Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="OUTLET"
										            name="outlet"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus dipilih' 
										            	}
										            ]}
										        >
										            {this.generateSelect('OutletModal', configClass.apiUrl.outlet, 'id', 'nama')}
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
						                	<Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="KATEGORI"
										            name="kategori"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus dipilih' 
										            	}
										            ]}
										        >
										            {this.generateSelect('KategoriModal', configClass.apiUrl.kategori, 'id', 'nama')}
										        </Form.Item>
										    </Col>
										</Row>
					                	<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="NAMA"
										            name="nama"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input
										            	style={{textTransform: 'none'}}
										                placeholder="nama"
										            />
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
						                	<Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="JENIS STOK"
										            name="jns_stok"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus dipilih' 
										            	}
										            ]}
										        >
										            <Select
														showSearch
														placeholder="pilih"
														optionFilterProp="children"
													>
														<Select.Option value="1">Active</Select.Option>
														<Select.Option value="2">Non Active</Select.Option>
													</Select>
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
						                	<Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="JENIS BARANG"
										            name="jns_barang"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus dipilih' 
										            	}
										            ]}
										        >
										            <Select
														showSearch
														placeholder="pilih"
														optionFilterProp="children"
													>
														<Select.Option value="1">Perusahaan</Select.Option>
														<Select.Option value="2">Konsinyasi</Select.Option>
													</Select>
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="BARCODE / SKU"
										            name="barcode"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input
										                placeholder="barcode"
										                style={{textTransform: 'none'}}
										            />
										        </Form.Item>
										    </Col>
										</Row>
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="SATUAN"
										            name="satuan"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input
										                placeholder="satuan"
										                style={{textTransform: 'none'}}
										            />
										        </Form.Item>
										    </Col>
										</Row>										
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="FOTO"
										            name="gambar"
										        >
										            <Input
										            	accept="image/png, image/jpeg"
										            	type="file"
										                placeholder="pilih gambar"
										                onChange={this.setGambar}
										            />
										        </Form.Item>
										        {(this.state.aksi == 'edit' && this.state.preview_gambar != null &&
										        	<img src ={this.state.preview_gambar} width="100" height="100" style={{objectFit: 'cover'}} />
										    	)}
										    </Col>
										</Row>
										<Row justify="space-between">
									        <Col lg={24} sm={24} xs={24}>
									        	<Form.Item
									        		label="HARGA JUAL"
										            name="harga"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <NumberFormat 
										            	style={{textTransform: 'none'}}
										            	autoComplete="off" 
										            	thousandSeparator={true} 
										            	prefix={'Rp. '}
										            	className="ant-input ant-input-sm"
						                                onValueChange={(values) => {
						                                  const {formattedValue, value} = values;
						                                  this.setState({
						                                  	harga_jual: parseFloat(value)
						                                  });
						                                  // console.log('harga', values);
						                                }}
						                            />
										        </Form.Item>
										    </Col>
										</Row>

										<Row justify="end">
									        <Col style={{padding: 5}}>
									        	<Button 
													key="btn-simpan"
													loading={this.state.loadingButtonProduk}
													type="primary" 
													htmlType="submit"
													disabled={this.state.errComponent?true:false}
													size="small"
												>
													{(!this.state.loadingButtonProduk && <CheckCircleOutlined />)}
													Simpan
												</Button>
											</Col>
											<Col style={{padding: 5}}>
												
													<Button 
														type="danger"
														key="btn-cancel"
														disabled={this.state.loadingButtonProduk?true:false}
													    size="small"
													    onClick={() =>{
													    	this.setState({
													    		modalProduk: false
													    	})
													    }}
													>
														<CloseCircleOutlined /> Cancel
													</Button>
												
										    </Col>
										</Row>

						        </Card>
					        </Col>
					    </Row>
					</Form>
		        </Modal>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(FormTitipan);