import React from 'react';
import { Link, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import {
	Breadcrumb,
	Card,
	Row,
	Col,
	Avatar,
	Form, 
	Input,
	Button,
	Space,
	Select,
	DatePicker,
	message,
	Alert,
	Spin,
	Modal, 
	Skeleton,
	Table,
	Tag
} from 'antd';
import {
  DiffOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  BarcodeOutlined,
  UserAddOutlined,
  PrinterOutlined,
  UserOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import axios from 'axios';
import Config from '../../Config';

import { connect } from 'react-redux';
import {
	initHandler,
	ajaxViewHandler,
	ajaxHandler,
	setConfig,
} from "../../store/actions";
import {Helmet} from "react-helmet";

const FormItem = Form.Item

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 19,
      offset: 5,
    },
    lg: {
      span: 19,
      offset: 5,
    }
  },
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const configClass = {
	title: 'form registrasi',
	apiUrl: {
		pasien: 'pasien/api/pasien',
		registrasi: 'transaksi/api/transaksi/',
	},
	pageUrl: {
		cetak_no: '/addon/cetak_antrian/'
	},
	breadCrumb: [
		(
			<span>
				<DollarOutlined /> transaksi
			</span>
		),
		'registrasi'
	]

}

const config = new Config();

class FormRegistrasi extends React.Component {
	formRef = React.createRef();
	formRefPasien = React.createRef();

	constructor(props){
		super(props);

		this.state = {
			errComponent: false,
			errTitle: '',
			errMsg: '',
			loadingButton: false,

			loadingPasien: false,
			listPasien: [],

			pasien: undefined,
			dataPasien: null,
			skeletonPasien: false,

			jenis: 'pasien lama',

			aksi: 'tambah',
			method: 'post',
			idLama: '',

			modalPasien: false,


			loadingData: false,
			pagination: {pageSize: 25},
			searchText: '',
			searchColumn: '',
			dataSource: [],
			columns: [
				{
					title: 'NO URUT',
					width: 150,
					dataIndex: 'urutan',
					key: 'urutan',
					align: 'center'
				},
				{
					title: 'NAMA',
					width: 150,
					dataIndex: 'pasien_nama',
					key: 'pasien_nama'
				},
				{
					title: 'KODE',
					width: 150,
					dataIndex: 'code',
					key: 'code',
					align: 'center'
				},
				{
					title: 'STATUS',
					width: 150,
					dataIndex: 'status_antrian_nama',
					key: 'status_antrian_nama',
					align: 'center',
					render: (value, row, index) => {
						return (
							<div>
								{(value == 'antri' &&
									<Tag color="red">{value}</Tag>
								)}
								{(value == 'proses' &&
									<Tag color="green">{value}</Tag>
								)}
							</div>
						)
					}
				},
				{
					title: 'AKSI',
					width: 80,
					dataIndex: 'id',
					key: 'aksi',
					fixed: 'right',
					render: (value, row, index) => {
						return (
							(row.status_antrian_nama == 'antri' &&
								<Row key={"space-aksi-"+index} justify="center">
											<Col style={{padding: 5}}>
												<Button className="btn-primary" size="small" onClick={() => this.showProsesConfirm(value)} shape="circle">
													<CheckCircleOutlined />
												</Button>
											</Col>
											<Col style={{padding: 5}}>
												<Button className="btn-delete" size="small" onClick={() => this.showDeleteConfirm(value)} shape="circle">
													<CloseSquareOutlined />
												</Button>
											</Col>
								</Row>
							)
						)
					},
				}

			]
		}
	}

	UNSAFE_componentWillMount(){
		this.props.initHandler();
	}

	componentDidMount(){
		this.loadMasterList();
		this.getData();
	}

	setGambar = (e) => {
	    this.setState({
			'gambar': e.target.files[0]
		});
	}

	handleSearch = (selectedKeys, confirm, dataIndex) => {
	    confirm();
	    this.setState({
	      searchText: selectedKeys[0],
	      searchedColumn: dataIndex,
	    });
	};

	handleReset = clearFilters => {
	    clearFilters();
	    this.setState({ searchText: '' });
	};

	async getData(limit=this.state.pagination.pageSize, offset=0, search=this.state.searchText){
		this.setState({loadingData: true});
		//console.log('master/api/desa/?limit='+limit+'&offset='+offset);

		this.props.ajaxViewHandler('get', configClass.apiUrl.registrasi+'?is_registrasi=true&limit='+limit+'&offset='+offset+'&search='+search)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				const pagination = { ...this.state.pagination };
				pagination.total = data.count;
				this.setState({
					dataSource: data.results,
					pagination
				});
	        }else{
	        	const errTitle = 'error get data';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
	        this.setState({loadingData: false});
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({loadingData: false});
		});
	}

	handleTableChange = async (pagination, filters, sorter) =>{
    	const newPagination = { ...this.state.pagination };
    	newPagination.pageSize = pagination.pageSize;
		this.setState({
			loadingData: true,
			pagination
		});
		if(pagination.current==1){
			this.getData(pagination.pageSize);
		}else{
			this.getData(pagination.pageSize, (pagination.current - 1)*pagination.pageSize);
		}

		
	}

	showDeleteConfirm(id) {
	  Modal.confirm({
	    title: 'Konfirmasi Hapus?',
	    icon: <ExclamationCircleOutlined />,
	    content: 'yakin menghapus data?',
	    okText: 'Ya',
	    okType: 'danger',
	    cancelText: 'Tidak',
	    onOk: () => this.prosesHapus(id),
	    onCancel() {
	      //console.log('Cancel');
	    },
	  });
	}

	prosesHapus(id) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('delete', configClass.apiUrl.registrasi+id+'/')
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menghapus data', duration: 2, key: 'toast' });
				this.getData();
			}else{
				const errTitle = 'gagal menghapus data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_delete', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	showProsesConfirm(id) {
	  Modal.confirm({
	    title: 'Konfirmasi Proses?',
	    icon: <ExclamationCircleOutlined />,
	    content: 'yakin mengubah ke status proses?',
	    okText: 'Ya',
	    okType: 'danger',
	    cancelText: 'Tidak',
	    onOk: () => this.prosesHanlder(id),
	    onCancel() {
	      //console.log('Cancel');
	    },
	  });
	}

	prosesHanlder(id) {
		message.loading({ content: 'Memproses...', key: 'toast' });

		this.props.ajaxHandler('get', configClass.apiUrl.registrasi+id+'/proses/')
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil mengubah status proses', duration: 2, key: 'toast' });
				this.getData();
			}else{
				const errTitle = 'gagal mengubah status proses'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_proses_update', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	async preview(id) {
		message.loading({ content: 'Menampilkan data sebelumnya...', duration: 0, key: 'toast' });

		this.props.ajaxViewHandler('get', configClass.apiUrl.preview+id)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				//this.formRef.current.setFieldsValue(data);
				for ( var key in data ) {
				    const dateRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4}$/;
				    const dateTimeRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;

				    const dateRegex2 = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
				    const dateTimeRegex2 = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
				    if(dateRegex.test(data[key])){//jika format tanggal			    	
				    	this.formRef.current.setFieldsValue({
				    		[key+'_picker']: moment(data[key], 'DD-MM-YYYY'),
							//[key]: moment(data[key], 'DD-MM-YYYY')
						});
				    }else if(dateTimeRegex.test(data[key])){//jika format tanggal dan jam	    	
				    	this.formRef.current.setFieldsValue({
				    		[key+'_picker']: moment(data[key], 'DD-MM-YYYY HH:mm:ss'),
						});
				    }else if(dateRegex2.test(data[key])){//jika format tanggal			    	
				    	this.formRef.current.setFieldsValue({
				    		[key+'_picker']: moment(data[key], 'DD-MM-YYYY'),
							//[key]: moment(data[key], 'DD-MM-YYYY')
						});
				    }else if(dateTimeRegex2.test(data[key])){//jika format tanggal dan jam	    	
				    	this.formRef.current.setFieldsValue({
				    		[key+'_picker']: moment(data[key], 'DD-MM-YYYY HH:mm:ss'),
						});
				    }/*else{
				    	this.formRef.current.setFieldsValue({
				    		[key]: data[key]
				    	});
				    }*/
				    if(key == 'gambar'){
				    	this.setState({
				    		preview_gambar: data[key]
				    	});
				    }else{
				    	this.formRef.current.setFieldsValue({
				    		[key]: data[key]
				    	});
				    }
	        	}
	        	message.destroy();
	        	this.setState({
	        		aksi: 'edit',
	        		method: 'put',
	        		idLama: id+'/'
	        	});
	        }else{
	        	const errTitle = 'error preview data lama';
	        	this.setState({
	        		errTitle: errTitle,
	        		errMsg: this.props.responseMsg
	        	});
	        	message.error({ content: errTitle, duration: 2, key: 'toast' });
	        }
		}).catch((response) => {
			console.log('gagal_get_preview', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	handleSubmit = (values) =>{
		var datas = new FormData();
		for ( var key in values ) {
			if(values[key] == undefined){
				datas.append(key, '');
			}else{
				datas.append(key, values[key].toString());
			}
		}
		
		this.postData(datas);
	}

	reset(){
		this.formRef.current.setFieldsValue({
			pasien: undefined,
			jenis: 'barcode'
		});
		this.resetPasien();
		this.setState({
			dataPasien: null,
			jenis: 'barcode'
		});
		this.getData();
	}

	resetPasien(){
		if(this.formRefPasien != undefined && this.formRefPasien.current != undefined){
			this.formRefPasien.current.resetFields();
		}
	}

	async getPasienById(val) {
		message.loading({ content: 'Display data pasien...', duration: 0, key: 'toast' });
		this.setState({
			errTitle: '',
			errMsg: '',
			dataPasien: null,
			skeletonPasien: true,
		});
		this.props.ajaxViewHandler('get', configClass.apiUrl.pasien+'/'+val)
		.then(() =>{
			this.setState({
				skeletonPasien: false
			});
			if(this.props.isSuccess){
				message.destroy();

				var response = this.props.responseMsg;
				this.setState({
					dataPasien: response
				});
			}else{
				const errTitle = 'gagal menampilkan data pasien'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_preview_pasien', response);
			this.setState({
				skeletonPasien: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	async postData(datas) {
		message.loading({ content: 'Memproses...', duration: 0, key: 'toast' });
		this.setState({
			errTitle: '',
			errMsg: ''
		});
		this.props.ajaxHandler('post', configClass.apiUrl.registrasi, datas, true, true)
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil melakukan registrasi', duration: 2, key: 'toast' });

				var response = this.props.responseMsg;
				var that = this;
				Modal.confirm({
					    title: 'Registrasi Sukses',
					    icon: <CheckCircleOutlined style={{color: 'green'}} />,
					    content: 'tekan cetak untuk mencetak, tekan selesai untuk melanjutkan',
					    okText: <a href={"/addon/cetak_registrasi/"+response.id} target="_blank"><PrinterOutlined /> Cetak</a>,
					    cancelText: 'Selesai',
					    onOk() {
					    	that.reset()
					    },
					    onCancel() {
					    	that.reset()
					    }
				});
			}else{
				const errTitle = 'gagal menyimpan data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_post', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	handleSubmitPasien = (values) =>{
		var datas = new FormData();
		for ( var key in values ) {
			if(values[key] == undefined){
				datas.append(key, '');
			}else{
				if(key == 'nama' || key == 'tempat_lahir'){
					datas.append(key, values[key].toString().toUpperCase());
				}else if(key == 'email'){
					datas.append(key, values[key].toString().toLowerCase());
				}else{
					datas.append(key, values[key].toString());
				}
			}
		}
		// datas.append('provider', this.props.id);
		this.postDataPasien(datas);
	}

	async postDataPasien(datas) {
		message.loading({ content: 'Memproses...', duration: 0, key: 'toast' });
		this.setState({
			errTitle: '',
			errMsg: ''
		});
		this.props.ajaxHandler('post', configClass.apiUrl.pasien+'/', datas, true, true)
		.then(() =>{
			this.setState({
				loadingButton: false
			});
			if(this.props.isSuccess){
				message.success({ content: 'berhasil menyimpan data', duration: 2, key: 'toast' });
				var response = this.props.responseMsg;
				this.formRef.current.setFieldsValue({
					pasien: response.id+'',
				});
				//preview yang berbentuk list
	        	const rule = [
					{
						destination: {
							stateProgressName: 'loadingPasien_searching',
							endPoint: configClass.apiUrl.pasien+'/'+response.id,
							stateDestination: 'listPasien'

						},
						nextDestination: null
					}
				];

				this.setState({
					errComponent: false,
					modalPasien: false
				});
				rule.map((item, index) => {
					this.getMasterList(item);
				});
				this.resetPasien();
				this.getPasienById(response.id);
			}else{
				const errTitle = 'gagal menyimpan data'
				message.error({ content: errTitle, duration: 2, key: 'toast' });
				this.setState({
					errTitle: errTitle,
					errMsg: this.props.responseMsg
				});
			}
		}).catch((response) => {
			console.log('gagal_post', response);
			this.setState({
				loadingButton: false
			});
			message.error({ content: 'kesalahan pengiriman data, coba lagi', duration: 2, key: 'toast' });
		});
	}

	loadMasterList(){
		const rule = [
			{
				destination: {
					stateProgressName: 'loadingPasien',
					endPoint: configClass.apiUrl.pasien,
					stateDestination: 'listPasien',
				},
				nextDestination: null
			}
		];

		this.setState({
			errComponent: false
		});

		rule.map((item, index) => {
			if(index == rule.length-1){
				this.getMasterList(item, true);
			}else{
				this.getMasterList(item);
			}
		});
	}

	async getMasterList(rule, isFinal=false) {
		var destination = rule.destination;
		var nextDestination = rule.nextDestination;
		this.setState({[destination.stateProgressName]: true});
		this.props.ajaxViewHandler('get', destination.endPoint)
		.then(() =>{
			const data = this.props.responseMsg;
			if(this.props.isSuccess){
				if(data.count != undefined){
					let newState = null;
					if(this.state[destination.stateDestination].length == 0){
						newState = data;
					}else{
						if(destination.stateProgressName.includes('_searching')){
							newState = data
						}else{
							const oldState = this.state[destination.stateDestination];
							newState = {
								count: data.count,
								next: data.next,
								previous: data.previous,
								results: [...oldState.results, ...data.results]
							};
						}
					}
					this.setState({
		            	[destination.stateDestination]: newState
		            });
				}else if(data.results != undefined){
	        		this.setState({
		            	[destination.stateDestination]: data.results
		            });
	        	}else{
	        		if(Array.isArray(data)){
			            this.setState({
			            	[destination.stateDestination]: data
			            })
			        }else{
			        	this.setState({
			            	[destination.stateDestination]: [data]
			            })
			        }
		        }
		        if(isFinal){
		        	if(this.props.match.params.id != undefined){
						this.preview(this.props.match.params.id);
					}
		        }
		    }else{
		    	this.setState({
	        		errComponent: true
	        	});
		    }
		    this.setState({[destination.stateProgressName]: false});
		}).catch((response) => {
			//console.log('gagal_get_master', response);
			message.error({ content: 'gagal request data, coba lagi', duration: 2, key: 'toast' });
			this.setState({
				errTitle: 'gagal request data master',
				errMsg: response
			});
			this.setState({[destination.stateProgressName]: false});
		});
        //next function
        if(nextDestination != null){
        	const next = {
        		destination: {
	        		stateProgressName: nextDestination.stateProgressName,
	        		endPoint: nextDestination.endPoint,
	        		stateDestination: nextDestination.stateDestination,
	        	},
        		nextDestination: nextDestination.next

        	}
        	this.getMasterList(next);
        }
	}

	

	generateSelect(stateName, url, key, value){
		return (
			this.state['loading'+stateName]?(
				<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
			):(
				<Select
					autoFocus 
					showSearch
					placeholder="cari / pilih pasien"
					optionFilterProp="children"
					onSearch={ val => {
						const rule = {
							destination: {
								stateProgressName: 'loading'+stateName+'_searching',
								endPoint: url+'?search='+val,
								stateDestination: 'list'+stateName
							},
							nextDestination: null
						}
						this.getMasterList(rule);
					}}
					onPopupScroll={ e => {
						e.persist(); 
						let target = e.target;
						if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
							if(this.state['list'+stateName].next != undefined && this.state['list'+stateName].next != null){
								const rule = {
									destination: {
										stateProgressName: 'loading'+stateName+'Paging',
										endPoint: this.state['list'+stateName].next,
										stateDestination: 'list'+stateName
									},
									nextDestination: null
								}
								this.getMasterList(rule);
							}
						}
					}}
					allowClear
					style={{
						width: '100%'
					}}
					onChange={(val) =>{
						this.getPasienById(val);
					}}
					size={"large"}
				>
					{(this.state['loading'+stateName+'_searching'] == true &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
					{this.state['list'+stateName].count != undefined ? (
						this.state['list'+stateName].results.map((item) => (
							<Select.Option value={item[key]+''}>{item[key]+" - "+item[value]+" - "+item.no_hp}</Select.Option>
						))
					) : (
						this.state['list'+stateName].map((item) => (
							<Select.Option value={item[key]+''}>{item[key]+" - "+item[value]+" - "+item.no_hp}</Select.Option>
						))
					)}
					{(this.state['list'+stateName] != undefined && this.state['list'+stateName].next != undefined &&
						<Select.Option value=""><Spin size="small" /></Select.Option>
					)}
				</Select>
			)
		);
	}

	showModalPasien(){
		this.setState({
			modalPasien: true
		})
	}

	render() {
		if(this.props.layanan_str.length>0 && this.props.layanan_str.includes('Pembelian / Pemesanan Obat') == false){
			return <Redirect to="/admin/forbidden" />
		}
		// if(this.props.isRedirect){
		// 	return <Redirect to={configClass.pageUrl.data} />
		// }

		return(
			<div>
				<Helmet>
					<title>{configClass.title+" | "+config.get_site_info().name}</title>
				</Helmet>
				<Breadcrumb>
					{configClass.breadCrumb.map(item => (
						<Breadcrumb.Item>
							{item}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
				<div className="card">
					<Form 
						layout="vertical"
						name="fdata"
					    onFinish={this.handleSubmit}
					    size="small"
					    ref={this.formRef}
					    initialValues={{
					    	jenis: this.state.jenis
					    }}
					    
					>
						{/*data dasar*/}
						<Row className="isi">
					       	<Col lg={24} sm={24} xs={24}>
					        	<Card
					        		className="konten"
						            title={
						            	<span className="konten-judul">
						            		{configClass.title}
						            		{(this.state.loadingButton &&
								        		<img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
								        	)}
						            	</span>
						            }
						        >
						        		{(this.state.errMsg!='' &&
								        	<Alert
										      message={this.state.errTitle}
										      description={JSON.stringify(this.state.errMsg)}
										      type="error"
										      showIcon
										      style={{
										      	marginBottom: 10
										      }}
										    />
										)}
										{/*
										<Row justify="end">
											<Col style={{padding: 5}}>
									        	<Button 
													key="btn-baru"
													loading={this.state.loadingButton}
													type="primary" 
													htmlType="Button"
													disabled={this.state.errComponent?true:false}
													size="small"
													style={{
														background: '#A3D921',
														color: '#000000',
														border: 'none'
													}}
													onClick={() => this.showModalPasien()}
												>
													<UserAddOutlined />
													Pasien Baru
												</Button>
											</Col>
										</Row>
										*/}
										<Row>
											<Col span={24}>
												<Input.Group compact>
													<Form.Item
										            	name="jenis"
										            	style={{width: '15%'}}
										            >
										            	<Select
															showSearch
															placeholder="pilih jenis"
															optionFilterProp="children"
															style={{
																width: '100%'
															}}
															onChange={(val) =>{
																this.setState({
																	jenis: val,
																	modalPasien: val == 'pasien baru' ? true : false
																}, () =>{
																	// this.formRef.pasien.current.focus({cursor: 'start'});
																	this.formRef.current.setFieldsValue({
																		pasien: undefined
																	});
																});
															}}
															size={"large"}
														>
															<Select.Option value={"barcode"}><BarcodeOutlined /> barcode</Select.Option>
															<Select.Option value={"pasien lama"}><UserOutlined /> pasien lama</Select.Option>
															<Select.Option value={"pasien baru"}><UserAddOutlined /> pasien baru</Select.Option>
														</Select>
										            </Form.Item>
										            {(this.state.jenis == 'barcode' &&
										            	<Form.Item
											            	name="pasien"
											            	style={{width: '85%'}}
											            >
															<Input 
																autoFocus
														      	value={this.state.barcodeText}
														      	onChange={(event) =>{
														      		this.setState({
														      			barcodeText: event.target.value
														      		})
														      	}}
														      	ref={this.barcodeRef}
														      	size="large" 
														      	placeholder="scan barcode disini" 
														      	className="penjualan-cart-barcode" 
														      	onKeyPress={event => {
													                if (event.key === 'Enter') {
													                	event.preventDefault();
													                	this.getPasienById(event.target.value);
													                }
													            }}
													            style={{textTransform: 'none'}}
														    />
													    </Form.Item>
										            )}
										            {((this.state.jenis == 'pasien lama' || this.state.jenis == 'pasien baru') &&
										            	<Form.Item
											            	name="pasien"
											            	style={{width: '85%'}}
											            >
													    	{this.generateSelect('Pasien', configClass.apiUrl.pasien, 'id', 'nama')}
													    </Form.Item>
										            )}
										        </Input.Group>
											</Col>
										</Row>
										{/*
										<Row>
											<Col span={24}>
												<Input.Group compact>
													<Form.Item
										            	name="pasien"
										            	style={{width: '45%'}}
										            >
														<Input 
													      	value={this.state.barcodeText}
													      	onChange={(event) =>{
													      		this.setState({
													      			barcodeText: event.target.value
													      		})
													      	}}
													      	ref={this.barcodeRef}
													      	prefix={this.state.loadingBarcode ? <Spin /> : <BarcodeOutlined />}
													      	size="small" 
													      	placeholder="scan barcode disini" 
													      	className="penjualan-cart-barcode" 
													      	onKeyPress={event => {
												                if (event.key === 'Enter') {
												                	event.preventDefault();
												                	this.scanProduk(event.target.value);
												                }
												            }}
													    />
												    </Form.Item>
												    <Input value={"atau"} readOnly style={{width: '5%', textAlign: 'center', background: '#CCCCCC', textTransform: 'none'}} />
												    <Form.Item
										            	name="pasien_select"
										            	style={{width: '50%'}}
										            >
												    	{this.generateSelect('Pasien', configClass.apiUrl.pasien, 'id', 'nama')}
												    </Form.Item>
												</Input.Group>
											</Col>
										</Row>
										*/}
										<Row justify="end">
									        <Col style={{padding: 5}}>
									        	<Button 
													key="btn-simpan"
													loading={this.state.loadingButton}
													type="primary" 
													htmlType="submit"
													disabled={this.state.errComponent?true:false}
													size="large"
												>
													{(!this.state.loadingButton && <CheckCircleOutlined />)}
													Registrasi
												</Button>
											</Col>
											<Col style={{padding: 5}}>
												<Link to={configClass.pageUrl.data}>
													<Button 
														type="danger"
														key="btn-cancel"
														disabled={this.state.loadingButton?true:false}
													    size="large"
													    onClick={() => this.reset()}
													>
														<CloseCircleOutlined /> Reset
													</Button>
												</Link>
										    </Col>
										</Row>

						        </Card>
					        </Col>
					    </Row>
					</Form>
					{this.state.skeletonPasien == true 
						?
							<Row className="isi">
									<Col span={24}>
										<Card
							        		className="konten"
								            title="Data Pasien"
								        >
								        	<Row>
												<Col span={24}>
													<Skeleton active />
												</Col>
											</Row>
										</Card>
									</Col>
							</Row>
						:
							(this.state.dataPasien != null &&
								<Row className="isi">
									<Col span={24}>
										<Card
							        		className="konten"
								            title="Data Pasien"
								        >
								        	<Row>
												<Col span={6}>
													Nama Pasien
												</Col>
												<Col span={18}>
													{': '+this.state.dataPasien.nama}
												</Col>
												<Col span={6}>
													No Hp
												</Col>
												<Col span={18}>
													{': '+this.state.dataPasien.no_hp}
												</Col>
												<Col span={6}>
													Alamat
												</Col>
												<Col span={18}>
													{': '+this.state.dataPasien.alamat}
												</Col>
											</Row>
										</Card>
									</Col>
								</Row>
							)
					}
					<Row className="isi">
						<Col lg={24} sm={24} xs={24}>
					        	<Card
					        		className="konten"
						            title={
						            	<span className="konten-judul">
						            		DATA ANTRIAN
						            	</span>
						            }
						        >
												<Table 
											        		title={() => 
											        			<Row>
											        				<Col lg={8} sm={24} xs={24}><span className="konten-judul">Total {this.state.pagination.total} record</span></Col>
																	<Col lg={{span: 8, offset: 8}} sm={24} xs={24}>
																		<Input.Search 
																			size="small"
																			placeholder="ketikkan pencarian" 
																			onSearch={value => {
																				this.setState({
																					searchText: value
																				}, () =>{
																						this.getData();
																				});
																			}} 
																			allowClear
																		/>										
																	</Col>
																</Row>					        			
											        		}
											        		bordered
											        		columns={this.state.columns} 
											        		dataSource={this.state.dataSource} 
											        		loading={this.state.loadingData} 
											        		scroll={{ x: 240, y: 450 }}
											        		size="small"
											        		pagination={this.state.pagination}
											        		onChange={this.handleTableChange}
											        	/>
										</Card>
									</Col>
					</Row>
				</div>
				<Modal
				  autoFocus={false}
				  closable={true}
		          visible={this.state.modalPasien}
		          title="TAMBAH PASIEN BARU"
		          onCancel={() =>{
		          	this.setState({
		          		modalPasien: false,
		          		jenis: 'pasien lama'
		          	}, () =>{
		          		// this.formRef.current.setFieldsValue({
		          		// 	jenis: 'pasien lama'
		          		// });
		          	});
		          }}
		          footer={[]}
		          width={800}
		        >
		        	<Form 
						{...layout}
						name="fdata"
					    onFinish={this.handleSubmitPasien}
					    size="small"
					    ref={this.formRefPasien}
					>
						{/*data dasar*/}
						
					        	
						        		{(this.state.errMsg!='' &&
								        	<Alert
										      message={this.state.errTitle}
										      description={JSON.stringify(this.state.errMsg)}
										      type="error"
										      showIcon
										      style={{
										      	marginBottom: 10
										      }}
										    />
										)}

										<Form.Item
									        		label="NAMA"
										            name="nama"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input
										            	placeholder="input nama pasien"
										            />
										</Form.Item>
										<Form.Item
									        		label="NIK"
										            name="nik"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input
										            	style={{textTransform: 'none'}}
										                placeholder="input NIK"
										            />
										</Form.Item>
										<Form.Item label="TEMPAT, TGL LAHIR" style={{ marginBottom: 0 }}>
											<Form.Item
									          name="tempat_lahir"
									          rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										      ]}
									          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
									        >
									          <Input placeholder="input tempat lahir" />
									        </Form.Item>
									        <Form.Item
									          name="tanggal_lahir_preview"
									          rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										      ]}
									          style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
									        >
									          <DatePicker
										        		placeholder="tanggal lahir (dd-mm-yyyy)" 
										        		format="DD-MM-YYYY"
										                style={{width: '100%'}}
										                onChange={(date, dateString) =>{
										                	this.formRefPasien.current.setFieldsValue({
														      tanggal_lahir: dateString,
														    });
										                }}
										       />
									        </Form.Item>
									        <Form.Item
										        	name="tanggal_lahir"
										        	style={{
										        		display: 'none'
										        	}}
										    >
										        	<Input type="hidden" />
										    </Form.Item>
										</Form.Item>
										<Form.Item
									        		label="NO HP"
										            name="no_hp"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input
										            	style={{textTransform: 'none'}}
										                placeholder="input no hp"
										            />
										</Form.Item>
										<Form.Item
									        		label="EMAIL"
										            name="email"
										        >
										            <Input
										            	style={{textTransform: 'lowercase'}}
										                placeholder="input email"
										            />
										</Form.Item>
										<Form.Item
									        		label="ALAMAT"
										            name="alamat"
										            rules={[
										            	{ 
										            		required: true, 
										            		message: 'harus diisi' 
										            	}
										            ]}
										        >
										            <Input
										            	style={{textTransform: 'none'}}
										                placeholder="input alamat"
										            />
										</Form.Item>
										<Row justify="end">
									        <Col style={{padding: 5}}>
									        	<Button 
													key="btn-simpan"
													loading={this.state.loading}
													type="primary" 
													htmlType="submit"
													disabled={this.state.errComponent?true:false}
													size="small"
												>
													{(!this.state.loading && <CheckCircleOutlined />)}
													Simpan
												</Button>
											</Col>
											<Col style={{padding: 5}}>
												
													<Button 
														type="danger"
														key="btn-cancel"
														disabled={this.state.loading?true:false}
													    size="small"
													    onClick={() =>{
													    	this.setState({
													    		modalPasien: false
													    	})
													    }}
													>
														<CloseCircleOutlined /> Cancel
													</Button>
												
										    </Col>
										</Row>

						        
					    
					</Form>
		        </Modal>
			</div>
		);
	}
}

const mapStateToProps = function(state) {
	return state.configReducers
}

export default connect(
		mapStateToProps,
		{
			initHandler,
			setConfig,
			ajaxViewHandler,
			ajaxHandler
		}
	)(FormRegistrasi);