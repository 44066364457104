import React from "react";
import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Card,
  Row,
  Col,
  Input,
  Button,
  Table,
  Modal,
  message,
  Alert,
  Select,
  Spin,
  Form,
  Skeleton,
  Result
} from "antd";
import {
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  RedoOutlined,
  ShoppingCartOutlined,
  FileSearchOutlined,
  CheckCircleOutlined,
  WarningOutlined
} from "@ant-design/icons";
import NumberFormat from "react-number-format";

import PerfectScrollbar from "react-perfect-scrollbar";

import axios from "axios";

import moment from "moment";

import { connect } from "react-redux";
import {
  initHandler,
  ajaxViewHandler,
  ajaxHandler,
  setConfig,
} from "../../store/actions";

import Config from "../../Config";
import { Helmet } from "react-helmet";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

const configClass = {
  title: "data pembelian",
  apiUrl: {
    data: "pembelian/api/pembelian",
    jenis_bayar: "master/api/jenis_bayar/",
    pembayaran: "pembelian/api/pembayaran/",
  },
  pageUrl: {
    form: "/admin/pembelian/",
  },
  breadCrumb: [
    <span>
      <ShoppingCartOutlined /> pembelian
    </span>,
    "data pembelian",
  ],
};

const config = new Config();

class DataPembelian extends React.Component {
  formRefPembayaran = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      errMsg: "",
      loadingData: false,
      pagination: { pageSize: 25 },
      searchText: "",
      searchColumn: "",
      dataSource: [],
      columns: [
        {
          title: "CREATED",
          width: 150,
          dataIndex: "created",
          key: "created",
          render: (value, row, index) => {
            return moment(value).format("DD-MM-YYYY hh:mm:ss");
          },
        },
        {
          title: "SUPLIER",
          width: 150,
          dataIndex: "suplier_nama",
          key: "suplier_nama",
        },
        {
          title: "TOTAL HARGA",
          width: 150,
          dataIndex: "total_harga",
          key: "total_harga",
          render: (value, row, index) => {
            return parseFloat(value).toLocaleString("en-us");
          },
        },
        {
          title: "TOTAL BAYAR",
          width: 150,
          dataIndex: "total_bayar",
          key: "total_bayar",
          render: (value, row, index) => {
            return parseFloat(value).toLocaleString("en-us");
          },
        },
        {
          title: "KEKURANGAN",
          width: 150,
          dataIndex: "total_bayar",
          key: "KEKURANGAN",
          render: (value, row, index) => {
            return (
              parseFloat(value) - parseFloat(row.total_harga)
            ).toLocaleString("en-us");
          },
        },
        {
          title: "AKSI",
          width: 80,
          dataIndex: "id",
          key: "aksi",
          fixed: "right",
          render: (value, row, index) => {
            return (
              <Row key={"space-aksi-" + index} justify="center">
                <Col style={{ padding: 5 }}>
                  <Button
                    className="btn-primary"
                    size="small"
                    onClick={() => this.showModalPembayaran(row)}
                    shape="circle"
                  >
                    <FileSearchOutlined />
                  </Button>
                </Col>
              </Row>
            );
          },
        },
      ],
      modalPembayaran: false,
      loadingJenisBayar: false,
      listJenisBayar: [],
      dataTransaksi: null,
      loadingRiwayatBayar: false,
      listRiwayatBayar: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.props.initHandler();
  }

  componentDidMount() {
    this.getData();
    this.loadMasterList();
  }

  loadMasterList() {
    const rule = [
      {
        destination: {
          stateProgressName: "loadingJenisBayar",
          endPoint: configClass.apiUrl.jenis_bayar,
          stateDestination: "listJenisBayar",
        },
        nextDestination: null,
      },
    ];

    this.setState({
      errComponent: false,
    });

    rule.map((item, index) => {
      if (index == rule.length - 1) {
        this.getMasterList(item, true);
      } else {
        this.getMasterList(item);
      }
    });
  }

  async getMasterList(rule, isFinal = false) {
    var destination = rule.destination;
    var nextDestination = rule.nextDestination;
    this.setState({ [destination.stateProgressName]: true });
    this.props
      .ajaxViewHandler("get", destination.endPoint)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.count != undefined) {
            let newState = null;
            if (this.state[destination.stateDestination].length == 0) {
              newState = data;
            } else {
              if (destination.stateProgressName.includes("_searching")) {
                newState = data;
              } else {
                const oldState = this.state[destination.stateDestination];
                newState = {
                  count: data.count,
                  next: data.next,
                  previous: data.previous,
                  results: [...oldState.results, ...data.results],
                };
              }
            }
            this.setState({
              [destination.stateDestination]: newState,
            });
          } else if (data.results != undefined) {
            this.setState({
              [destination.stateDestination]: data.results,
            });
          } else {
            if (Array.isArray(data)) {
              this.setState({
                [destination.stateDestination]: data,
              });
            } else {
              this.setState({
                [destination.stateDestination]: [data],
              });
            }
          }
        } else {
          this.setState({
            errComponent: true,
          });
        }
        this.setState({ [destination.stateProgressName]: false });
      })
      .catch((response) => {
        console.log("gagal_get_master", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({
          errTitle: "gagal request data master",
          errMsg: response,
        });
        this.setState({ [destination.stateProgressName]: false });
      });
    //next function
    if (nextDestination != null) {
      const next = {
        destination: {
          stateProgressName: nextDestination.stateProgressName,
          endPoint: nextDestination.endPoint,
          stateDestination: nextDestination.stateDestination,
        },
        nextDestination: nextDestination.next,
      };
      this.getMasterList(next);
    }
  }

  showDeleteConfirm(id) {
    Modal.confirm({
      title: "Konfirmasi Hapus?",
      icon: <ExclamationCircleOutlined />,
      content: "yakin menghapus data?",
      okText: "Ya",
      okType: "danger",
      cancelText: "Tidak",
      onOk: () => this.prosesHapus(id),
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  prosesHapus(id) {
    message.loading({ content: "Memproses...", key: "toast" });

    this.props
      .ajaxHandler("delete", configClass.apiUrl.data + id + "/")
      .then(() => {
        this.setState({
          loadingButton: false,
        });
        if (this.props.isSuccess) {
          message.success({
            content: "berhasil menghapus data",
            duration: 2,
            key: "toast",
          });
          this.getData();
        } else {
          const errTitle = "gagal menghapus data";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_delete", response);
        this.setState({
          loadingButton: false,
        });
        message.error({
          content: "kesalahan pengiriman data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  async getData(
    limit = this.state.pagination.pageSize,
    offset = 0,
    search = this.state.searchText
  ) {
    this.setState({ loadingData: true });
    //console.log('master/api/desa/?limit='+limit+'&offset='+offset);

    this.props
      .ajaxViewHandler(
        "get",
        configClass.apiUrl.data +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          "&search=" +
          search
      )
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          const pagination = { ...this.state.pagination };
          pagination.total = data.count;
          this.setState({
            dataSource: data.results,
            pagination,
          });
        } else {
          const errTitle = "error get data";
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
          });
          message.error({ content: errTitle, duration: 2, key: "toast" });
        }
        this.setState({ loadingData: false });
      })
      .catch((response) => {
        console.log("gagal_get_preview", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({ loadingData: false });
      });
  }

  handleTableChange = async (pagination, filters, sorter) => {
    const newPagination = { ...this.state.pagination };
    newPagination.pageSize = pagination.pageSize;
    this.setState({
      loadingData: true,
      pagination,
    });
    if (pagination.current == 1) {
      this.getData(pagination.pageSize);
    } else {
      this.getData(
        pagination.pageSize,
        (pagination.current - 1) * pagination.pageSize
      );
    }
  };

  showModalPembayaran(row) {
    // console.log('tes_row', row);
    this.setState(
      {
        modalPembayaran: true,
        dataTransaksi: row,
      },
      () => {
        if (
          this.formRefPembayaran != undefined &&
          this.formRefPembayaran.current != undefined
        ) {
          this.formRefPembayaran.current.setFieldsValue({
            total_transaksi: parseFloat(row.total_harga).toLocaleString(
              "en-US"
            ),
            total_sudah_bayar: parseFloat(row.total_bayar).toLocaleString(
              "en-US"
            ),
            total_kekurangan: (
              parseFloat(row.total_harga) - parseFloat(row.total_bayar)
            ).toLocaleString("en-US"),
          });
        }
        this.getRiwayatBayar(row.id);
      }
    );
  }

  generateSelectPembayaran(stateName, url, key, value) {
    return this.state["loading" + stateName] ? (
      <img src={process.env.PUBLIC_URL + "/loading_crop.gif"} height="50" />
    ) : (
      <Select
        autoFocus
        showSearch
        placeholder="pilih metode pembayaran"
        optionFilterProp="children"
        onSearch={(val) => {
          const rule = {
            destination: {
              stateProgressName: "loading" + stateName + "_searching",
              endPoint: url + "?search=" + val,
              stateDestination: "list" + stateName,
            },
            nextDestination: null,
          };
          this.getMasterList(rule);
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (
              this.state["list" + stateName].next != undefined &&
              this.state["list" + stateName].next != null
            ) {
              const rule = {
                destination: {
                  stateProgressName: "loading" + stateName + "Paging",
                  endPoint: this.state["list" + stateName].next,
                  stateDestination: "list" + stateName,
                },
                nextDestination: null,
              };
              this.getMasterList(rule);
            }
          }
        }}
        allowClear
        style={{
          width: "100%",
        }}
        size={"large"}
      >
        {this.state["loading" + stateName + "_searching"] == true && (
          <Select.Option value="">
            <Spin size="small" />
          </Select.Option>
        )}
        {this.state["list" + stateName].count != undefined
          ? this.state["list" + stateName].results.map((item) => (
              <Select.Option value={item[key] + ""}>
                {item[key] + " - " + item[value]}
              </Select.Option>
            ))
          : this.state["list" + stateName].map((item) => (
              <Select.Option value={item[key] + ""}>
                {item[key] + " - " + item[value]}
              </Select.Option>
            ))}
        {this.state["list" + stateName] != undefined &&
          this.state["list" + stateName].next != undefined && (
            <Select.Option value="">
              <Spin size="small" />
            </Select.Option>
          )}
      </Select>
    );
  }

  handleSubmitPembayaran = (values) => {
    values = { ...values, pembelian: this.state.dataTransaksi.id };
    this.postDataPembayaran(values);
  };

  async postDataPembayaran(datas) {
    this.setState({
      errTitle: "",
      errMsg: "",
      loadingSimpan: true,
    });

    message.loading({ content: "Memproses...", duration: 0, key: "toast" });

    var that = this;

    this.props
      .ajaxHandler("post", configClass.apiUrl.pembayaran, datas, false, true)
      .then(() => {
        this.setState({
          loadingSimpanPembelian: false,
        });
        if (this.props.isSuccess) {
          message.success({
            content: "berhasil menyimpan data",
            duration: 2,
            key: "toast",
          });
          this.setState({
            loadingSimpan: false,
            modalPembayaran: false,
          });
          var response = this.props.responseMsg;
          var that = this;
          this.getData();
          // Modal.confirm({
          //   title: "Berhasil Menyimpan",
          //   icon: <CheckCircleOutlined style={{ color: "green" }} />,
          //   content:
          //     "tekan cetak untuk mencetak, tekan selesai untuk melanjutkan",
          //   okText: (
          //     <a
          //       href={configClass.pageUrl.cetak_faktur + response.id}
          //       target="_blank"
          //     >
          //       <PrinterOutlined /> Cetak
          //     </a>
          //   ),
          //   cancelText: "Selesai",
          //   onOk() {
          //     that.reset()
          //   },
          //   onCancel() {
          //     that.reset()
          //   },
          // });
        } else {
          const errTitle = "gagal menyimpan data";
          message.error({ content: errTitle, duration: 2, key: "toast" });
          this.setState({
            errTitle: errTitle,
            errMsg: this.props.responseMsg,
            loadingSimpan: false,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_post", response);
        this.setState({
          loadingSimpan: false,
        });
        message.error({
          content: "kesalahan pengiriman data, coba lagi",
          duration: 2,
          key: "toast",
        });
      });
  }

  async getRiwayatBayar(id) {
    this.setState({ loadingRiwayatBayar: true });
    this.props
      .ajaxViewHandler(
        "get",
        configClass.apiUrl.pembayaran + "?pembelian=" + id
      )
      .then(() => {
        if (this.props.isSuccess) {
          const data = this.props.responseMsg;
          this.setState({
            loadingRiwayatBayar: false,
            listRiwayatBayar: data.results,
          });
        } else {
          this.setState({
            loadingRiwayatBayar: false,
            errComponent: true,
          });
        }
      })
      .catch((response) => {
        console.log("gagal_get_riwayat", response);
        message.error({
          content: "gagal request data, coba lagi",
          duration: 2,
          key: "toast",
        });
        this.setState({
          errTitle: "gagal request riwayat",
          errMsg: response,
          loadingRiwayatBayar: false,
        });
      });
  }

  render() {
    // if(this.props.isSuperadmin == false){
    // 	return <Redirect to="/admin/forbidden" />
    // }
    return (
      <div>
        <Helmet>
          <title>
            {configClass.title + " | " + config.get_site_info().name}
          </title>
        </Helmet>
        <Breadcrumb>
          {configClass.breadCrumb.map((item) => (
            <Breadcrumb.Item>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className="card">
          <Row className="isi">
            <Col span={24}>
              <Card
                className="konten"
                title={
                  <Row>
                    <Col lg={{ span: 20 }} xs={{ span: 24 }}>
                      <span className="konten-judul">
                        {configClass.title}
                        {this.state.loadingData && (
                          <img
                            src={process.env.PUBLIC_URL + "/loading_crop.gif"}
                            height="30"
                            alt="loading"
                          />
                        )}
                      </span>
                    </Col>
                    <Col
                      lg={{ span: 4 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        loading={this.state.loadingButton}
                        className="btn-refresh"
                        size="small"
                        onClick={() => this.getData()}
                      >
                        <RedoOutlined />
                      </Button>
                      <Link to={configClass.pageUrl.form}>
                        <Button
                          loading={this.state.loadingButton}
                          className="btn-primary"
                          size="small"
                        >
                          <PlusOutlined /> Tambah
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                }
              >
                {this.state.errMsg != "" && (
                  <Alert
                    message={this.state.errTitle}
                    description={JSON.stringify(this.state.errMsg)}
                    type="error"
                    showIcon
                    style={{
                      marginBottom: 10,
                    }}
                  />
                )}
                <Table
                  title={() => (
                    <Row>
                      <Col lg={8} sm={24} xs={24}>
                        <span className="konten-judul">
                          Total {this.state.pagination.total} record
                        </span>
                      </Col>
                      <Col lg={{ span: 8, offset: 8 }} sm={24} xs={24}>
                        <Input.Search
                          size="small"
                          placeholder="ketikkan pencarian"
                          onSearch={(value) => {
                            this.setState(
                              {
                                searchText: value,
                              },
                              () => {
                                this.getData();
                              }
                            );
                          }}
                          allowClear
                        />
                      </Col>
                    </Row>
                  )}
                  bordered
                  columns={this.state.columns}
                  dataSource={this.state.dataSource}
                  loading={this.state.loadingData}
                  scroll={{ x: 240, y: 450 }}
                  size="small"
                  pagination={this.state.pagination}
                  onChange={this.handleTableChange}
                />
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          autoFocus={false}
          closable={true}
          visible={this.state.modalPembayaran}
          title="FORM PEMBAYARAN PEMBELIAN"
          onCancel={() => {
            this.setState({
              modalPembayaran: false,
            });
          }}
          footer={[]}
          width={800}
        >
          <Form
            {...layout}
            name="fbayar"
            onFinish={this.handleSubmitPembayaran}
            size="small"
            ref={this.formRefPembayaran}
            initialValues={{
              total_transaksi:
                this.state.dataTransaksi != null &&
                parseFloat(this.state.dataTransaksi.total_harga).toLocaleString(
                  "en-US"
                ),
              total_sudah_bayar:
                this.state.dataTransaksi != null &&
                parseFloat(this.state.dataTransaksi.total_bayar).toLocaleString(
                  "en-US"
                ),
              total_kekurangan:
                this.state.dataTransaksi != null &&
                (
                  parseFloat(this.state.dataTransaksi.total_harga) -
                  parseFloat(this.state.dataTransaksi.total_bayar)
                ).toLocaleString("en-US"),
              payment_type: "1",
            }}
          >
            {/*data dasar*/}

            {this.state.errMsg != "" && (
              <Alert
                message={this.state.errTitle}
                description={JSON.stringify(this.state.errMsg)}
                type="error"
                showIcon
                style={{
                  marginBottom: 10,
                }}
              />
            )}

            <Form.Item label="TOTAL PEMBELIAN" name="total_transaksi">
              <Input readOnly />
            </Form.Item>
            <Form.Item label="TOTAL SUDAH BAYAR" name="total_sudah_bayar">
              <Input readOnly />
            </Form.Item>
            <Form.Item label="TOTAL KEKURANGAN" name="total_kekurangan">
              <Input readOnly />
            </Form.Item>
            <Form.Item
              label="JENIS PEMBAYARAN"
              name="payment_type"
              rules={[
                {
                  required: true,
                  message: "harus dipilih",
                },
              ]}
            >
              {this.generateSelectPembayaran(
                "JenisBayar",
                configClass.apiUrl.jenis_bayar,
                "id",
                "nama"
              )}
            </Form.Item>
            <Form.Item
              label="NOMINAL BAYAR"
              name="preview_dibayar"
              rules={[
                {
                  required: true,
                  message: "harus diisi",
                },
              ]}
            >
              <NumberFormat
                style={{ textTransform: "none", height: 38 }}
                autoComplete="off"
                thousandSeparator={true}
                prefix={"Rp. "}
                className="ant-input ant-input-sm"
                onValueChange={(values) => {
                  const { formattedValue, value } = values;
                  this.formRefPembayaran.current.setFieldsValue({
                    nominal: value,
                  });
                  var kembali = 0;
                  if (this.state.dataTransaksi != null) {
                    kembali =
                      parseFloat(this.state.dataTransaksi.total_harga) -
                      parseFloat(this.state.dataTransaksi.total_bayar) -
                      parseFloat(this.state.dataTransaksi.value);
                  }

                  this.setState({
                    kembali: kembali,
                  });

                  this.formRefPembayaran.current.setFieldsValue({
                    preview_kembali:
                      "Rp. " + parseFloat(kembali).toLocaleString("en-US"),
                  });
                }}
              />
            </Form.Item>
            <Form.Item name="nominal" style={{ display: "none" }}>
              <Input />
            </Form.Item>
            <Button
              key="btn-simpan"
              loading={this.state.loadingSimpan}
              type="primary"
              htmlType="submit"
              disabled={this.state.errComponent ? true : false}
              size="small"
            >
              {!this.state.loadingSimpan && <CheckCircleOutlined />}
              Simpan
            </Button>
          </Form>
          <div><b>RIWAYAT PEMBAYARAN</b></div>
          {this.state.loadingRiwayatBayar 
          	?
          		<Row className="isi">
									<Col span={24}>
										<Skeleton active />
									</Col>
							</Row>
          	:
          		<PerfectScrollbar className="panel-cart">
		            <Row
		              style={{
		                borderBottom: "1px solid #cccccc",
		                padding: 10,
		                fontWeight: "bold",
		              }}
		            >
		              <Col span={6}>CREATED</Col>
		              <Col span={6}>JENIS PEMBAYARAN</Col>
		              <Col span={6}>CREATED BY</Col>
		              <Col span={6}>NOMINAL BAYAR</Col>
		            </Row>
		            {this.state.listRiwayatBayar.length == 0 ? (
		              <Result
		                icon={<WarningOutlined style={{ color: "#f9af4d" }} />}
		                title={
		                  <span style={{ color: "#f9af4d" }}>
		                    belum ada riwayat pembayaran!
		                  </span>
		                }
		              />
		            ) : (
		              this.state.listRiwayatBayar.map((item, idx) => (
		                <Row
		                  style={{
		                    borderBottom: "1px solid #cccccc",
		                    padding: 10,
		                  }}
		                >
		                  <Col span={6}>
		                    {moment(item.created).format("DD-MM-YYYY hh:mm:ss")}
		                  </Col>
		                  <Col span={6}>
		                    {item.payement_type_nama}
		                  </Col>
		                  <Col span={6}>
		                    {item.created_by_nama}
		                  </Col>
		                  <Col span={6}>
		                    {parseFloat(item.nominal).toLocaleString('en-us')}
		                  </Col>
		                </Row>
		              ))
		            )}
		          </PerfectScrollbar>
          }
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  initHandler,
  setConfig,
  ajaxViewHandler,
  ajaxHandler,
})(DataPembelian);
